import { NotificationConstants as K } from './Notifications.constants';
import { Notification } from '../../../legacy/models';

export const FetchNotifications = () => {
    return {
        type: K.ACTIONS.FETCH_NOTIFICATIONS_REQUEST,
    };
};

export const FetchNotificationsRequestSuccess = (payload) => {
    return {
        type: K.ACTIONS.FETCH_NOTIFICATIONS_REQUEST_SUCCESS,
        payload,
    };
};

export const CreateNotificationRequest = (payload) => {
    return {
        type: K.ACTIONS.CREATE_NOTIFICATIONS_REQUEST,
        payload,
    };
};

export const CreateNotificationRequestSuccess = (payload) => {
    return {
        type: K.ACTIONS.CREATE_NOTIFICATIONS_REQUEST_SUCCESS,
        payload,
    };
};

export const UpdateNotificationRequest = (payload) => {
    return {
        type: K.ACTIONS.UPDATE_NOTIFICATIONS_REQUEST,
        payload,
    };
};

export const UpdateNotificationRequestSuccess = (payload) => {
    return {
        type: K.ACTIONS.UPDATE_NOTIFICATIONS_REQUEST_SUCCESS,
        payload,
    };
};

export const DeleteNotificationRequest = (notification) => {
    return {
        type: K.ACTIONS.DELETE_NOTIFICATIONS_REQUEST,
        payload: notification,
    };
};

export const DeleteNotificationRequestSuccess = (notification_id) => {
    return {
        type: K.ACTIONS.DELETE_NOTIFICATIONS_REQUEST_SUCCESS,
        payload: notification_id,
    };
};
