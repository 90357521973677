import { put, call, takeLatest } from 'redux-saga/effects';
import { AddFusionsResource, SetFusionsResource } from './Fusions.action';
import { store } from '../..';
import { api_createFusion, api_updateFusion, api_deleteFusion } from './Fusions.services';
import { Fusion, parseFusionArguments } from '../../../legacy/models';
import { FusionConstants as K } from './Fusions.constants';
import { errorFlash, flash } from '../../../legacy/components/Flash';

function* handleCreateFusion(action) {
    try {
        const { asset_id, data } = action.payload;
        const response = yield call(api_createFusion, asset_id, data);

        const fusion = new Fusion(
            ...parseFusionArguments({
                ...response,
                asset_id,
            })
        );

        yield put(AddFusionsResource({ [fusion.fusion_id]: fusion }));

        flash({ message: 'Fusion chart created', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createFusionSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_FUSION_REQUEST,
        handleCreateFusion
    );
}

// UPDATE FUSION
function* handleUpdateFusion(action) {
    try {
        const { asset_id, data } = action.payload;

        const response = yield call(api_updateFusion, asset_id, data);
        const fusion = new Fusion(
            ...parseFusionArguments({
                ...data,
                ...response,
            })
        );

        yield put(AddFusionsResource({ [fusion.fusion_id]: fusion }));
        flash({ message: 'Fusion chart updated', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateFusionSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_FUSION_REQUEST,
        handleUpdateFusion
    );
}

// DELETE FUSION
function* handleDeleteFusion(action) {
    try {
        const response = yield call(api_deleteFusion, action.payload);
        const currentFusionState = store.getState().fusions.fusions;
        const cloneCurrentFusionState = {
            ...currentFusionState,
        };

        delete cloneCurrentFusionState[action.payload.fusion_id];

        yield put(SetFusionsResource(cloneCurrentFusionState));
        flash({ message: 'Fusion chart deleted', status: 'success' });

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* deleteFusionSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_FUSION_REQUEST,
        handleDeleteFusion
    );
}
