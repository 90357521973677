
import { makeRequest } from '../Shared/Shared.action';

// REDUX STORE
import { store } from '../..';

// MODEL
import { Invitation } from '../../../legacy/models';

export const api_getInvitations = (entity_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/invite`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_createInvitation = (entity_id, emails) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/invite`,
                method: 'POST',
                params: JSON.stringify({
                    invitees: emails,
                }),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_revokeInvitation = (entity_id, invitation) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/invite/${invitation.invite_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateInvitation = (entity_id, invite_id, accept) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/invite/${invite_id}`,
                method: 'PUT',
                params: JSON.stringify({
                    accept: accept,
                }),
                success: resolve,
                error: reject,
            });
        }
    );
};
