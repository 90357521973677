import { DeviceConstants as K } from './Devices.constants';

export const ResetDevicesState = () => {
    return {
        type: K.ACTIONS.RESET_DEVICES_STATE,
    };
};

export const SetDevicesResource = (devices) => {
    return {
        type: K.ACTIONS.SET_DEVICES_RESOURCE,
        payload: { devices },
    };
};

export const AddDevicesResource = (devices) => {
    return {
        type: K.ACTIONS.ADD_DEVICES_RESOURCE,
        payload: { devices },
    };
};

export const RemoveDevicesResource = (device_ids) => {
    return {
        type: K.ACTIONS.REMOVE_DEVICES_RESOURCE,
        payload: { device_ids },
    };
};

export const PreviewSerialCommsData = (payload, callback) => {
    return {
        type: K.ACTIONS.PREVIEW_SERIAL_COMMUNICATIONS_DATA,
        payload,
        callback,
    };
};

export const UpdateDevice = (payload, callback) => {
    return {
        type: K.ACTIONS.UPDATE_DEVICE_REQUEST,
        payload,
        callback,
    };
};

export const CreateDevice = (payload, callback) => {
    return {
        type: K.ACTIONS.CREATE_DEVICE_REQUEST,
        payload,
        callback,
    };
};

export const UpdateDeviceEntity = (mac_address, callback) => {
    return {
        type: K.ACTIONS.UPDATE_DEVICE_ENTITY_REQUEST,
        payload: { mac_address },
        callback,
    };
};

export const InitializeDeviceAsRelay = (mac_address, gateway_id, callback) => {
    return {
        type: K.ACTIONS.INITIALIZE_RELAY_REQUEST,
        payload: { mac_address, gateway_id },
        callback,
    };
};

export const LinkDevice = (asset_id, device_id, callback) => {
    return {
        type: K.ACTIONS.LINK_DEVICE_REQUEST,
        payload: { asset_id, device_id },
        callback,
    };
};

export const UnlinkDevice = (asset_id, device_id, callback) => {
    return {
        type: K.ACTIONS.UNLINK_DEVICE_REQUEST,
        payload: { asset_id, device_id },
        callback,
    };
};

export const CascadeUnlinkDevice = (device) => {
    return {
        type: K.ACTIONS.CASCADE_UNLINK_DEVICE,
        payload: { device },
    };
};

export const RemoveRelay = (device_id, callback) => {
    return {
        type: K.ACTIONS.REMOVE_RELAY_REQUEST,
        payload: { device_id },
        callback,
    };
};
