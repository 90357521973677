/* eslint-disable react/prop-types */


// LIBRARIES
import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useDispatch, useSelector } from 'react-redux';

// COMPONENTS
import DeviceConfiguration from '../../Devices/DeviceConfiguration';
import DeviceScan from '../../Devices/DeviceScan';

// REDUX
import {
    LinkDevice,
    UpdateDevice,
} from '../../../../store/old/Devices/Devices.action';

import './AssetDevices.scss';
import { userSelector } from '../../../../store/old/Authentication/Authentication.selector';
const { TabPane } = Tabs;

let linkIndex = -1;

const AssetDevices = (props) => {
    const dispatch = useDispatch();
    const authUser = useSelector(userSelector);
    const { asset, devices } = props;

    const updateDevice = ({ device_id, data }, callback) => {
        dispatch(UpdateDevice({ device_id, data }, callback));
    };

    const onUnlinkDevice = () => {
        setActiveKey(devices.length ? `${devices[0].device_id}` : null);
    };

    const [linkSelection, setLinkSelection] = useState([]);
    const [activeKey, setActiveKey] = useState(
        devices.length ? `${devices[0].device_id}` : null
    );

    const add = () => {
        setLinkSelection(
            linkSelection.concat({ title: 'Link New Device', key: linkIndex })
        );
        setActiveKey(`${linkIndex}`);
        linkIndex--;
    };

    const remove = (targetKey) => {
        setLinkSelection(linkSelection.filter((s) => s.key !== +targetKey));
    };

    const onEdit = (targetKey, action) => {
        if (action === 'add') add();
        if (action === 'remove') remove(targetKey);
    };

    const onScan = (targetKey) => {
        return (result) => {
            dispatch(
                LinkDevice(asset.asset_id, result.device.device_id, (d) => {
                    setActiveKey(`${d.device_id}`);
                    remove(targetKey);
                })
            );
        };
    };

    const hasDevicesEditAccess = authUser.check_resource_policy(
        'devices',
        false,
        true,
        false
    );

    return (
        <Tabs
            tabPosition="left"
            type={hasDevicesEditAccess ? 'editable-card' : 'card'}
            tabBarStyle={{ width: 200 }}
            destroyInactiveTabPane
            onEdit={onEdit}
            onChange={setActiveKey}
            activeKey={activeKey}
            className="asset-devices"
        >
            {devices.map((d) => (
                <TabPane
                    tab={<span title={d.mac_address}>{d.mac_address}</span>}
                    key={d.device_id}
                    closable={false}
                >
                    <DeviceConfiguration
                        device={d}
                        submit={updateDevice}
                        onUnlink={onUnlinkDevice}
                    />
                </TabPane>
            ))}
            {linkSelection.map((s) => (
                <TabPane tab={s.title} key={s.key} closable>
                    <div className="w-100 h-100 d-flex">
                        <DeviceScan
                            hideOPCUA
                            hideGateway
                            hideRelay
                            onScan={onScan(s.key)}
                        />
                    </div>
                </TabPane>
            ))}
        </Tabs>
    );
};

export default AssetDevices;
