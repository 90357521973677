import React, {
    useState,
    useEffect,
    useContext,
    useMemo,
    createContext,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { unstable_batchedUpdates } from 'react-dom';
import moment from 'moment';
import * as _ from 'lodash';
import { isSameDay, isSameHour, isSameMinute } from 'date-fns';

// HOC
import { shouldUpdate } from '../../Wrappers/HOCs';

// COMPONENT
import { TargetSettingTimeline } from './TargetSettingTimeline';
import { AssetPlanTable } from './AssetPlanTable';
import { ActionHeader } from './ActionHeader';
import ConfirmationModal from '../../components/ConfirmationModal';
import { FormPlanModal } from './FormPlanModal';
import { errorFlash } from '../../components/Flash';

// DATE HELPER
import { getArrayFromTwoDates } from './targets.utils';

// ACTION
import {
    fetchEntityTargets,
    createTarget,
    updateTarget,
    deleteTarget,
    bulkCreateTargets,
} from '../../../store/old/Target/Target.action';
import { fetchAssetsSkusStdTimes } from '../../../store/old/Assets/Assets.action';

import { arrayAssets } from '../../../store/old/Assets/Assets.selector';

import { CREATE } from './Action';

import './index.scss';
import { arraySkus } from '../../../store/old/Sku/Sku.selector';
import { targetsUISetStore } from '../../../store/old/UI/TargetsUI/TargetsUI.action';
import { arrayTargets } from '../../../store/old/Target/Target.selector';
import { getAllLeaves } from '../../utils/helpers';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';
import { targetSettingQueryString } from '../../../store/old/UI/TargetsUI/TargetsUI.selector';
import { useNavigate } from 'react-router';
import { getQueryParams } from '../../utils/service';
import { withSkus } from '../../Wrappers/HOCs/withSkus';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { SPAWrapper } from '../../components/SPAWrapper';

const TimelineWithShouldUpdate = shouldUpdate(TargetSettingTimeline);
const AssetPlanTableShouldUpdate = shouldUpdate(AssetPlanTable);

export const TargetSettingContext = createContext(null);

const TargetSettingSimple = () => {
    const dispatch = useDispatch();

    const navigate = useNavigate();

    const { startDate, period } = useSelector((appState) => appState.ui.targets);
    const { entity_id } = useSelector(currentEntitySelector)
    const skuList = useSelector(arraySkus);
    const targetList = useSelector(arrayTargets);
    const assetList = useSelector(arrayAssets);

    const rootBlock = useSelector(entityBlock);
    const blocksResource = useSelector((appState) => appState.blocks.blocks);

    let leftGroupSize;
    let rightGroupSize;
    let elementSize;

    const [showModal, setShowModal] = useState(false);
    const [showConfirmationBox, setshowConfirmationBox] = useState(false);

    const [filteredBlock, setFilteredBlock] = useState(null);
    const [filteredSKU, setFilteredSKU] = useState(null);

    const [currentSKU, setCurrentSKU] = useState(null);
    const [currentPlan, setCurrentPlan] = useState(null);
    const [action, setAction] = useState('');

    const [screenSize, setScreenSize] = useState(0);
    const [enableStickyTimeLine, setEnableStickyTimeLine] = useState(false);
    const [selectedAssetIndex, setSelectedAssetIndex] = useState('');
    const [mouseTop, setMouseTop] = useState(300);

    const [activeAssetId, setActiveAssetId] = useState();

    // getters
    const endDate = useMemo(
        () => startDate.clone().add(period.size, period.unit),
        [startDate, period]
    );
    const arrayDates = useMemo(
        () => getArrayFromTwoDates(startDate, endDate, period.step),
        [startDate, period]
    );
    const arrayResolutions = useMemo(
        () => getArrayFromTwoDates(startDate, endDate, period.resolution),
        [startDate, period]
    );

    const assetsDisplayed = useMemo(() => {
        if (!filteredBlock) return assetList;

        return getAllLeaves(blocksResource[filteredBlock], (a) => a.asset).map(
            (b) => b.asset
        );
    }, [filteredBlock]);

    const filteredTargets = useMemo(() => {
        const displayedAssetsSet = new Set(assetsDisplayed);
        return targetList.filter((t) => t.asset && displayedAssetsSet.has(t.asset));
    }, [assetsDisplayed, targetList]);

    const targetSettingPlanTableElement = document.getElementById(
        'target-setting-table'
    );

    const setQuery = (force) => {
        const query = _.omit(getQueryParams(window.location.search), ['entity_id']);

        if (_.isEmpty(query) || force) {
            navigate(window.location.pathname + targetSettingQueryString());
        }
    };

    const changeModalStatus = () => {
        setShowModal(!showModal);
    };

    const changeConfirmationBoxStatus = () => {
        setshowConfirmationBox(!showConfirmationBox);
    };

    const fetchTargetsInRange = () => {
        const query = {
            date_range: {
                lower: startDate.toISOString(),
                upper: endDate.toISOString(),
            },
        };
        dispatch(fetchEntityTargets(query));
    };

    const changePeriod = (p) => {
        dispatch(targetsUISetStore({ period: p }));
    };

    const changeStartDate = (d) => {
        dispatch(targetsUISetStore({ startDate: d.startOf('day') }));
    };

    const handleCreatePlan = (item) => {
        const startDateRequest = new Date(item.from);
        const endDateRequest = new Date(item.to);

        if (
            isSameDay(startDateRequest, endDateRequest) &&
      isSameHour(startDateRequest, endDateRequest) &&
      isSameMinute(startDateRequest, endDateRequest)
        ) {
            return errorFlash({
                message: 'Update Plan Error',
                details: 'Start Date can be same as End Date',
            });
        }

        changeModalStatus();
        dispatch(createTarget(item));
    };

    const handleCreateManyPlans = (item, callback) => {
        dispatch(bulkCreateTargets(item, callback));
    };

    const handleUpdatePlan = (item) => {
        const startDateRequest = new Date(item.from);
        const endDateRequest = new Date(item.to);

        if (
            isSameDay(startDateRequest, endDateRequest) &&
      isSameHour(startDateRequest, endDateRequest) &&
      isSameMinute(startDateRequest, endDateRequest)
        ) {
            return errorFlash({
                message: 'Update Plan Error',
                details: 'Start Date can be same as End Date',
            });
        }

        changeModalStatus();
        dispatch(updateTarget({ target_id: currentPlan.target_id, ...item }));
    };

    const handleDeleteTarget = () => {
        changeConfirmationBoxStatus();
        changeModalStatus();
        dispatch(deleteTarget(currentPlan.target_id));
    };

    const invokeCreatePlan = (arrayIndex, setArrayIndex, clickedAssetId) => {
        const sortedArrayIndex = _.sortBy(arrayIndex);
        const hoursStep = period.resolution / 60;
        const from = moment(arrayResolutions[_.first(sortedArrayIndex)].id);
        const to = moment(arrayResolutions[_.last(sortedArrayIndex)].id).add(
            hoursStep,
            'hours'
        );

        unstable_batchedUpdates(() => {
            const plan = {
                target: 0,
                asset_id: clickedAssetId,
                sku_id: currentSKU || null,
                from: from,
                to: to,
            };
            changeModalStatus();
            setAction(CREATE);
            setCurrentPlan(plan);
            setArrayIndex([]);
            setSelectedAssetIndex('');
            setActiveAssetId(null);
        });
    };

    useEffect(() => {
        setQuery();
        dispatch(fetchAssetsSkusStdTimes(entity_id));
    }, []);

    useEffect(() => {
        setQuery(true);
        fetchTargetsInRange();
    }, [startDate, period]);

    elementSize = screenSize < 1000 ? 1000 : screenSize - 50;
    leftGroupSize = 200;
    rightGroupSize = elementSize - leftGroupSize - 60;

    useEffect(() => {
        handleChangeScreenSize();
        window.addEventListener('resize', handleChangeScreenSize);
        return () => {
            window.removeEventListener('resize', handleChangeScreenSize);
        };
    }, [screenSize]);

    useEffect(() => {
        if (targetSettingPlanTableElement) {
            targetSettingPlanTableElement.addEventListener(
                'scroll',
                handlePlanTableScroll,
                false
            );
        }

        return () => {
            if (targetSettingPlanTableElement) {
                targetSettingPlanTableElement.removeEventListener(
                    'scroll',
                    handlePlanTableScroll,
                    false
                );
            }
        };
    }, [targetSettingPlanTableElement]);

    const handlePlanTableScroll = () => {
        const scrollTopTargetTable = targetSettingPlanTableElement.scrollTop;
        const rowTimelinePosition =
      document.getElementsByClassName('row timeline')[0].offsetTop;

        if (scrollTopTargetTable > rowTimelinePosition + 60) {
            setEnableStickyTimeLine(true);
        }

        if (scrollTopTargetTable < rowTimelinePosition) {
            setEnableStickyTimeLine(false);
        }
    };

    const handleChangeScreenSize = () => {
        const deviceSize = window.innerWidth;
        setScreenSize(deviceSize);
    };

    const tsContext = {
        store: {
            assetList,
            skuList,
            rootBlock,
            targetList,
        },
        startDate,
        endDate,
        period,
        changeStartDate,
        changePeriod,
        setCurrentSKU,
        changeModalStatus,
        showModal,
        currentPlan,
        handleCreatePlan,
        handleCreateManyPlans,
        handleUpdatePlan,
        setCurrentPlan,
        handleDeleteTarget,
        showConfirmationBox,
        changeConfirmationBoxStatus,
        arrayResolutions,
        setAction,
        action,
        invokeCreatePlan,
        selectedAssetIndex,
        setSelectedAssetIndex,
        plans: filteredTargets,
        screenSize,
        leftGroupSize,
        rightGroupSize,
        arrayDates,
        enableStickyTimeLine,
        mouseTop,
        setMouseTop,
        activeAssetId,
        setActiveAssetId,
        setFilteredBlock,
        filteredSKU,
        setFilteredSKU,
        assetsDisplayed,
    };

    return (
        <TargetSettingContext.Provider value={tsContext}>
            <SPAWrapper
                id="target-setting-table"
                className="target-setting"
                style={{
                    height: window.innerHeight - 59 - 52,
                    overflowY: 'scroll',
                }}
            >
                <FormPlanModal />
                <ConfirmationBox />
                <ActionHeader />
                <div
                    className="target-setting-body"
                    style={{ width: elementSize - 60 }}
                >
                    <TimelineWithShouldUpdate />
                    <AssetPlanTableShouldUpdate />
                </div>
            </SPAWrapper>
        </TargetSettingContext.Provider>
    );
};

const ConfirmationBox = () => {
    const {
        showConfirmationBox,
        changeConfirmationBoxStatus,
        handleDeleteTarget,
    } = useContext(TargetSettingContext);

    return (
        <ConfirmationModal
            showConfirmation={showConfirmationBox}
            toggleConfirmation={changeConfirmationBoxStatus}
            action="Delete Target"
            message={
                <>
                    <p>You are about to delete this target</p>
                    <p>Would you like to continue?</p>
                </>
            }
            onConfirmation={handleDeleteTarget}
        />
    );
};

export const TargetSetting = withSkus(TargetSettingSimple);
