import { updateStoreRedux } from '../../../legacy/utils/redux';
import { NotificationConstants as K } from './Notifications.constants';

const initialReducer = {
    notifications: {},
    error: {},
};

export const notificationsReducer = (
    state = initialReducer,
    action
) => {
    switch (action.type) {
    case K.ACTIONS.FETCH_NOTIFICATIONS_REQUEST_SUCCESS:
        return updateStoreRedux(state, {
            notifications: { ...action.payload },
        });
    case K.ACTIONS.CREATE_NOTIFICATIONS_REQUEST_SUCCESS:
        const { payload: created } = action;
        return updateStoreRedux(state, {
            notifications: {
                ...state.notifications,
                [created.notification_id]: created,
            },
        });
    case K.ACTIONS.UPDATE_NOTIFICATIONS_REQUEST_SUCCESS:
        const { payload: updated } = action;
        return updateStoreRedux(state, {
            notifications: {
                ...state.notifications,
                [updated.notification_id]: updated,
            },
        });
    case K.ACTIONS.DELETE_NOTIFICATIONS_REQUEST_SUCCESS:
        const { payload: notification_id } = action;
        const result = { ...state.notifications };
        delete result[notification_id];
        return updateStoreRedux(state, {
            notifications: {
                ...result,
            },
        });
    default:
        return updateStoreRedux(state, {});
    }
};
