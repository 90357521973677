import { values } from 'lodash';
import CONSTANTS from '../Constants';
import { OEEFactory } from '../utils/oee';
import { store } from '../../store';

export const parseAssetArguments = (a) => {
    return [
        a.asset_code,
        a.asset_id,
        a.asset_name,
        a.brand,
        a.model,
        a.block_id ? a.block_id : a.block.block_id,
        a.asset_img,
        a.custom_fields,
        a.autoUpdate,
        a.speed,
        a.stdTime,
    ];
};

export class Asset {
    constructor(
        asset_code = null,
        asset_id = -1,
        asset_name = '',
        brand = null,
        model = null,
        block_id = -1,
        asset_img = null,
        custom_fields = [],
        autoUpdate,
        speed,
        stdTime
    ) {
        this.asset_code = asset_code;
        this.asset_id = asset_id;
        this.asset_name = asset_name;
        this.brand = brand || '';
        this.model = model || '';
        this.block_id = block_id;
        this.asset_img = asset_img;
        this.custom_fields = custom_fields;
        this.autoUpdate = autoUpdate || false;
        this.speed = speed || false;
        this.stdTime = stdTime || 0;
    }

    get tile() {
        const {
            tiles: { tiles },
        } = store.getState();
        let currentTile;

        for (const key in tiles) {
            if (tiles.hasOwnProperty(key)) {
                const tile = tiles[key];

                if (tile.asset_id === this.asset_id) {
                    currentTile = tile;

                    break;
                }
            }
        }

        return currentTile || null;
    }

    get image() {
        return this.asset_img && this.asset_img.signedUrl;
    }

    get block() {
        const {
            blocks: { blocks: blocksState },
        } = store.getState();

        return blocksState[this.block_id];
    }

    get devices() {
        const {
            devices: { devices: devicesState },
        } = store.getState();

        const devices = values(devicesState).filter(
            (d) => d.asset_id === this.asset_id
        );
        return devices;
    }

    get metadata() {
        const {
            metadata: { metadata: metadataState },
        } = store.getState();

        const deviceIds = this.devices.map((d) => d.device_id);
        return values(metadataState).filter((m) => deviceIds.includes(m.device_id));
    }

    get fusions() {
        const {
            fusions: { fusions: fusionsState },
        } = store.getState();

        return values(fusionsState).filter((f) => f.asset_id === this.asset_id);
    }

    get charts() {
        return this.fusions
            .concat(this.metadata)
            .sort((a, b) => a.chart_order - b.chart_order);
    }

    get primary() {
        if (!this.charts.length) return;

        return this.charts.sort(
            (a, b) => Number(a.chart_order) - Number(b.chart_order)
        )[0];
    }

    get skuOeeEnabled() {
        return !!this.devices.filter(
            (d) => d.subscriptions && d.subscriptions.length
        ).length;
    }

    get labelFields() {
        return CONSTANTS.LABELS.concat(...this.custom_fields).map((t, i) => ({
            ...t,
            id: i + 1,
        }));
    }

    get oee() {
        const { data } = store.getState();

        return data.blocks[this.block_id] || OEEFactory({});
    }

    get targets() {
        const {
            target: {
                assetsTargets: assetsTargetsResource,
                targets: targetsResource,
            },
        } = store.getState();

        const assetTargets = assetsTargetsResource[this.asset_id] || [];

        return assetTargets
            .map((target_id) => targetsResource[target_id])
            .sort((a, b) => new Date(a.from) - new Date(b.from));
    }

    get labels() {
        const {
            label: { labels: labelsState },
        } = store.getState();

        return values(labelsState)
            .filter((l) => l.asset_id === this.asset_id)
            .sort((a, b) => b.from - a.from);
    }

    get issueLabels() {
        return this.labels.filter((l) => l.issue);
    }

    get productionLabels() {
        return this.labels.filter((l) => !l.issue);
    }

    get liveIssueLabel() {
        return this.issueLabels.find((l) => !l.isComplete);
    }

    get liveProductLabel() {
        return this.productionLabels.find((l) => !l.isComplete);
    }
}
