import { InvitationConstants as K } from './Invitation.constant';

export const getAllInvitationsRequest = (callback) => {
    return {
        type: K.ACTIONS.FETCH_INVITATIONS_REQUEST,
        callback,
    };
};

export const getAllInvitationsSuccess = (invitations) => {
    return {
        type: K.ACTIONS.FETCH_INVITATIONS_SUCCESS,
        payload: {
            list: invitations,
        },
    };
};

export const getAllInvitationsFailure = (error) => {
    return {
        type: K.ACTIONS.FETCH_INVITATIONS_FAILURE,
        payload: {
            error,
        },
    };
};

export const createInvitationRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.CREATE_INVITATION_REQUEST,
        payload,
        callback,
    };
};

export const createInvitationFailure = (error) => {
    return {
        type: K.ACTIONS.CREATE_INVITATION_FAILURE,
        payload: {
            error,
        },
    };
};

export const updateInvitationRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.UPDATE_INVITATION_REQUEST,
        payload,
        callback,
    };
};

export const updateInvitationFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_INVITATION_FAILURE,
        payload: { error },
    };
};

export const revokeInvitationRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.REVOKE_INVITATION_REQUEST,
        payload,
        callback,
    };
};

export const revokeInvitationFailure = (error) => {
    return {
        type: K.ACTIONS.REVOKE_INVITATION_FAILURE,
        payload: { error },
    };
};
