/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */
import React, {
    forwardRef,
    useCallback,
    useEffect,
    useImperativeHandle,
    useMemo,
    useState,
} from 'react';
import { isEmpty, keys, mapValues } from 'lodash';
import { useDispatch } from 'react-redux';
import { Form, Input, Select } from 'antd';
import ChannelRow, { ChannelHeader } from './ChannelRow';
import AukButton from '../../components/AukButton';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import { getMapFromArr, regexMatch } from '../../utils/helpers';
import { errorFlash, flash } from '../../components/Flash';
import { api_previewSerialData } from '../../../store/old/Devices/Devices.services';
import { getDefaultSpeedAttribute } from '../../models';
import { startLoading, stopLoading } from '../../../store/old/UI/Loaders/Loader.action';
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';

const NODE_CHANNELS = 6;
const getChannelRows = (n) => Array.from(Array(n), (_, x) => x);

const CHANNEL_REGEX = /^(\d)$/;

const CHANNELS_VALIDATE = {
    CMD: [
        (form) => ({
            validator: (_, value) => {
                const interfaceType = form.getFieldValue('type');
                if (interfaceType !== K.RS232) return Promise.resolve();
                const dmode = form.getFieldValue('dmode');
                if (dmode === K.DMODE_PUSH) return Promise.resolve();
                if (!value.trim())
                    return Promise.reject(new Error('Command cannot be empty'));
                return Promise.resolve();
            },
        }),
    ],
    SLAVE_ID: [
        (form) => ({
            validator: (_, value) => {
                const interfaceType = form.getFieldValue('type');
                if (interfaceType !== K.RS485) return Promise.resolve();

                if (value === '') {
                    return Promise.reject(new Error('Slave ID is required'));
                }

                if (+value < 0 || +value > 255)
                    return Promise.reject(
                        new Error('Slave ID must be between 0 and 255')
                    );
                return Promise.resolve();
            },
        }),
    ],
    ADDRESS: [
        (form) => ({
            validator: (_, value) => {
                const interfaceType = form.getFieldValue('type');
                if (interfaceType !== K.RS485) return Promise.resolve();

                if (!value.length) {
                    return Promise.reject(
                        new Error('Must have a minimum of one register.')
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
    REG: (fieldName) => [
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Register address must be greater than 0',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 65535,
            message: 'Register address cannot be greater than 65535',
        },
        (form) => ({
            validator: (_, value) => {
                const interfaceType = form.getFieldValue('type');
                if (interfaceType !== K.RS485) return Promise.resolve();

                const addresses = form.getFieldValue('address');
                const address = addresses[fieldName];

                if (+value < 0 || +value < 65535) {
                }

                if (value && address.regSize === undefined) {
                    return Promise.resolve(
                        form.setFields([
                            {
                                name: ['address', fieldName, 'regSize'],
                                errors: ['Register size is required'],
                            },
                        ])
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
    REG_SIZE: (fieldName) => [
        {
            transform: (value) => +value,
            type: 'number',
            min: 0,
            message: 'Register size must be greater than 0',
        },
        {
            transform: (value) => +value,
            type: 'number',
            max: 65535,
            message: 'Register size cannot be greater than 65535',
        },
        (form) => ({
            validator: (_, value) => {
                const interfaceType = form.getFieldValue('type');
                if (interfaceType !== K.RS485) return Promise.resolve();

                const addresses = form.getFieldValue('address');
                const address = addresses[fieldName];

                if (value && address.reg === undefined) {
                    return Promise.resolve(
                        form.setFields([
                            {
                                name: ['address', fieldName, 'reg'],
                                errors: ['Register address is required'],
                            },
                        ])
                    );
                }

                return Promise.resolve();
            },
        }),
    ],
};

const ChannelsForm = forwardRef((props, ref) => {
    const {
        isSerial,
        device,
        hidden,
        interfaceType,
        onChange,
        submit,
        isInit,
    } = props;
    const dispatch = useDispatch();
    const [form] = Form.useForm();

    const [preview, setPreview] = useState('');
    const [serialMap, setSerialMap] = useState(device.channelSerialMap);
    const [serialDmode, setSerialDmode] = useState(device.dmode);

    const isPoll = serialDmode === 'poll';
    const isRS232 = interfaceType === K.RS232;
    const hideDmode = !(isSerial && isRS232);
    const hideCmd = !(isSerial && isRS232 && isPoll);
    const hideRS485 = !(isSerial && !isRS232);

    const channelRows = useMemo(() => {
        return isSerial
            ? getChannelRows(keys(serialMap).length)
            : getChannelRows(NODE_CHANNELS);
    }, [preview, serialMap, isSerial]);

    const channels = getMapFromArr(device.metadata, 'channel');

    const getSerialPreviewData = () => {
        const data = form.getFieldsValue(true);

        if (isRS232 && isPoll) return { cmd: data.cmd };
        if (isRS232 && !isPoll) return {};

        const { reg, regSize } = getRegAddress(data.address);

        return {
            address: {
                reg,
                regSize,
                slaveId: data.slaveId,
            },
        };
    };

    const getSerialPreview = async () => {
        try {
            const getPreview = async () => {
                const res = await api_previewSerialData({
                    device_id: device.device_id,
                    data: getSerialPreviewData(),
                });

                setPreview(res.message);
            };

            if (isInit || !device.gateway_id) {
                // replace with pure device init
                submit(() => {
                    dispatch(startLoading());
                    flash({
                        message: 'Generating preview, please wait...',
                        status: 'warning',
                    });
                    setTimeout(async () => {
                        await getPreview();
                        dispatch(stopLoading());
                    }, 5000);
                });
            } else {
                await getPreview();
            }
        } catch (e) {
            errorFlash({
                message:
          'Error generating preview, please check that device is connected',
            });
        }
    };

    useEffect(() => {
        if (!preview) return;

        const data = form.getFieldsValue(true);

        const previewResult = preview.match(K.REGEX_OPTIONS[0].rawLabel) || [];

        const _serialMap = previewResult.reduce(
            (acc, curr, index) => ({
                ...acc,
                [index]: [
                    isRS232 ? (isPoll ? data.cmd : '-') : data.address[index].reg,
                    +curr,
                ],
            }),
            {}
        );

        setSerialMap(_serialMap);
    }, [preview]);

    useEffect(
        () => onChange(getFormData(form.getFieldsValue(true))),
        [channelRows]
    );

    const getChannelsFormData = (formData) => {
        return keys(formData)
            .filter((k) => regexMatch(k, CHANNEL_REGEX))
            .map((k) => formData[k])
            .filter((m) => m.mode && m.chart_title)
            .map((m) => {
                if (m.mode === '3a') {
                    const batRec = mapValues(m.batRec, (v) => +v);

                    // if batRec configured
                    !isEmpty(batRec) && (m.batRec = batRec);

                    // if batRec not configured and newly initialized channel
                    !m.batRec &&
            !m.metadata_id &&
            (m.batRec = { upp: 99999, low: 0.001, in: 1, out: 1 });
                }
                if (m.mode !== '3a') delete m.batRec;
                if (m.mode !== '2a') delete m.invert;
                m.type = formData.type;
                return m;
            });
    };

    const getRegAddress = (registers) => {
        const reg = registers.map((r) => r.reg);
        const regSize = registers.map((r) => r.regSize);
        return { reg, regSize };
    };

    const getSerialOrder = (metadata) => {
        return metadata.reduce(
            (acc, curr) => ({
                ...acc,
                [curr.channel]: { ch: `${curr.channel}`, mode: curr.mode },
            }),
            {}
        );
    };

    const getRS232FormData = (formData, metadata) => {
        const result = {
            dmode: formData.dmode,
        };

        const data = {
            regex: K.REGEX_OPTIONS[0].label,
            map: getSerialOrder(metadata),
        };

        if (formData.dmode !== K.DMODE[1].value) {
            data.cmd = { enc: 'ascii', val: formData.cmd };
        }

        result.data = [data];

        return result;
    };

    const getRS485FormData = (formData, metadata) => {
        return {
            dmode: K.DMODE[0].value,
            data: [
                {
                    address: {
                        ...getRegAddress(formData.address.filter((a) => a)),
                        slaveId: formData.slaveId,
                    },
                    map: getSerialOrder(metadata),
                    regex: K.REGEX_OPTIONS[0].label,
                },
            ],
        };
    };

    const getFormData = useCallback(
        (data) => {
            let metadata = getChannelsFormData(data);

            if (!isSerial) return { metadata };

            if (isRS232) {
                return { metadata, serial: getRS232FormData(data, metadata) };
            }

            // is rs485
            metadata = metadata.slice(0, channelRows.length);
            return { metadata, serial: getRS485FormData(data, metadata) };
        },
        [isSerial, isRS232, channelRows]
    );

    useImperativeHandle(ref, () => ({
        getFormData() {
            return getFormData(form.getFieldsValue(true));
        },
    }));

    const isChangedChannel = (obj) => {
        const [channel] = keys(obj).filter((k) => regexMatch(k, /\d/g));
        return channel;
    };

    useEffect(() => {
        form.setFields([{ name: 'type', value: interfaceType }]);
    }, [form, interfaceType]);

    return (
        <Form
            ref={ref}
            name="channelsForm"
            form={form}
            className="d-flex w-100 h-100 flex-column justify-content-between"
            initialValues={{ remember: true }}
            onValuesChange={(changedValues) => {
                if (changedValues.dmode) setSerialDmode(changedValues.dmode);

                const channel = isChangedChannel(changedValues);
                if (channel && changedValues[channel].mode) {
                    form.setFields([
                        {
                            name: [channel, 'speed'],
                            value: getDefaultSpeedAttribute(changedValues[channel].mode),
                        },
                    ]);
                }

                onChange && onChange(getFormData(form.getFieldsValue(true)));
            }}
            hidden={hidden}
            preserve
            key={device.metadata.length}
        >
            <Form.Item noStyle name="type" initialValue={interfaceType} hidden={true}>
                <Input disabled />
            </Form.Item>
            <Form.Item
                name="dmode"
                label="Poll / Push"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValue={device.dmode || 'poll'}
                hidden={hideDmode}
            >
                <Select className="w-100" options={K.DMODE} />
            </Form.Item>
            <Form.Item
                name="cmd"
                label="Command (Poll)"
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                initialValue={device.cmd}
                hidden={hideCmd}
                rules={CHANNELS_VALIDATE.CMD}
            >
                <Input.TextArea className="w-100" maxLength={50} />
            </Form.Item>
            <Form.Item
                name="slaveId"
                label="Slave ID"
                initialValue={device.slaveId}
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                hidden={hideRS485}
                rules={CHANNELS_VALIDATE.SLAVE_ID}
            >
                <Input className="w-100" type="number" />
            </Form.Item>
            <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label="Registers"
                hidden={hideRS485}
            >
                <Form.List
                    name="address"
                    initialValue={device.registers}
                    rules={CHANNELS_VALIDATE.ADDRESS}
                >
                    {(fields, { add, remove }, { errors }) => {
                        return (
                            <>
                                {fields.map((field, i) => {
                                    return (
                                        <Form.Item noStyle key={i}>
                                            <Input.Group className="d-flex align-items-center">
                                                <Form.Item
                                                    className="pr-2"
                                                    style={{ flexGrow: 1 }}
                                                    name={[field.name, 'reg']}
                                                    rules={CHANNELS_VALIDATE.REG(field.name)}
                                                >
                                                    <Input
                                                        addonBefore={
                                                            <span style={{ width: 50 }}>Address</span>
                                                        }
                                                        type="number"
                                                        className="w-100"
                                                    />
                                                </Form.Item>
                                                <Form.Item
                                                    className="pl-2"
                                                    style={{ flexGrow: 1 }}
                                                    name={[field.name, 'regSize']}
                                                    rules={CHANNELS_VALIDATE.REG_SIZE(field.name)}
                                                >
                                                    <Input
                                                        addonBefore={
                                                            <span style={{ width: 50 }}>Size</span>
                                                        }
                                                        type="number"
                                                        className="w-100"
                                                    />
                                                </Form.Item>
                                                <Form.Item className="mx-3">
                                                    <MinusCircleOutlined
                                                        onClick={() => remove(field.name)}
                                                    />
                                                </Form.Item>
                                            </Input.Group>
                                        </Form.Item>
                                    );
                                })}

                                <Form.Item>
                                    <AukButton.Outlined
                                        type="dashed"
                                        onClick={() => add()}
                                        block
                                        icon={<PlusOutlined />}
                                    >
                    Add Address
                                    </AukButton.Outlined>
                                    <Form.ErrorList errors={errors} />
                                </Form.Item>
                            </>
                        );
                    }}
                </Form.List>
            </Form.Item>
            {isSerial && preview && (
                <Form.Item
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                    label="Data Preview"
                >
                    <Input.TextArea className="w-100" value={preview} disabled />
                </Form.Item>
            )}
            <Form.Item
                labelCol={{ span: 6 }}
                wrapperCol={{ span: 18 }}
                label={' '}
                colon={false}
                hidden={!isSerial}
            >
                <AukButton.Blue className="w-100" onClick={getSerialPreview}>
          Preview Inputs
                </AukButton.Blue>
            </Form.Item>
            <hr style={{ width: '60%', display: isSerial ? 'block' : 'none' }} />
            <ChannelHeader hideMapping={!isSerial || isEmpty(serialMap)} />
            {channelRows.map((c) => (
                <Form.Item noStyle name={`${c}`} key={c}>
                    <ChannelRow
                        form={form}
                        key={c}
                        data={channels[+c]}
                        channel={+c}
                        serialMap={serialMap}
                        isSerial={isSerial}
                        handleDelete={() => {
                            form.setFields([
                                { name: [`${c}`, 'chart_title'], value: '', errors: [] },
                                { name: [`${c}`, 'mode'], value: null, errors: [] },
                                { name: [`${c}`, 'batRec', 'upp'], value: 99999, errors: [] },
                                { name: [`${c}`, 'batRec', 'low'], value: 0.001, errors: [] },
                                { name: [`${c}`, 'batRec', 'in'], value: 1, errors: [] },
                                { name: [`${c}`, 'batRec', 'out'], value: 1, errors: [] },
                            ]);
                            onChange && onChange(getFormData(form.getFieldsValue(true)));
                        }}
                    />
                </Form.Item>
            ))}
        </Form>
    );
});

export default ChannelsForm;
