import { SummaryTile, Tile } from '../../../legacy/models';

import { TilesConstants as K } from './Tiles.constants';

export const ResetDashboardTilesState = () => {
    return {
        type: K.ACTIONS.RESET_DASHBOARD_TILES_STATE,
    };
};

export const SetDashboardTilesState = (tiles, summary_tiles) => {
    return {
        type: K.ACTIONS.SET_DASHBOARD_TILES_STATE,
        payload: { tiles, summary_tiles },
    };
};

export const AddTilesState = (tile) => {
    return {
        type: K.ACTIONS.ADD_TILES_STATE,
        payload: { tile },
    };
};

export const SetTilesState = (tiles) => {
    return {
        type: K.ACTIONS.SET_TILES_STATE,
        payload: { tiles },
    };
};

export const DeleteTilesState = (tile_ids) => {
    return {
        type: K.ACTIONS.DELETE_TILES_STATE,
        payload: { tile_ids },
    };
};

export const getAllSummaryTilesRequest = (callback) => {
    return {
        type: K.ACTIONS.GET_ALL_SUMMARY_TILES_REQUEST,
        callback,
    };
};

export const getAllSummaryTilesSuccess = (summary_tiles) => {
    return {
        type: K.ACTIONS.GET_ALL_SUMMARY_TILES_SUCCESS,
        payload: {
            summary_tiles,
        },
    };
};

export const getAllSummaryTilesFailure = (error) => {
    return {
        type: K.ACTIONS.GET_ALL_SUMMARY_TILES_FAILURE,
        payload: {
            error,
        },
    };
};

export const createSummaryTileRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.CREATE_SUMMARY_TILE_REQUEST,
        payload,
        callback,
    };
};

export const createSummaryTileSuccess = (payload, callback) => {
    return {
        type: K.ACTIONS.CREATE_SUMMARY_TILE_SUCCESS,
        payload,
        callback,
    };
};

export const createSummaryTileFailure = (error) => {
    return {
        type: K.ACTIONS.CREATE_SUMMARY_TILE_FAILURE,
        payload: {
            error,
        },
    };
};

export const editSummaryTileRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.EDIT_SUMMARY_TILE_REQUEST,
        payload,
        callback,
    };
};

export const editSummaryTileSuccess = (summary_tile, callback) => {
    return {
        type: K.ACTIONS.EDIT_SUMMARY_TILE_SUCCESS,
        payload: { summary_tile },
        callback,
    };
};

export const editSummaryTileFailure = (
    error
) => {
    return {
        type: K.ACTIONS.EDIT_SUMMARY_TILE_FAILURE,
        payload: {
            error,
        },
    };
};

export const deleteSummaryTileRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.DELETE_SUMMARY_TILE_REQUEST,
        payload,
        callback,
    };
};

export const deleteSummaryTileSuccess = (summary_tile_ids, callback) => {
    return {
        type: K.ACTIONS.DELETE_SUMMARY_TILE_SUCCESS,
        payload: { summary_tile_ids },
        callback,
    };
};

export const deleteSummaryTileFailure = (error) => {
    return {
        type: K.ACTIONS.DELETE_SUMMARY_TILE_FAILURE,
        payload: {
            error,
        },
    };
};

export const editTileRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.EDIT_TILE_REQUEST,
        payload,
        callback,
    };
};

export const editTileSuccess = (payload, callback) => {
    return {
        type: K.ACTIONS.EDIT_TILE_SUCCESS,
        payload,
        callback,
    };
};

export const updateTilePositionFailure = (error) => {
    return {
        type: K.ACTIONS.UPDATE_TILE_POSITION_FAILURE,
        payload: {
            error,
        },
    };
};
