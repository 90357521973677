import { all, call, put, takeLatest } from 'redux-saga/effects';
import { has, isEmpty } from 'lodash';

// SERVICES
import {
    api_updateAssetStandardTime,
    api_updateAsset,
    api_updateAssetChartOrder,
    api_updateAssetOperatorSkus,
    api_getAssets,
    api_getAssetsSkusStandardTimes,
    api_updateAssetSkuStandardTime,
    api_createAssetSkuStandardTime,
    api_deleteAsset,
    api_deleteAssetsPure,
    api_createAsset,
    api_bulkCreateUpdateAssetSkuStandardTimes,
    api_getAssetOperatorPermittedLabels,
} from './Assets.services';
import {
    api_createEntityPreference,
    api_getAssetOperatorPreferences,
    api_updateEntityPreference,
} from '../Preference/Preference.services';
import {
    parseAssetArguments,
    Asset,
    StandardTime,
    parseStdTimeArguments,
    Fusion,
    parseFusionArguments,
    Metadatum,
    parseMetadatumArguments,
    parseBlockArguments,
    Block,
} from '../../../legacy/models';

// HELPERS
import { watcherBuilder } from '../Base/Base.saga';
import { AssetsConstants as K } from './Assets.constants';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { errorFlash, flash } from '../../../legacy/components/Flash';

// REDUX STORE
import { store } from '../..';
import {
    addAssetsResource,
    addAssetSkuStdTimeResource,
    cascadeDeleteAsset,
    removeAssetsResource,
    setAssetsState,
    setAssetOperatorPermittedLabels,
} from './Assets.action';
import { DeleteTilesState } from '../Tiles/Tiles.action';
import {
    AddMetadataResource,
    RemoveMetadataResource,
    SetMetadataResource,
} from '../Metadata/Metadata.action';
import {
    AddFusionsResource,
    RemoveFusionsResource,
    SetFusionsResource,
} from '../Fusions/Fusions.action';
import { setAssetOperatorPreferences } from '../Preference/Preference.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { RemoveDevicesResource } from '../Devices/Devices.action';
import {
    addBlocksResource,
    removeBlocksResource,
} from '../Blocks/Blocks.action';
import { RemoveWidgetsResource } from '../Widgets/Widget.action';


function* handleUpdateAssetStandardTime(action) {
    try {
        const { payload } = action;

        const data = Object.assign(
            {},
            has(payload.data, 'stdTime') ? { stdTime: payload.data.stdTime } : {},
            has(payload.data, 'autoUpdate') ? { autoUpdate: payload.data.autoUpdate } : {},
            has(payload.data, 'speed') ? { speed: payload.data.speed } : {}
        );

        if (has(payload.data, 'stdTime') && payload.data.stdTime <= 0) {
            throw {
                message: 'The standard time should be greater than 0',
            };
        }

        const response = yield call(
            api_updateAssetStandardTime,
            payload.entity_id,
            action.payload.data.asset_id,
            data
        );

        const { assets } = store.getState().assets;
        const _asset = assets[action.payload.data.asset_id];

        const updated = new Asset(
            ...parseAssetArguments({
                ..._asset,
                ...data,
            })
        );

        const { primary } = updated;
        if (primary.fusion_id) {
            const fusion = new Fusion(
                ...parseFusionArguments({ ...primary.serialize(), ...data })
            );

            yield put(AddFusionsResource({ [fusion.fusion_id]: fusion }));
        } else if (primary.metadata_id) {
            const metadatum = new Metadatum(
                ...parseMetadatumArguments({ ...primary.serialize(), ...data })
            );
            yield put(AddMetadataResource({ [metadatum.metadata_id]: metadatum }));
        }

        flash({
            message: `Update success`,
            status: 'success',
        });

        yield put(addAssetsResource({ [updated.asset_id]: updated }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetStandardTimeSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_STANDARD_TIME,
        handleUpdateAssetStandardTime
    );
}

function* handleCreateAsset(action) {
    try {

        const response = yield call(api_createAsset, action.payload.entity_id, action.payload.asset);
        const asset = new Asset(...parseAssetArguments(response.asset));
        const block = new Block(...parseBlockArguments(response.block));

        yield all([
            put(addAssetsResource({ [asset.asset_id]: asset })),
            put(addBlocksResource({ [block.block_id]: block })),
        ]);

        flash({ message: 'Asset created.' });
        if (action.callback) {
            return action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createAssetSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET,
        handleCreateAsset
    );
}

function* handleUpdateAsset(action) {
    try {
        const { asset, entity_id } = action.payload;

        const response = yield call(api_updateAsset, entity_id, asset.asset_id, asset);
        const updatedAsset = new Asset(...parseAssetArguments(response));

        yield put(addAssetsResource({ [asset.asset_id]: updatedAsset }));

        flash({ message: 'Asset updated.' });

        if (action.callback) {
            return action.callback(updatedAsset);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET,
        handleUpdateAsset
    );
}

// function* handleDeleteAssetPure(action) {
//     try {
//         const { assets } = action.payload;
//         const deleteIds = assets.map((a) => a.asset_id);

//         const response = yield call(api_deleteAssetsPure, { assets: deleteIds });
//         const blocks = getMapFromArr(
//             response.blocks.map((b) => new Block(...parseBlockArguments(b))),
//             'block_id'
//         );

//         yield all([
//             put(removeAssetsResource(deleteIds)),
//             put(setBlocksState({ blocks })),
//         ]);

//         flash({ message: 'Asset deleted.' });

//         if (action.callback) {
//             return action.callback();
//         }
//         return;
//     } catch (error) {
//         errorFlash(error);
//     }
// }

// export function* handleDeleteAssetPureSaga() {
//     yield takeLatest(
//         K.ACTIONS.DELETE_ASSET_PURE,
//         handleDeleteAssetPure
//     );
// }

function* handleUpdateAssetChartOrders(action) {
    try {
        const { entity_id, asset_id, chart_orders } = action.payload;

        const response = yield call(api_updateAssetChartOrder, entity_id, asset_id, {
            chart_order: chart_orders,
        });

        const {
            metadata: { metadata },
            fusions: { fusions },
        } = store.getState();

        const metadataState = { ...metadata };
        const fusionsState = { ...fusions };

        for (let i = 0; i < chart_orders.length; i++) {
            const current = chart_orders[i];
            if (current.fusion_id) {
                const fusion = fusionsState[current.fusion_id];
                fusion.updateChartOrder(current.chart_order);
            }

            if (current.metadata_id) {
                const metadatum = metadataState[current.metadata_id];
                metadatum.updateChartOrder(current.chart_order);
            }
        }

        yield all([
            put(SetMetadataResource(metadataState)),
            put(SetFusionsResource(fusionsState)),
        ]);

        if (action.callback) {
            return action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetChartOrdersSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_CHART_ORDERS,
        handleUpdateAssetChartOrders
    );
}

function* handleFetchAssetOperatorSettings(action) {
    try {
        const { asset_id, entity_id } = action.payload;

        const whitelist = yield call(
            api_getAssetOperatorPermittedLabels,
            entity_id,
            asset_id
        );

        const skus = whitelist.skus.map((s) => s.sku_id);

        yield put(setAssetOperatorPermittedLabels({ skus }));

        const preferences = yield call(
            api_getAssetOperatorPreferences,
            entity_id,
            asset_id
        );

        preferences.assets = preferences.assets.map((a) => a.asset_id);

        yield put(
            setAssetOperatorPreferences(!isEmpty(preferences) ? preferences : null)
        );

        if (action.callback) {
            action.callback({ preferences, skus });
        }
    } catch (error) {
        if (error.statusCode && error.statusCode === 404) return;
        errorFlash(error);
    }
}

export function* fetchAssetOperatorSettingsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSET_OPERATOR_SETTINGS,
        handleFetchAssetOperatorSettings
    );
}

function* handleUpdateAssetOperatorSettings(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { asset_id, skus, panels, alerts, preference_id, apply_to } =
      action.payload;

        const preferences = {
            panels,
            assets: [asset_id],
            alerts,
            apply_to,
        };

        const addOrEditPreference = preference_id
            ? api_updateEntityPreference
            : api_createEntityPreference;

        yield all([
            call(addOrEditPreference, preferences, preference_id),
            call(api_updateAssetOperatorSkus, entity_id, asset_id, { skus }),
        ]);

        yield all([
            put(setAssetOperatorPreferences({ ...preferences, preference_id })),
            put(setAssetOperatorPermittedLabels({ skus })),
        ]);

        if (action.callback) {
            action.callback({ preferences, skus });
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetOperatorSettingsSaga() {
    yield takeLatest(
        K.ACTIONS.SET_ASSET_OPERATOR_SETTINGS,
        handleUpdateAssetOperatorSettings
    );
}

function* handleFetchAssetsProduction(action) {
    try {
        const { entity_id, query } = action.payload;
        const response = yield call(api_getAssets, entity_id, query);

        if (action.callback) {
            action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchAssetsSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSETS_PRODUCTION_REQUEST,
        handleFetchAssetsProduction
    );
}

function* handleFetchAssetsSkusStandardTimes(action) {
    try {
        const response = yield api_getAssetsSkusStandardTimes(action.payload.entity_id);

        const standardTimesResource = getMapFromArr(
            response.sku_assets.map(
                (s) => new StandardTime(...parseStdTimeArguments(s))
            ),
            'uuid'
        );

        yield put(
            setAssetsState({
                standardTimes: standardTimesResource,
            })
        );

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* fetchAssetsSkusStandardTimesSaga() {
    yield takeLatest(
        K.ACTIONS.FETCH_ASSETS_SKUS_STANDARD_TIMES,
        handleFetchAssetsSkusStandardTimes
    );
}

function* handleUpdateAssetSkuStandardTime(action) {
    try {
        const { payload } = action;
        if (payload.data.std_time < 0) {
            throw {
                message: 'The standard time should be greater than or equal to 0',
            };
        }

        const params = Object.assign(
            {},
            payload.data.std_time !== undefined
                ? { std_time: payload.data.std_time }
                : undefined,
            payload.data.autoUpdate !== undefined
                ? { autoUpdate: payload.data.autoUpdate }
                : undefined
        );

        const response = yield api_updateAssetSkuStandardTime(
            payload.entity_id,
            payload.data.asset_id,
            payload.data.sku_id,
            params
        );

        const updated = new StandardTime(
            ...parseStdTimeArguments({
                ...response,
                autoUpdate: !!response.autoUpdate,
                asset_id: action.payload.data.asset_id,
            })
        );

        flash({
            message: `Update success`,
            status: 'success',
        });

        yield put(addAssetSkuStdTimeResource({ [updated.uuid]: updated }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateAssetSkuStandardTimeSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_ASSET_SKU_STANDARD_TIME,
        handleUpdateAssetSkuStandardTime
    );
}

function* handleCreateAssetSkuStandardTime(action) {
    try {
        const { payload } = action;
        if (payload.data.std_time && payload.data.std_time <= 0) {
            throw { message: 'The standard time should be greater than 0' };
        }

        const params = Object.assign(
            {},
            payload.data.std_time !== undefined
                ? { std_time: payload.data.std_time }
                : undefined,
            payload.data.autoUpdate !== undefined
                ? { autoUpdate: payload.data.autoUpdate }
                : undefined
        );

        const response = yield call(
            api_createAssetSkuStandardTime,
            payload.entity_id,
            payload.data.asset_id,
            payload.data.sku_id,
            params
        );
        const created = new StandardTime(...parseStdTimeArguments(response));

        flash({
            message: `Create success`,
            status: 'success',
        });

        yield put(addAssetSkuStdTimeResource({ [created.uuid]: created }));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createAssetSkuStandardTimeSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_ASSET_SKU_STANDARD_TIME,
        handleCreateAssetSkuStandardTime
    );
}

function* handleBulkCreateUpdateAssetSkuStandardTimes(
    action
) {
    try {
        const { entity_id, std_times } = action.payload;

        const response = yield call(
            api_bulkCreateUpdateAssetSkuStandardTimes,
            entity_id,
            std_times
        );

        const stdTimes = getMapFromArr(
            response.map((item) => new StandardTime(...parseStdTimeArguments(item))),
            'uuid'
        );

        yield put(addAssetSkuStdTimeResource(stdTimes));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* bulkCreateUpdateAssetSkuStandardTimesSaga() {
    yield takeLatest(
        K.ACTIONS.BULK_CREATE_UPDATE_ASSET_SKU_STANDARD_TIMES,
        handleBulkCreateUpdateAssetSkuStandardTimes
    );
}

// DELETE ASSETS
function* handleDeleteAsset(action) {
    try {
        const { asset_id, entity_id } = action.payload;

        const {
            assets: { assets },
        } = store.getState();

        const asset = assets[asset_id];
        yield put(RemoveWidgetsResource([`t_${asset.tile.tile_id}`]));
        const response = yield api_deleteAsset(entity_id, asset_id);
        yield put(cascadeDeleteAsset(asset));

        if (action.callback) {
            yield action.callback(response);
        }
    } catch (error) {
        errorFlash({
            details: error.message,
            message: error.error,
        });
    }
}

export function* deleteAssetSaga() {
    yield takeLatest(
        K.ACTIONS.DELETE_ASSET,
        handleDeleteAsset
    );
}

function* handleCascadeDeleteAsset(action) {
    try {
        const { asset } = action.payload;
        const { tile, block, metadata, fusions, devices, asset_id } = asset;

        yield all([
            put(DeleteTilesState([tile.tile_id])),
            put(removeAssetsResource([asset_id])),
            put(removeBlocksResource([block.block_id])),
            put(RemoveDevicesResource(devices.map(({ device_id }) => device_id))),
            put(RemoveMetadataResource(metadata.map(({ metadata_id }) => metadata_id))),
            put(RemoveFusionsResource(fusions.map(({ fusion_id }) => fusion_id))),
        ]);
    } catch (e) {
        errorFlash({
            details: e.message,
            message: e.error,
        });
    }
}

export function* watchCascadeDeleteAssetSaga() {
    yield takeLatest(
        K.ACTIONS.CASCADE_DELETE_ASSET,
        handleCascadeDeleteAsset
    );
}
