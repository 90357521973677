import { makeRequest } from '../Shared/Shared.action';
import { store } from '../..';
import { User } from '../../../legacy/models';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { queryString } from '../../../legacy/utils/service';

// GENERATE KEYS VALUE USER
export function generateListUser(data) {
    let listUsers = {};
    const responseLength = data.length;

    for (let i = 0; i < responseLength; i++) {
        const user_id = data[i].user_id;
        listUsers[Number(user_id)] = new User(
            data[i].user_id,
            data[i].email,
            data[i].name_first,
            data[i].name_last,
            data[i].mobile,
            data[i].country_code,
            data[i].role_name,
            data[i].appointment,
            data[i].entity_id,
            data[i].confirmed,
            data[i].user_img,
            data[i].user_img_id,
            data[i].preference
        );
    }

    return listUsers;
}

export const api_getUser = (user_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

// Get All Users
export const api_getAllUsers = (entity_id, query= {}) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/user${queryString({
                    ...query,
                })}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateUserEmail = (user_id, data) => {
    return new Promise((resolve, reject) => {
        makeRequest({
            path: `v1/user/${user_id}/email`,
            method: 'POST',
            params: JSON.stringify(data),
            success: resolve,
            error: reject,
        });
    });
};

// Change user Role
export const api_changeUserRole = (entity_id, user) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/user/${user.user_id}/role/${user.role_name}`,
                method: 'PUT',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateUser = (user_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Change user Role
export const api_deleteUser = (entity_id, user) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/user/${user.user_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_userDeleteSelf = () => {
    const { user_id } = store.getState().auth.user;
    return new Promise<any>(
        (resolve, reject) => {
            makeRequest({
                path: `v1/user/${user_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateUserImage = (user_id, data) => {
    const { entity_id } = currentEntitySelector(store.getState());
    return new Promise<any>(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/user/${user_id}/image`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
