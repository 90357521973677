import { store } from '../../store';

export const parseStdTimeArguments = (a) => {
    return [+a.sku_asset_id, +a.asset_id, +a.sku_id, a.autoUpdate, a.std_time];
};

export class StandardTime {
    sku_asset_id;
    asset_id;
    sku_id;
    autoUpdate;
    std_time;

    constructor(
        sku_asset_id,
        asset_id,
        sku_id,
        autoUpdate,
        std_time
    ) {
        this.sku_asset_id = sku_asset_id;
        this.asset_id = asset_id;
        this.sku_id = sku_id;
        this.autoUpdate = autoUpdate;
        this.std_time = std_time || 0;
    }

    get uuid() {
        return `${this.sku_id}-${this.asset_id}`;
    }

    get asset() {
        const {
            assets: { assets: assetsState },
        } = store.getState();

        return assetsState[this.asset_id];
    }

    get sku() {
        const {
            sku: { skus: skusState },
        } = store.getState();

        return skusState[this.sku_id];
    }
}
