import React, {
    useMemo,
    useReducer,
    useState,
} from 'react';
import { Layout } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import {
    InfoCircleOutlined,
    PlusOutlined,
} from '@ant-design/icons';
import AssetConfiguration from './AssetConfiguration';
import AukButton from '../../components/AukButton';
import BlocksTree from '../Blocks/BlocksTree';
import BlockForm from '../Blocks/BlockForm';
import AssetForm from './AssetForm';
import { NoData } from '../../components/None';
import { PanelBody, PanelHeader } from '../../components/Panel';
import { SPAWrapper } from '../../components/SPAWrapper';
import { assetsSelector } from '../../../store/old/Assets/Assets.selector';
import { entityBlock } from '../../../store/old/Blocks/Blocks.selector';
import { createAsset } from '../../../store/old/Assets/Assets.action';
import {
    createBlock,
    deleteBlock,
    updateBlocks as updateBlocksAction,
} from '../../../store/old/Blocks/Blocks.action';
import { cloneBlockTree } from '../../utils/blocks';

import './AssetManagement.scss';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import { devicesState } from '../../../store/old/Devices/Devices.selector';
import { fusionsState } from '../../../store/old/Fusions/Fusions.selector';
import { metadataState } from '../../../store/old/Metadata/Metadata.selector';

const { Sider, Content } = Layout;

const AssetManagement = () => {
    const dispatch = useDispatch();
    const { entity_id } = useSelector(currentEntitySelector)
    const [resetCounter, reset] = useReducer((x) => x + 1, 0);

    const rootBlock = useSelector(entityBlock);
    const blocksResource = useSelector((appState) => appState.blocks.blocks);
    const assetsResource = useSelector(assetsSelector);
    const devicesResource = useSelector(devicesState);
    const fusionsResource = useSelector(fusionsState);
    const metadataResource = useSelector(metadataState);

    const treeData = useMemo(
        () => cloneBlockTree(rootBlock),
        [rootBlock, blocksResource, assetsResource]
    );

    const [clickedSelection, setClickedSelection] = useState(null);
    const [draggedSelection, setDraggedSelection] = useState([]);

    const [showCreateBlock, setShowCreateBlock] = useState(false);
    const [showCreateAsset, setShowCreateAsset] = useState(false);

    const selectedIsAsset = useMemo(
        () => clickedSelection && clickedSelection.asset_id,
        [clickedSelection]
    );

    const selectedIsBlock = useMemo(
        () => clickedSelection && !clickedSelection.asset_id,
        [clickedSelection]
    );

    const updateBlocks = (arr, callback) => dispatch(updateBlocksAction(entity_id, arr, callback));
    const resetClickedSelection = () => setClickedSelection(null);

    const updateBlock = (formData) => {
        const { child_weightages } = formData;
        const b = { ...formData, child_weightages: undefined };
        updateBlocks([b].concat(child_weightages), () => {
            resetClickedSelection();
        });
    };

    const cancelForms = () => {
        setShowCreateBlock(false);
        setShowCreateAsset(false);
        resetClickedSelection();
    };

    const handleClickCreateBlock = () => {
        setShowCreateBlock(true);
        setShowCreateAsset(false);
        resetClickedSelection();
    };

    const handleSelectBlock = (b) => {
        setShowCreateBlock(false);
        setShowCreateAsset(false);
        setClickedSelection(b);
    };

    const AssetCreateForm = useMemo(() => {
        return (
            <AssetForm
                submit={(d) => dispatch(createAsset(entity_id, d, () => setShowCreateAsset(false)))}
                showCancel
                cancel={cancelForms}
            />
        );
    });

    const AssetUpdateForm = useMemo(() => {
        if (!clickedSelection) return null;
        return (
            <AssetConfiguration
                key={clickedSelection.asset_id}
                asset={assetsResource[clickedSelection.asset_id || -1] || {}}
                cancel={cancelForms}
            />
        );
    }, [clickedSelection, assetsResource, devicesResource, fusionsResource, metadataResource]);

    const BlockCreateForm = useMemo(() => {
        return (
            <div className="d-flex w-100 h-100 flex-column">
                <PanelHeader>Add New Block</PanelHeader>
                <PanelBody style={{ flexGrow: 1 }}>
                    <BlockForm
                        submit={(d) => dispatch(createBlock(entity_id, d, () => setShowCreateBlock(false)))}
                        isInit={true}
                        showCancel
                        cancel={cancelForms}
                    />
                </PanelBody>
            </div>
        );
    }, []);

    const BlockUpdateForm = useMemo(() => {
        if (!clickedSelection) return null;
        const block = blocksResource[clickedSelection.block_id || -1];
        const key = block ? `${block.block_id}${block.children.length}` : '';
        return (
            <BlockForm
                key={key}
                block={block}
                submit={updateBlock}
                onDelete={(b) => {
                    resetClickedSelection();
                    dispatch(deleteBlock(entity_id, b));
                }}
                isInit={false}
            />
        );
    }, [clickedSelection, blocksResource, assetsResource]);

    const NoSelection = useMemo(
        () => <NoData description="Please select a line/machine" />,
        []
    );

    return (
        <SPAWrapper className="asset-management">
            <Layout className="d-flex w-100 h-100">
                <Sider className="d-flex flex-column h-100" width="35%" theme="light">
                    <div
                        className="d-flex flex-column w-100 h-100 p-2"
                        style={{ background: '#fff' }}
                    >
                        <div className="d-flex justify-content-between my-2">
                            <AukButton.Blue
                                ghost
                                className="w-100 mx-2"
                                onClick={handleClickCreateBlock}
                            >
                                <PlusOutlined className="mr-2" />
                Add Block
                            </AukButton.Blue>
                            {/* <AukButton.Blue
                ghost
                className="w-50 mx-2"
                onClick={handleClickCreateAsset}
              >
                <PlusOutlined className="mr-2" />
                Add Asset
              </AukButton.Blue> */}
                        </div>
                        <code className="m-2 p-2">
                            <InfoCircleOutlined className="mr-2" />
              Click to select/un-select, drag to reorder
                        </code>
                        {draggedSelection.length ? (
                            <div
                                className="w-100 my-3"
                                style={{ color: 'red', textAlign: 'center' }}
                            >
                                <i>--- You have unsaved changes ---</i>
                            </div>
                        ) : null}
                        <div
                            className="d-flex mb-2"
                            style={{ overflow: 'auto', flexGrow: 1 }}
                        >
                            <BlocksTree
                                key={resetCounter}
                                draggable
                                selectable
                                data={treeData}
                                selected={clickedSelection ? clickedSelection.block_id : -1}
                                onDrop={setDraggedSelection}
                                onSelect={handleSelectBlock}
                            />
                        </div>

                        <div className="d-flex justify-content-between">
                            <AukButton.Reset
                                disabled={!draggedSelection.length}
                                onClick={reset}
                            />
                            <AukButton.Save
                                disabled={!draggedSelection.length}
                                onClick={() =>
                                    updateBlocks(draggedSelection, () => setDraggedSelection([]))
                                }
                            />
                        </div>
                    </div>
                </Sider>
                <Content className="pl-3" style={{ position: 'relative' }}>
                    <div
                        className="d-flex w-100 h-100 justify-content-center align-items-center p-2 pt-3"
                        style={{
                            border: '1px solid #ddd',
                            borderRadius: 2,
                        }}
                    >
                        {clickedSelection
                            ? selectedIsAsset
                                ? AssetUpdateForm
                                : selectedIsBlock
                                    ? BlockUpdateForm
                                    : NoSelection
                            : showCreateBlock
                                ? BlockCreateForm
                                : showCreateAsset
                                    ? AssetCreateForm
                                    : NoSelection}
                    </div>
                </Content>
            </Layout>
        </SPAWrapper>
    );
};

export default AssetManagement;
