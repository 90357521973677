import { makeRequest } from '../Shared/Shared.action';

// GENERATE KEYS VALUE SUMMARY TILES
export function generateListSummaryTiles(data) {
    let listSummaries = {};
    const responseLength = data.length;

    for (let i = 0; i < responseLength; i++) {
        const summary_tile_id = data[i].summary_tile_id;

        listSummaries[Number(summary_tile_id)] = new SummaryTile(
            data[i].position_x,
            data[i].position_y,
            data[i].block_id,
            data[i].label,
            data[i].summary_tile_id,
            data[i].type,
            data[i].entity_id
        );
    }

    return listSummaries;
}

export const api_createSummaryTile = (entity_id, payload) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/summary_tile`,
                params: JSON.stringify(payload),
                method: 'POST',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_editSummaryTile = (entity_id, summary_tile_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/summary_tile/${summary_tile_id}`,
                params: JSON.stringify(data),
                method: 'PUT',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_deleteSummaryTile = (entity_id, summary_tile_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/summary_tile/${summary_tile_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_editTile = (entity_id, tile_id, data) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/tile/${tile_id}`,
                method: 'PUT',
                params: JSON.stringify(data),
                success: resolve,
                error: reject,
            });
        }
    );
};
