import { FusionConstants as K } from './Fusions.constants';
import { Fusion } from '../../../legacy/models';

export const ResetFusionsState = () => {
    return {
        type: K.ACTIONS.RESET_FUSIONS_STATE,
    };
};

export const SetFusionsResource = (payload) => {
    return {
        type: K.ACTIONS.SET_FUSIONS_RESOURCE,
        payload,
    };
};

export const RemoveFusionsResource = (fusion_ids) => {
    return {
        type: K.ACTIONS.REMOVE_FUSIONS_RESOURCE,
        payload: { fusion_ids },
    };
};

export const CreateFusion = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_FUSION_REQUEST,
        payload: { asset_id, data },
        callback,
    };
};

export const UpdateFusion = (asset_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_FUSION_REQUEST,
        payload: { asset_id, data },
        callback,
    };
};

export const DeleteFusion = (payload, callback
) => {
    return {
        type: K.ACTIONS.DELETE_FUSION_REQUEST,
        payload,
        callback,
    };
};

export const AddFusionsResource = (payload) => {
    return {
        type: K.ACTIONS.ADD_FUSIONS_RESOURCE,
        payload,
    };
};
