import React from 'react';

// COMPONENTS
import AlertsTab from './alerts';
import SkusTab from './skus';
import PanelsTab from './panel';

// STYLE
import './index.scss';

export default class PreferencePanes extends React.Component<any> {
    constructor(props) {
        super(props);
        this.state = {
            activeItem: {},
        };
    }

    componentDidMount() {
        this.setState({ activeItem: this.TabsData[0] });
    }

    handleChangeActiveItem(item) {
        this.setState({ activeItem: item });
    }

    get TabsData() {
        return [
            {
                id: 0,
                title: 'Panels',
                component: () => (
                    <PanelsTab
                        asset={this.props.asset}
                        dateRange={this.props.dateRange}
                        handleChangePanelDisplay={this.props.handleChangePanelDisplay}
                        panels={this.props.panels}
                        controls={this.props.controls}
                    />
                ),
            },
            {
                id: 1,
                title: 'SKUs',
                component: () => (
                    <SkusTab
                        checked={this.props.skus}
                        handleCheck={(e) => this.props.handleChangeWhitelist('skus', e)}
                    />
                ),
            },
            {
                id: 2,
                title: 'Alerts',
                component: () => (
                    <AlertsTab
                        data={this.props.alerts}
                        handleAdd={this.props.handleAddAlert}
                        handleChange={this.props.handleChangeAlert}
                        handleDelete={this.props.handleDeleteAlert}
                    />
                ),
            },
        ];
    }

    render() {
        const { activeItem } = this.state;

        if (Object.keys(activeItem).length === 0) return null;

        return (
            <div className="tabs-wrapper">
                <div className="tabs-header">
                    {this.TabsData.map((v) => {
                        return (
                            <div
                                key={v.id}
                                className={`tabs-header-item ${
                                    v.id === activeItem.id ? 'active' : ''
                                }`}
                                onClick={() => this.handleChangeActiveItem(v)}
                            >
                                {v.title}
                            </div>
                        );
                    })}
                </div>

                <div className="tabs-content">{activeItem.component()}</div>
            </div>
        );
    }
}
