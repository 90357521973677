/* eslint-disable react/prop-types */
import React from 'react';

import './Tag.scss';

const Tag = (props) => (
    <div className="tag" style={props.style}>
        {props.children}
        {
            props.handleRemove && 
        (
            <span className="remove-tag" onClick={props.handleRemove}><i className="fas fa-minus"></i></span>
        )
        }
    </div>
)

export default Tag;