import { BlocksConstants as K } from './Blocks.constants';

export const setBlocksState = (payload) => {
    return {
        type: K.ACTIONS.SET_BLOCKS_STATE,
        payload,
    };
};

export const resetBlocksState = () => {
    return {
        type: K.ACTIONS.RESET_BLOCKS_STATE,
    };
};

export const addBlocksResource = (blocks) => {
    return {
        type: K.ACTIONS.ADD_BLOCKS_RESOURCE,
        payload: { blocks },
    };
};

export const removeBlocksResource = (block_ids) => {
    return {
        type: K.ACTIONS.REMOVE_BLOCKS_RESOURCE,
        payload: { block_ids },
    };
};

// export const fetchBlocks = (entity_id, callback) => {
//     return {
//         type: K.ACTIONS.GET_BLOCKS_REQUEST,
//         payload: { entity_id },
//         callback,
//     };
// };

export const createBlock = (entity_id, block, callback) => {
    return {
        type: K.ACTIONS.CREATE_BLOCK_REQUEST,
        payload: { block, entity_id },
        callback,
    };
};

export const updateBlocks = (entity_id, blocks, callback) => {
    return {
        type: K.ACTIONS.UPDATE_BLOCKS_REQUEST,
        payload: { blocks, entity_id },
        callback,
    };
};

// export const UpdateBlock = (block, callback) => {
//     return {
//         type: K.ACTIONS.UPDATE_BLOCK_REQUEST,
//         payload: { block },
//         callback,
//     };
// };

export const deleteBlock = (entity_id, block, callback) => {
    return {
        type: K.ACTIONS.DELETE_BLOCK_REQUEST,
        payload: { block, entity_id },
        callback,
    };
};

export const setBlockBottleneck = (blocks_bn, callback) => {
    return {
        type: K.ACTIONS.SET_BLOCK_BOTTLENECK,
        payload: { blocks_bn },
        callback,
    };
};

export const addBlocksIssuesResource = (blocks_issues_exclusions) => {
    return {
        type: K.ACTIONS.ADD_BLOCKS_ISSUES_RESOURCE,
        payload: { blocks_issues_exclusions },
    };
};

export const fetchBlocksIssues = (entity_id, callback) => {
    return {
        type: K.ACTIONS.GET_BLOCKS_ISSUES_REQUEST,
        payload: { entity_id },
        callback,
    };
};

export const fetchBlockIssues = (entity_id, block_id, callback) => {
    return {
        type: K.ACTIONS.GET_BLOCK_ISSUES_REQUEST,
        payload: { entity_id, block_id },
        callback,
    };
};

export const updateBlocksIssues = (entity_id, blocks_issues_exclusions, callback) => {
    return {
        type: K.ACTIONS.UPDATE_BLOCKS_ISSUES_REQUEST,
        payload: { blocks_issues_exclusions, entity_id },
        callback,
    };
};

export const updateBlockUsers = (entity_id, block_id, create, remove, callback) => {
    return {
        type: K.ACTIONS.UPDATE_BLOCK_USERS_REQUEST,
        payload: { entity_id, block_id, create, remove },
        callback,
    };
};