import { all, call, put, takeLatest } from 'redux-saga/effects';
import _ from 'lodash';

import { DeviceConstants as K } from './Devices.constants';
import { store } from '../..';

// DEVICE SERVICES
import {
    api_previewSerialData,
    api_updateDevice,
    api_createDevice,
    api_updateDeviceEntity,
    api_initializeDeviceAsRelay,
    api_linkDevice,
    api_unlinkDevice,
    api_resetDevice,
} from './Devices.services';

// DEVICE ACTION
import {
    AddDevicesResource,
    CascadeUnlinkDevice,
    RemoveDevicesResource,
} from './Devices.action';

// MODEL
import {
    Asset,
    Block,
    Device,
    Metadatum,
    parseAssetArguments,
    parseBlockArguments,
    parseDeviceArguments,
    parseMetadatumArguments,
    parseTileArguments,
    Tile,
    tileToWidget,
} from '../../../legacy/models';

// METADATUM ACTION
import {
    AddMetadataResource,
    RemoveMetadataResource,
    SetMetadataResource,
} from '../Metadata/Metadata.action';
import { getMapFromArr } from '../../../legacy/utils/helpers';
import { addAssetsResource } from '../Assets/Assets.action';
import { errorFlash, flash } from '../../../legacy/components/Flash';
import { addBlocksResource } from '../Blocks/Blocks.action';
import { AddTilesState } from '../Tiles/Tiles.action';
import { currentEntitySelector } from '../Entity/Entity.selector';
import { AddWidgetsResource } from '../Widgets/Widget.action';

// PREVIEW DATA
function* handlePreviewData(data) {
    if (data.payload.data && Object.keys(data.payload.data).length === 0) {
        errorFlash({
            message:
        'Ensure that Serial Device is configured to "Print" mode and press the Print Button on Serial Device',
        });
    }

    try {
        const response = yield api_previewSerialData(data.payload);

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* previewData() {
    yield takeLatest(
        K.ACTIONS.PREVIEW_SERIAL_COMMUNICATIONS_DATA,
        handlePreviewData
    );
}

// UPDATE DEVICE ENTITY
function* handleUpdateDeviceEntity(action) {
    try {
        const { mac_address } = action.payload;
        const { entity_id } = currentEntitySelector(store.getState());

        const result = yield api_updateDeviceEntity(entity_id, mac_address);
        const device = new Device(...parseDeviceArguments(result));

        yield put(AddDevicesResource({ [device.device_id]: device }));

        if (action.callback) {
            yield action.callback(device);
        }

        return device;
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateDeviceEntitySaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_DEVICE_ENTITY_REQUEST,
        handleUpdateDeviceEntity
    );
}

// INITIALIZE AS RELAY
function* handleInitializeDeviceAsRelay(action) {
    try {
        const { mac_address, gateway_id } = action.payload;

        const response = yield* handleUpdateDeviceEntity({
            payload: { mac_address },
        });

        if (!response) throw { message: 'Invalid mac address' };

        const _relay = yield api_initializeDeviceAsRelay(
            response.device_id,
            gateway_id
        );

        const relay = new Device(...parseDeviceArguments(_relay));
        yield put(AddDevicesResource({ [relay.device_id]: relay }));

        flash({ message: 'Relay initialized', status: 'success' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* initializeDeviceAsRelaySaga() {
    yield takeLatest(
        K.ACTIONS.INITIALIZE_RELAY_REQUEST,
        handleInitializeDeviceAsRelay
    );
}

// REMOVE RELAY
function* handleRemoveRelay(action) {
    try {
        const { device_id } = action.payload;

        yield call(api_resetDevice, device_id);

        flash({ message: 'Relay removed', status: 'success' });

        yield put(RemoveDevicesResource([device_id]));

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* removeRelaySaga() {
    yield takeLatest(
        K.ACTIONS.REMOVE_RELAY_REQUEST,
        handleRemoveRelay
    );
}

// UPDATE DEVICE
function* handleUpdateDevice(data) {
    try {
        const appState = store.getState();
        const originalDevice = appState.devices.devices[data.payload.device_id];

        const clonedMetadataState = { ...appState.metadata.metadata };

        // remove old metadata (in case of deleted channels)
        originalDevice.metadata.forEach(
            ({ metadata_id }) => delete clonedMetadataState[metadata_id]
        );

        const response = yield api_updateDevice(data.payload);
        const device = new Device(...parseDeviceArguments(response.device));

        delete response.metadata.device_id;
        const metadata = getMapFromArr(
            response.device.metadata.map((m) => {
                m.device_id = device.device_id;
                return new Metadatum(...parseMetadatumArguments(m));
            }),
            'metadata_id'
        );

        yield all([
            put(AddDevicesResource({ [device.device_id]: device })),
            put(SetMetadataResource({ ...clonedMetadataState, ...metadata })),
        ]);

        flash({
            message: 'Device successfully updated',
            status: 'success',
        });

        if (data.callback) {
            yield data.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* updateDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.UPDATE_DEVICE_REQUEST,
        handleUpdateDevice
    );
}

// CREATE DEVICE
function* handleCreateDevice(data) {
    try {
        const response = yield call(api_createDevice, data.payload);

        response.asset.block_id = response.block[0].block_id;
        response.asset.asset_img = { image_id: response.asset.asset_img_id };

        const asset = new Asset(...parseAssetArguments(response.asset));
        const device = new Device(...parseDeviceArguments(response.device));
        const block = new Block(...parseBlockArguments(response.block[0]));
        const tile = new Tile(...parseTileArguments(response.tile));

        const metadata = getMapFromArr(
            response.device.metadata.map((m) => {
                m.device_id = device.device_id;
                return new Metadatum(...parseMetadatumArguments(m));
            }),
            'metadata_id'
        );

        const widget = tileToWidget(response.tile);

        yield all([
            put(AddTilesState(tile)),
            put(addAssetsResource({ [asset.asset_id]: asset })),
            put(AddDevicesResource({ [device.device_id]: device })),
            put(AddMetadataResource(metadata)),
            put(addBlocksResource({ [block.block_id]: block })),
            put(AddWidgetsResource({ [widget.widget_id]: widget })),
        ]);

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* createDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.CREATE_DEVICE_REQUEST,
        handleCreateDevice
    );
}

// LINK DEVICE
function* handleLinkDevice(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { asset_id, device_id } = action.payload;

        const response = yield call(api_linkDevice, entity_id, asset_id, device_id);
        const device = new Device(...parseDeviceArguments(response));

        yield put(AddDevicesResource({ [device.device_id]: device }));
        flash({ message: 'Device linked', status: 'success' });

        if (action.callback) {
            yield action.callback(device);
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* LinkDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.LINK_DEVICE_REQUEST,
        handleLinkDevice
    );
}

// UNLINK DEVICE
function* handleUnlinkDevice(action) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const { asset_id, device_id } = action.payload;

        yield call(
            api_unlinkDevice,
            entity_id,
            asset_id,
            device_id
        );

        const { devices } = store.getState().devices;
        const device = devices[device_id];
        yield put(CascadeUnlinkDevice(device));
        flash({ message: 'Device unlinked', status: 'success' });

        if (action.callback) {
            yield action.callback();
        }
    } catch (error) {
        errorFlash(error);
    }
}

export function* UnlinkDeviceRequestSaga() {
    yield takeLatest(
        K.ACTIONS.UNLINK_DEVICE_REQUEST,
        handleUnlinkDevice
    );
}

function* handleUnlinkDeviceCascade(action) {
    try {
        const { device } = action.payload;

        const metadata_ids = device.metadata.map((m) => m.metadata_id);

        yield all([
            put(RemoveDevicesResource([device.device_id])),
            put(RemoveMetadataResource(metadata_ids)),
        ]);
    } catch (error) {
        errorFlash(error);
    }
}

export function* UnlinkDeviceCascadeSaga() {
    yield takeLatest(
        K.ACTIONS.CASCADE_UNLINK_DEVICE,
        handleUnlinkDeviceCascade
    );
}
