/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

// LIBRARIES
import React, { forwardRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Form, Input, Select } from 'antd';


// COMPONENTS
import Box, { BoxContent } from '../../components/Box';
import WithBarcode from '../../components/WithBarcode';

// REDUX
import { AddGateway } from '../../../store/old/Gateway/Gateway.action';
import { arrayGateways } from '../../../store/old/Gateway/Gateway.selector';
import { currentEntitySelector } from '../../../store/old/Entity/Entity.selector';
import {
    InitializeDeviceAsRelay,
    UpdateDeviceEntity,
} from '../../../store/old/Devices/Devices.action';

// UTILS
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';

import { ArrowRightOutlined } from '@ant-design/icons';

import './DeviceScan.scss';

const DeviceScan = forwardRef(
    (props, ref) => {
        const dispatch = useDispatch();
        const { entity_id } = useSelector(currentEntitySelector);
        const gateways = useSelector(arrayGateways).map((gw) => ({
            label: gw.serial_number,
            value: gw.device_id,
        }));

        const {
            hideGateway,
            hideOPCUA,
            hideRelay,
            hideNode,
            onScan,
            options = {},
        } = props;

        const [active, setActive] = useState(props.active || K.TYPES.NODE);
        const [gatewayId, setGatewayId] = useState(
            options.relay
                ? options.relay.gatewayId
                : gateways.length
                    ? +gateways[0].value
                    : -1
        );
        const [dmac, setDmac] = useState('');

        const addNode = (mac_address) => {
            dispatch(
                UpdateDeviceEntity(
                    mac_address,
                    (result) => onScan && onScan({ type: active, device: result })
                )
            );
        };

        const addRelay = (mac_address) => {
            dispatch(
                InitializeDeviceAsRelay(
                    mac_address,
                    gatewayId,
                    (result) => onScan && onScan({ type: active, device: result })
                )
            );
        };

        const addGateway = (mac_address) => {
            // serial number
            dispatch(
                AddGateway(
                    entity_id,
                    mac_address,
                    (result) => onScan && onScan({ type: active, device: result })
                )
            );
        };

        const submit = (mac_address) => {
            if (active === K.TYPES.NODE) return addNode(mac_address);
            if (active === K.TYPES.RELAY) return addRelay(mac_address);
            if (active === K.TYPES.GATEWAY) return addGateway(mac_address);
        };

        return (
            <div className="device-scan">
                <div className="device-scan__icons p-5">
                    {!hideNode && (
                        <Box onClick={() => setActive(K.TYPES.NODE)}>
                            <BoxContent
                                text="Node"
                                active={active === K.TYPES.NODE}
                                icon={<i className="fas fa-microchip fa-2x" />}
                            />
                        </Box>
                    )}
                    {!hideRelay && (
                        <Box onClick={() => setActive(K.TYPES.RELAY)}>
                            <BoxContent
                                text="Relay"
                                active={active === K.TYPES.RELAY}
                                icon={<i className="fas fa-exchange-alt fa-2x" />}
                            />
                        </Box>
                    )}
                    {!hideOPCUA && (
                        <Box onClick={() => setActive(K.TYPES.OPCUA)}>
                            <BoxContent
                                text="OPC UA"
                                active={active === K.TYPES.OPCUA}
                                icon={<i className="fas fa-hdd fa-2x" />}
                            />
                        </Box>
                    )}
                    {!hideGateway && (
                        <Box onClick={() => setActive(K.TYPES.GATEWAY)}>
                            <BoxContent
                                text="Gateway"
                                active={active === K.TYPES.GATEWAY}
                                icon={<i className="fas fa-server fa-2x" />}
                            />
                        </Box>
                    )}
                </div>
                {active === K.TYPES.RELAY && (
                    <Form.Item
                        label="Gateway"
                        labelCol={{ span: 6 }}
                        wrapperCol={{ span: 18 }}
                    >
                        <Select
                            options={gateways}
                            value={gatewayId}
                            onChange={setGatewayId}
                            style={{ width: 'calc(100% - 41px)' }}
                            disabled={
                                options.relay
                                    ? options.relay.disableGatewaySelection
                                    : gateways.length === 1
                            }
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label="Serial Number"
                    labelCol={{ span: 6 }}
                    wrapperCol={{ span: 18 }}
                >
                    <WithBarcode handleScan={submit}>
                        <Input
                            value={dmac}
                            onChange={(e) => setDmac(e.target.value)}
                            addonAfter={
                                <span
                                    className="d-flex align-items-center px-2"
                                    onClick={() => submit(dmac)}
                                    style={{ cursor: 'pointer' }}
                                >
                                    <ArrowRightOutlined />
                                </span>
                            }
                            maxLength={50}
                        />
                    </WithBarcode>
                </Form.Item>
            </div>
        );
    }
);

export default DeviceScan;
