import { updateStoreRedux } from '../../../legacy/utils/redux';

// CONSTANT
import { InvitationConstants as K } from './Invitation.constant';

const initialReducer = {
    list: {},
    error: {},
};

export const invitationsReducer = (
    state = initialReducer,
    action
) => {
    if (action.type === K.ACTIONS.FETCH_INVITATIONS_SUCCESS) {
        return updateStoreRedux(state, {
            list: action.payload.list,
        });
    }

    if (
        action.type === K.ACTIONS.FETCH_INVITATIONS_FAILURE ||
    action.type === K.ACTIONS.CREATE_INVITATION_FAILURE ||
    action.type === K.ACTIONS.UPDATE_INVITATION_FAILURE ||
    action.type === K.ACTIONS.REVOKE_INVITATION_FAILURE
    ) {
        return updateStoreRedux(state, {
            error: action.payload.error,
        });
    }

    return updateStoreRedux(state, {});
};
