/* eslint-disable react/prop-types */
// LIBRARIES
import moment from 'moment';
import React from 'react';
import { connect } from 'react-redux';
import { DatePicker, Input, Modal, Select, TreeSelect } from 'antd';
import { isEqual, keys } from 'lodash';

// COMPONENTS
import LabelWrapper from '../LabelWrapper';

// SELECTORS
import { arrayUsers } from '../../../store/old/User/User.selector';
import {
    mapsAssetsByPolicy,
    arrayAssets,
} from '../../../store/old/Assets/Assets.selector';

// HELPERS
import { regexMatch } from '../../utils/helpers';
import { containsSuspiciousCharacters } from '../../utils/validate';
import { generatePermittedIssuesTreeData } from '../../routes/IssueManagement/IssuesTreeData';
import { permittedIssuesByBlocks } from '../../utils/issues';
import translate from '../../utils/translate';

// FLASH
import { flash } from '../Flash';

import './TrackedIssueEditor.scss';

const FIELDS = {
    PRIORITY: 'priority',
    TITLE: 'title',
    ISSUE_ID: 'issue_id',
    ASSIGNEE_ID: 'assignee_id',
    DESCRIPTION: 'description',
    ASSETS: 'assets',
    TARGET_DATE: 'target_date',
    ROOT_CAUSE: 'root_cause',
};

const isValid = (field, value) => {
    switch (field) {
    case FIELDS.PRIORITY:
        return !!value;
    case FIELDS.TITLE:
        return !!value.trim() && !containsSuspiciousCharacters(value);
    case FIELDS.ISSUE_ID:
        return !!value;
    case FIELDS.ASSIGNEE_ID:
        return !!value;
    case FIELDS.DESCRIPTION:
        return !!value.trim() && !containsSuspiciousCharacters(value);
    case FIELDS.ROOT_CAUSE:
        return !value ? true : !containsSuspiciousCharacters(value);
    case FIELDS.ASSETS:
        return !!value.length;
    case FIELDS.TARGET_DATE:
        return !!value;
    default:
        return true;
    }
};

const FORM_ERRORS = {
    priority: 'Priority is required',
    title: 'Title is required / contains invalid characters',
    issue_id: 'Issue is required',
    assignee_id: 'Assignee is required',
    description: 'Description is required / contains invalid characters',
    assets: 'Asset(s) required',
    target_date: 'Target resolve date is required',
    root_cause: 'Root cause contains invalid characters',
};

const deriveStateFromProps = (data = {}) => {
    const {
        tracked_issue_id,
        status,
        priority,
        assets,
        title,
        issue_id,
        assignee_id,
        description,
        target_date,
        root_cause,
    } = data;

    return {
        tracked_issue_id: tracked_issue_id,
        status: +status || 0,
        priority: priority || '',
        assets: assets ? assets.map((a) => a.asset_id) : [],
        title: title || '',
        issue_id: issue_id || null,
        assignee_id: assignee_id || null,
        description: description || '',
        target_date: target_date || null,
        root_cause: root_cause || '',
    };
};

export class TrackedIssueEditor extends React.Component {
    constructor(props) {
        super(props);

        this.state = { ...deriveStateFromProps(props.data) };

        this.handleChangeMultiSelect = this.handleChangeMultiSelect.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        if (!isEqual(prevProps.data, this.props.data)) {
            return this.setState({ ...deriveStateFromProps(this.props.data) });
        }

        if (prevState.assets !== this.state.assets) {
            if (!this.whitelistedIssues.has(this.state.issue_id)) {
                return this.setState({ issue_id: null });
            }
        }
    }

    get formErrors() {
        return keys(FIELDS).reduce((acc, curr) => {
            const field = FIELDS[curr];
            !isValid(FIELDS[curr], this.state[FIELDS[curr]]) &&
        acc.push(FORM_ERRORS[FIELDS[curr]]);
            return acc;
        }, []);
    }

    get validForm() {
        const { formErrors } = this;

        formErrors.length &&
      flash({
          message: 'Invalid form',
          details: (
              <ul>
                  {formErrors.map((e, i) => (
                      <li key={i}>{e}</li>
                  ))}
              </ul>
          ),
          status: 'error',
      });

        return !formErrors.length;
    }

    handleSubmit() {
        if (!this.validForm) return;

        this.props.save(this.state);
    }

    handleChangeSelect(field, e) {
        this.setState({ [field]: e });
    }

    handleInputChange(field, e) {
        this.setState({ [field]: e.target.value });
    }

    handleChangeMultiSelect(e) {
        this.setState({ assets: e });
    }

    handleChangeDateTimeSelect(e) {
        this.setState({ target_date: e ? e.endOf('day').toISOString() : null });
    }

    get issueTreeData() {
        const { issues, blocks } = this.props;
        const { assetBlockIds } = this;

        return generatePermittedIssuesTreeData(
            issues,
            assetBlockIds,
            blocks.blocks_issues_exclusions
        );
    }

    get assetBlockIds() {
        return this.state.assets
            .map((asset_id) => this.props.assets[asset_id])
            .filter((a) => a)
            .map((a) => a.block_id);
    }

    get whitelistedIssues() {
        const {
            blocks: { blocks_issues_exclusions },
            issues: { issues },
        } = this.props;

        const { assetBlockIds } = this;

        return new Set(
            permittedIssuesByBlocks(issues, assetBlockIds, blocks_issues_exclusions)
        );
    }

    get priorityField() {
        const options = [
            { value: 'low', label: translate('low') },
            { value: 'mid', label: translate('mid') },
            { value: 'high', label: translate('high') },
        ];

        const { priority } = this.state;
        return (
            <LabelWrapper label={translate('priority')}>
                <Select
                    className={isValid(FIELDS.PRIORITY, priority) ? '' : 'is-invalid'}
                    showSearch
                    style={{ width: '100%' }}
                    options={options}
                    value={priority}
                    onSelect={(e) => this.handleChangeSelect('priority', e)}
                    filterOption={(input, option) =>
                        regexMatch(option.value, input, { escape: true })
                    }
                />
            </LabelWrapper>
        );
    }

    get titleField() {
        const { title } = this.state;
        return (
            <LabelWrapper label={translate('title')}>
                <Input
                    className={isValid(FIELDS.TITLE, title) ? '' : 'is-invalid'}
                    value={title}
                    onChange={(e) => this.handleInputChange('title', e)}
                    maxLength={80}
                />
            </LabelWrapper>
        );
    }

    get issuesField() {
        const { issue_id } = this.state;
        return (
            <LabelWrapper label={translate('issue')}>
                <TreeSelect
                    className={isValid(FIELDS.ISSUE_ID, issue_id) ? '' : 'is-invalid'}
                    showSearch
                    value={issue_id}
                    style={{ width: '100%' }}
                    dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                    treeData={this.issueTreeData}
                    treeDefaultExpandAll
                    onChange={(e) => this.handleChangeSelect('issue_id', e)}
                    filterOption={(input, option) =>
                        regexMatch(option.title, input, { escape: true })
                    }
                />
            </LabelWrapper>
        );
    }

    get assetsField() {
        const options = this.props.arrayAssets.map((a) => ({
            value: a.asset_id,
            label: a.asset_name,
        }));

        const { assets } = this.state;

        return (
            <LabelWrapper label={translate('asset')}>
                <Select
                    className={isValid(FIELDS.ASSETS, assets) ? '' : 'is-invalid'}
                    showSearch
                    mode="multiple"
                    style={{ width: '100%' }}
                    options={options}
                    value={assets}
                    allowClear
                    onChange={(e) => this.handleChangeMultiSelect(e)}
                    filterOption={(input, option) =>
                        regexMatch(option.label, input, { escape: true })
                    }
                />
            </LabelWrapper>
        );
    }

    get assigneeField() {
        const { isClientUser, isWhitelistedDemoUser } = this.props.authUser;
        const options = this.props.users.map((user) => ({
            value: user.user_id,
            label: user.nameFull,
        }));

        const { assignee_id } = this.state;
        return (
            <LabelWrapper label={translate('assignee')}>
                <Select
                    className={
                        isValid(FIELDS.ASSIGNEE_ID, assignee_id) ? '' : 'is-invalid'
                    }
                    showSearch
                    style={{ width: '100%' }}
                    options={isClientUser || isWhitelistedDemoUser ? options : []}
                    value={assignee_id}
                    onSelect={(e) => this.handleChangeSelect('assignee_id', e)}
                    filterOption={(input, option) =>
                        regexMatch(option.label, input, { escape: true })
                    }
                />
            </LabelWrapper>
        );
    }

    get descriptionField() {
        const { description } = this.state;

        return (
            <LabelWrapper label={translate('description')}>
                <Input
                    className={
                        isValid(FIELDS.DESCRIPTION, description) ? '' : 'is-invalid'
                    }
                    value={description}
                    onChange={(e) => this.handleInputChange('description', e)}
                    maxLength={150}
                />
            </LabelWrapper>
        );
    }

    get targetField() {
        const { target_date } = this.state;
        return (
            <LabelWrapper label={translate('targetresolvedate')}>
                <DatePicker
                    className={
                        isValid(FIELDS.TARGET_DATE, target_date) ? '' : 'is-invalid'
                    }
                    defaultValue={target_date ? moment(target_date) : target_date}
                    onChange={(e) => this.handleChangeDateTimeSelect(e)}
                />
            </LabelWrapper>
        );
    }

    get rootCauseField() {
        const { root_cause } = this.state;
        return (
            <LabelWrapper label={translate(["root", "cause"])}>
                <Input
                    className={
                        isValid(FIELDS.ROOT_CAUSE, root_cause) ? '' : 'is-invalid'
                    }
                    value={root_cause}
                    onChange={(e) => this.handleInputChange('root_cause', e)}
                    maxLength={150}
                />
            </LabelWrapper>
        );
    }

    get isEdit() {
        return this.state.tracked_issue_id;
    }

    get body() {
        return (
            <>
                {this.priorityField}
                {this.titleField}
                {this.assetsField}
                {this.issuesField}
                {this.assigneeField}
                {this.descriptionField}
                {this.rootCauseField}
                {this.targetField}
            </>
        );
    }

    render() {
        const title = this.isEdit ? 'Update Ticket' : 'Open Ticket';

        return (
            <Modal
                className="auk-modal--small"
                style={{ width: '60vw' }}
                title={title}
                visible={this.props.show}
                onOk={this.handleSubmit}
                onCancel={this.props.toggle}
            >

                {this.body}
            </Modal>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        assets: mapsAssetsByPolicy(appState),
        arrayAssets: arrayAssets(appState),
        users: arrayUsers(appState),
        issues: appState.issues,
        blocks: appState.blocks,
        authUser: appState.auth.user,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(TrackedIssueEditor);

TrackedIssueEditor.defaultProps = {
    data: {},
};
