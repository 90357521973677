import { AssetsConstants as K } from './Assets.constants';

export const resetAssetsState = () => {
    return {
        type: K.ACTIONS.RESET_ASSETS_STATE,
    };
};

export const setAssetsState = (
    payload
) => {
    return {
        type: K.ACTIONS.SET_ASSETS_STATE,
        payload,
    };
};

export const addAssetsResource = (assets) => {
    return {
        type: K.ACTIONS.ADD_ASSETS_RESOURCE,
        payload: { assets },
    };
};

export const fetchAssetsProduction = (entity_id, query, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSETS_PRODUCTION_REQUEST,
        payload: { entity_id, query },
        callback,
    };
};

export const updateAssetStdTime = (entity_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_STANDARD_TIME,
        payload: { entity_id, data },
        callback,
    };
};

export const createAsset = (entity_id, asset, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET,
        payload: { entity_id, asset },
        callback,
    };
};

export const updateAsset = (entity_id, asset, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET,
        payload: { entity_id, asset },
        callback,
    };
};

// export const deleteAssetPure = (
//     assets,
//     callback
// ) => {
//     return {
//         type: K.ACTIONS.DELETE_ASSET_PURE,
//         payload: { assets },
//         callback,
//     };
// };

export const fetchAssetOperatorSettings = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSET_OPERATOR_SETTINGS,
        payload: { entity_id, asset_id },
        callback,
    };
};

export const setAssetOperatorPermittedLabels = (payload) => {
    return {
        type: K.ACTIONS.SET_ASSET_OPERATOR_PERMITTED_LABELS,
        payload,
    };
};

export const setAssetOperatorSettings = (payload, callback) => {
    return {
        type: K.ACTIONS.SET_ASSET_OPERATOR_SETTINGS,
        payload,
        callback,
    };
};

export const fetchAssetsSkusStdTimes = (entity_id, callback) => {
    return {
        type: K.ACTIONS.FETCH_ASSETS_SKUS_STANDARD_TIMES,
        payload: { entity_id },
        callback,
    };
};

export const addAssetSkuStdTimeResource = (standardTimes) => {
    return {
        type: K.ACTIONS.ADD_ASSETS_SKUS_STANDARD_TIMES_RESOURCE,
        payload: { standardTimes },
    };
};

export const updateAssetSkuStdTime = (entity_id, data, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_SKU_STANDARD_TIME,
        payload: { entity_id, data },
        callback,
    };
};

export const createAssetSkuStdTime = (entity_id, data, callback) => {
    return {
        type: K.ACTIONS.CREATE_ASSET_SKU_STANDARD_TIME,
        payload: { entity_id, data },
        callback,
    };
};

export const bulkCreateUpdateAssetSkuStdTimes = (entity_id, std_times, callback) => {
    return {
        type: K.ACTIONS.BULK_CREATE_UPDATE_ASSET_SKU_STANDARD_TIMES,
        payload: { entity_id, std_times },
        callback,
    };
};

export const deleteAsset = (entity_id, asset_id, callback) => {
    return {
        type: K.ACTIONS.DELETE_ASSET,
        payload: { entity_id, asset_id },
        callback,
    };
};

export const removeAssetsResource = (asset_ids) => {
    return {
        type: K.ACTIONS.REMOVE_ASSETS_RESOURCE,
        payload: { asset_ids },
    };
};

export const cascadeDeleteAsset = (asset) => {
    return {
        type: K.ACTIONS.CASCADE_DELETE_ASSET,
        payload: { asset },
    };
};

export const updateAssetChartOrders = (entity_id, asset_id, chart_orders, callback) => {
    return {
        type: K.ACTIONS.UPDATE_ASSET_CHART_ORDERS,
        payload: { entity_id, asset_id, chart_orders },
        callback,
    };
};
