/* eslint-disable react/prop-types */
import React, { useMemo, useState } from 'react';
import ReactMarkdown from 'react-markdown';

import './Banner.scss';
import { Modal } from 'antd';
import { CloseOutlined } from '@ant-design/icons';
import { localizeTime } from '../utils/helpers';

const Banner = ({ display, data, withModal = false }) => {
    const [modalVisible, setModalVisibility] = useState(false);
    const [visible, setVisibility] = useState(true);

    const bannerContent = useMemo(
        () => data && data.banner && localizeTime(data.banner),
        [data]
    );
    const modalContent = useMemo(
        () => data && data.content && localizeTime(data.content),
        [data]
    );

    return visible && display && data ? (
        <>
            <div
                className={`banner ${withModal ? 'banner--with-modal' : ''}`}
                onClick={() => withModal && setModalVisibility(true)}
                style={{ background: data.color }}
            >
                {bannerContent}
                <div className="banner-close">
                    <CloseOutlined
                        onClick={(e) => {
                            e.stopPropagation();
                            setVisibility(false);
                        }}
                    />
                </div>
            </div>
            {withModal && (
                <Modal
                    className="auk-modal--small"
                    style={{ width: '60vw' }}
                    title={data.title}
                    visible={modalVisible}
                    onOk={() => setModalVisibility(false)}
                    onCancel={() => setModalVisibility(false)}
                >
                    <ReactMarkdown className="markdown-body">
                        {modalContent}
                    </ReactMarkdown>
                </Modal>
            )}
        </>
    ) : null;
};

export default Banner;
