/* eslint-disable react/prop-types */
// LIBRARIES
import React from 'react';
import moment from 'moment';
import { connect } from 'react-redux';
import { Select, Tabs, DatePicker, Button } from 'antd';

// COMPONENTS
import DualFieldInputRow from '../../components/DualFieldInputRow';
import { Permission } from '../../components/Permission';

// HELPERS
import { uiDatetimeFormat } from '../../utils/helpers';
import { generateContinuousDomain } from '../../utils/charts';
import { REGRESSIONS } from '../../utils/regression';
import { flash } from '../../components/Flash';

// CONSTANTS
import CONSTANTS from '../../Constants';
import { RegressionConstants as K } from '../../../store/old/UI/Regression/RegressionUI.constants';

// SELECTORS
import { arrayAssetsWithoutUsingBlock } from '../../../store/old/Assets/Assets.selector';
import { regressionState } from '../../../store/old/UI/Regression/RegressionUI.selector';

// ACTIONS
import {
    changeRegressionBound,
    changeRegressionForm,
    changeRegressionModel,
    fetchRegressionDataRequest,
    resetRegressionStore,
} from '../../../store/old/UI/Regression/RegressionUI.action';
import { _isRangeResolutionAllowed } from '../../utils/controls';
import translate from '../../utils/translate';

const { RangePicker } = DatePicker;

const RANGE_TABS = [
    {
        id: K.RANGE_TYPES.HISTORICAL,
        key: 'historical',
        name: translate('historical'),
    },
    {
        id: K.RANGE_TYPES.RECENT,
        key: 'mostrecent',
        name: translate('mostrecent'),
    },
];

const generateSourceOptions = (asset) => {
    return asset
        ? asset.metadata.map((m) => ({
            value: m.metadata_id,
            label: m.chart_title,
        }))
        : [];
};

const RESOLUTIONS = CONSTANTS.RESOLUTIONS.map((r) => ({
    value: `${r.id}`,
    label: `${r.res_x} ${r.res_period}`,
    res_x: r.res_x,
    res_period: r.res_period,
}));

const PERIODS = CONSTANTS.PERIODS.map((p) => ({
    value: `${p.id}`,
    label: `Last ${p.size} ${p.unit}`,
    res_x: p.size,
    res_period: p.unit,
}));

const getAllowableResolutions = (durationSeconds) => {
    // temp - to set as generic helper function
    if (durationSeconds <= 0) return [];

    return RESOLUTIONS.filter((res) => {
        return _isRangeResolutionAllowed(
            durationSeconds,
            res.res_x,
            res.res_period
        );
    });
};

const initStateFromProps = (props) => {
    const {
        regressionStore: { sourceX, sourceY, startDate, endDate, resolution },
    } = props;

    return {
        assetX: sourceX && sourceX.ofAsset.asset_id,
        assetY: sourceY && sourceY.ofAsset.asset_id,
        sourceX: sourceX && sourceX.metadata_id,
        sourceY: sourceY && sourceY.metadata_id,
        startDate: startDate,
        endDate: endDate,
        resolution: resolution && resolution.value,
        rangeType: '0',
        period: null,
    };
};

class RegressionControls extends React.Component {
    constructor(props) {
        super(props);

        this.state = initStateFromProps(props);

        this.handleChange = this.handleChange.bind(this);
        this.handleChangeDate = this.handleChangeDate.bind(this);
        this.handleClear = this.handleClear.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { regressionStore: prevStore } = prevProps;
        const { regressionStore: currStore } = this.props;

        if (
            prevStore.selection !== currStore.selection &&
      currStore.selection !== -1
        ) {
            // set sources in callback to avoid sources being set to null
            return this.setState(
                {
                    assetX: currStore.sourceX.ofAsset.asset_id,
                    assetY: currStore.sourceY.ofAsset.asset_id,
                },
                () =>
                    this.setState({
                        sourceX: currStore.sourceX.metadata_id,
                        sourceY: currStore.sourceY.metadata_id,
                    })
            );
        }

        if (
            prevState.rangeType !== this.state.rangeType ||
      prevState.startDate !== this.state.startDate ||
      prevState.endDate !== this.state.endDate ||
      prevState.period !== this.state.period
        ) {
            return this.autoupdateResolution();
        }

        if (prevState.assetX !== this.state.assetX) {
            return this.setState({ sourceX: null });
        }

        if (prevState.assetY !== this.state.assetY) {
            return this.setState({ sourceY: null });
        }
    }

    autoupdateResolution() {
        const { resolution, resOptions } = this;

        if (
            !resolution ||
      (resolution && !resOptions.find((r) => r.value === resolution))
        ) {
            const result = resOptions[Math.floor(resOptions.length / 2)];
            this.setState({
                resolution: result ? result.value : null,
            });
        }
    }

    handleChange(field, val) {
        this.setState({ [field]: val });
    }

    handleChangeDate([startDate, endDate]) {
        this.setState({ startDate, endDate });
    }

    handleClear() {
        this.setState(
            {
                assetX: null,
                assetY: null,
                sourceX: null,
                sourceY: null,
                startDate: null,
                endDate: null,
                resolution: null,
                rangeType: '0',
                period: null,
            },
            this.props.handleClear
        );
    }

    get formData() {
        const [startDate, endDate] = this.dateRange;

        return {
            sourceX: this.sourceX,
            sourceY: this.sourceY,
            resolution: this.resolution,
            startDate,
            endDate,
        };
    }

    get duration() {
        const { rangeType, startDate, endDate } = this.state;

        switch (rangeType) {
        case K.RANGE_TYPES.HISTORICAL:
            return startDate && endDate
                ? moment.duration(endDate.diff(startDate)).as('seconds')
                : 0;
        default:
            return this.period
                ? moment
                    .duration(this.period.res_x, this.period.res_period)
                    .as('seconds')
                : 0;
        }
    }

    get optionsX() {
        return generateSourceOptions(this.assetX);
    }

    get optionsY() {
        return generateSourceOptions(this.assetY);
    }

    get sourceSelectors() {
        const {
            arrayAssets,
        } = this.props;

        const assetOptions = arrayAssets.map((a) => ({
            value: a.asset_id,
            label: a.asset_name,
        }));

        const sources = [
            {
                name: "Data Series #1 (x-axis)",
                asset: 'assetX',
                source: 'sourceX',
                sources: 'optionsX',
            },
            {
                name: "Data Series #2 (y-axis)",
                asset: 'assetY',
                source: 'sourceY',
                sources: 'optionsY',
            },
        ];

        return (
            <div className="d-flex flex-column">
                <div className="mb-1">
                    {sources.map((s, i) => (
                        <div className="mb-2" key={i}>
                            <div className="mb-1">{s.name}</div>
                            <div className="d-flex flex-column">
                                <Select
                                    className="mb-1"
                                    placeholder={translate('asset')}
                                    options={assetOptions}
                                    value={this.state[s.asset]}
                                    onChange={(e, val) => this.handleChange(s.asset, e)}
                                />
                                <Select
                                    className="mb-1"
                                    placeholder={translate('chart')}
                                    options={this[s.sources]}
                                    value={this.state[s.source]}
                                    onChange={(e, val) => this.handleChange(s.source, e)}
                                />
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        );
    }

    get formErrors() {
        const { resolution, sourceX, sourceY } = this.state;
        const [startDate, endDate] = this.dateRange;

        const errors = [];
        !resolution && errors.push('Please enter resolution');
        !startDate && errors.push('Please select start date / period');
        !endDate && errors.push('Please select end date / period');
        !sourceX && errors.push('Please select a source (x-axis)');
        !sourceY && errors.push('Please select a source (y-axis)');
        return errors.length ? errors : null;
    }

    flashFormErrors(errors) {
        flash({
            status: 'error',
            message: 'Error loading regression chart',
            details: (
                <ol>
                    {errors.map((e, i) => (
                        <li key={i}>{e}</li>
                    ))}
                </ol>
            ),
        });
    }

    get resOptions() {
        return getAllowableResolutions(this.duration);
    }

    get datetimeSelectors() {
        const resolution = (
            <Select
                placeholder={translate('resolution')}
                options={this.resOptions}
                value={this.state.resolution}
                onChange={(e) => this.handleChange('resolution', e)}
            />
        );

        const tabpanes = [
            {
                tab: RANGE_TABS[0].name,
                key: RANGE_TABS[0].id,
                content: (
                    <div className="d-flex flex-column">
                        <RangePicker
                            format={uiDatetimeFormat}
                            showTime={{ format: 'HH:mm' }}
                            className="mb-1"
                            value={[this.state.startDate, this.state.endDate]}
                            onChange={(e) => this.handleChangeDate(e)}
                        />
                        {resolution}
                    </div>
                ),
            },
            {
                tab: RANGE_TABS[1].name,
                key: RANGE_TABS[1].id,
                content: (
                    <div className="d-flex flex-column">
                        <Select
                            className="mb-1"
                            placeholder={translate('period')}
                            options={PERIODS}
                            value={this.state.period}
                            onChange={(e) => this.handleChange('period', e)}
                        />
                        {resolution}
                    </div>
                ),
            },
        ];

        return (
            <div>
                <Tabs
                    defaultActiveKey={this.state.rangeType}
                    size="small"
                    className="mb-2"
                    onChange={(e) => this.handleChange('rangeType', e)}
                >
                    {tabpanes.map((t) => {
                        return (
                            <Tabs.TabPane tab={t.tab} key={t.key}>
                                {t.content}
                            </Tabs.TabPane>
                        );
                    })}
                </Tabs>
            </div>
        );
    }

    get optionsRegression() {
        const { data } = this.props.regressionStore;

        const [y1, y2] = generateContinuousDomain(data, (d) => d.y);
        const [x1, x2] = generateContinuousDomain(data, (d) => d.x);

        const fields = [
            {
                placeholder: translate('trendline'),
                options: REGRESSIONS,
                value:
          this.props.regressionStore.regression &&
          this.props.regressionStore.regression.value,
                handleChange: (e, val) => {
                    this.props.handleChangeRegression(val.d);
                },
            },
        ];

        const yStep = (y2 - y1) / 20 || '';
        const xStep = (x2 - x1) / 20 || '';

        return (
            <div className="d-flex flex-column pt-1 mt-3">
                <h6>{translate('regression')}</h6>
                {fields.map((field, i) => (
                    <Select
                        key={i}
                        placeholder={field.placeholder}
                        options={field.options}
                        value={field.value}
                        onChange={(e, val) => field.handleChange(e, val)}
                    />
                ))}
                {
                    <>
                        <div className="mt-2">
                            {translate(['trendline', 'upperbound'])}
                        </div>
                        <AxisTranslateInput
                            rows={[
                                {
                                    label: 'dX',
                                    input: {
                                        type: 'number',
                                        onChange: (e) =>
                                            this.props.handleChangeBound('upperBound', {
                                                dx: +e.target.value,
                                            }),
                                        value: `${this.props.regressionStore.upperBound.dx}`,
                                        step: xStep,
                                    },
                                },
                                {
                                    label: 'dY',
                                    input: {
                                        type: 'number',
                                        onChange: (e) =>
                                            this.props.handleChangeBound('upperBound', {
                                                dy: +e.target.value,
                                            }),
                                        value: `${this.props.regressionStore.upperBound.dy}`,
                                        step: yStep,
                                    },
                                },
                            ]}
                        />
                        <div>
                            {translate(['trendline', 'lowerbound'])}
                        </div>
                        <AxisTranslateInput
                            rows={[
                                {
                                    label: 'dX',
                                    input: {
                                        type: 'number',
                                        onChange: (e) =>
                                            this.props.handleChangeBound('lowerBound', {
                                                dx: +e.target.value,
                                            }),
                                        value: `${this.props.regressionStore.lowerBound.dx}`,
                                        step: xStep,
                                    },
                                },
                                {
                                    label: 'dY',
                                    input: {
                                        type: 'number',
                                        onChange: (e) =>
                                            this.props.handleChangeBound('lowerBound', {
                                                dy: +e.target.value,
                                            }),
                                        value: `${this.props.regressionStore.lowerBound.dy}`,
                                        step: yStep,
                                    },
                                },
                            ]}
                        />
                    </>
                }
            </div>
        );
    }

    get clearBtn() {
        return (
            <Button style={{ width: '45%' }} onClick={this.handleClear}>
                <i className="fas fa-power-off mr-1" />
                {translate('clear')}
            </Button>
        );
    }

    get previewBtn() {
        return (
            <Button
                type="primary"
                style={{ width: '45%' }}
                onClick={() => {
                    const { formErrors } = this;
                    if (formErrors) return this.flashFormErrors(formErrors);
                    this.props.updateForm(this.formData, this.props.handleFetchData);
                }}
            >
                <i className="fas fa-angle-double-right mr-1" />
                {translate('preview')}
            </Button>
        );
    }

    get saveBtn() {
        return (
            <Permission forResource resource="regression_analysis" canDo="edit">
                <Button
                    className="w-100 my-1"
                    type="primary"
                    onClick={() => {
                        const { formErrors } = this;
                        if (formErrors) return this.flashFormErrors(formErrors);
                        this.props.updateForm(this.formData, this.props.handleClickSave);
                    }}
                >
                    <i className="fas fa-save mr-1" />
                    {translate('save')}
                </Button>
            </Permission>
        );
    }

    get notificationBtn() {
        return (
            <Permission forResource resource="regression_analysis" canDo="edit">
                <Button
                    className="w-100 mb-1"
                    danger
                    disabled={this.props.regressionStore.selection === -1}
                    onClick={() => {
                        this.props.handleClickAlert();
                    }}
                >
                    <i className="fas fa-bell mr-1" />
                    {translate('alert')}
                </Button>
            </Permission>
        );
    }

    render() {
        const { data } = this.props.regressionStore;

        return (
            <div className="d-flex flex-column p-3 regression--controls">
                <div>
                    {this.sourceSelectors}
                    {this.datetimeSelectors}
                    <div className="d-flex justify-content-between">
                        {this.clearBtn}
                        {this.previewBtn}
                    </div>
                    <div
                        className={`regression--options ${
                            !(data && data.length) ? 'disabled' : ''
                        }`}
                    >
                        {this.optionsRegression}
                        {this.saveBtn}
                    </div>
                    {this.notificationBtn}
                </div>
            </div>
        );
    }

    get resolution() {
        return RESOLUTIONS.find((r) => r.value === this.state.resolution);
    }

    get period() {
        return PERIODS.find((p) => p.value === this.state.period);
    }

    get assetX() {
        return this.props.assets[this.state.assetX];
    }

    get assetY() {
        return this.props.assets[this.state.assetY];
    }

    get sourceX() {
        return this.props.metadata[this.state.sourceX];
    }

    get sourceY() {
        return this.props.metadata[this.state.sourceY];
    }

    get dateRange() {
        const { rangeType, startDate, endDate } = this.state;
        const { period } = this;

        switch (rangeType) {
        case K.RANGE_TYPES.HISTORICAL:
            return [startDate, endDate];
        default:
            return [
                period ? moment().subtract(period.res_x, period.res_period) : null,
                period ? moment() : null,
            ];
        }
    }
}

const AxisTranslateInput = (props) => {
    return (
        <DualFieldInputRow
            className="axis-translate"
            rows={props.rows}
            labelCol={2}
            labelClassed={'axis-translate--input'}
        />
    );
};

const mapStateToProps = (appState) => {
    return {
        arrayAssets: arrayAssetsWithoutUsingBlock(appState),
        assets: appState.assets.assets,
        regressionStore: regressionState(appState),
        correlations: appState.correlations.correlations,
        metadata: appState.metadata.metadata,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        handleFetchData: () => dispatch(fetchRegressionDataRequest()),
        updateForm: (data, callback) =>
            dispatch(changeRegressionForm(data, callback)),
        handleClear: () => dispatch(resetRegressionStore()),
        handleChangeRegression: (regression) =>
            dispatch(changeRegressionModel(regression)),
        handleChangeBound: (bound, value) =>
            dispatch(changeRegressionBound(bound, value)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegressionControls);
