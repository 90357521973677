import { AuthConstants as K } from './Authentication.constants';

// LOGIN FLOW
export const Authenticate = (
    payload,
    callback
) => {
    return {
        type: K.ACTIONS.LOGIN_REQUEST,
        username: payload.username,
        password: payload.password,
        callback,
    };
};

export const AuthenticateSuccess = (user) => {
    return {
        type: K.ACTIONS.LOGIN_SUCCESS,
        user,
    };
};

export const AuthenticateFailure = (error) => {
    return {
        type: K.ACTIONS.LOGIN_FAILURE,
        error,
    };
};

// LOGOUT FLOW
export const LogOut = (callback) => {
    return {
        type: K.ACTIONS.LOGOUT_REQUEST,
        callback,
    };
};

export const LogOutSuccess = () => {
    return {
        type: K.ACTIONS.LOGOUT_SUCCESS,
    };
};

export const LogOutFailure = (error) => {
    return {
        type: K.ACTIONS.LOGOUT_FAILURE,
        error,
    };
};

// FETCH USER INFORMATION FLOW
export const FetchUser = (callback) => {
    return {
        type: K.ACTIONS.FETCH_USER_INFORMATION_REQUEST,
        callback,
    };
};

export const FetchUserSuccess = (user) => {
    return {
        type: K.ACTIONS.FETCH_USER_INFORMATION_SUCCESS,
        user,
    };
};

export const FetchUserFailure = (error) => {
    return {
        type: K.ACTIONS.FETCH_USER_INFORMATION_FAILURE,
        error,
    };
};

// UPDATE INTERNAL USER INFORMATION
export const UpdateUserInformation = (user) => {
    return {
        type: K.ACTIONS.UPDATE_USER_INFORMATION,
        user,
    };
};

// CHANGE PASSWORD FLOW
export const ChangePassword = (
    payload,
    callback
) => {
    return {
        type: K.ACTIONS.CHANGE_PASSWORD_REQUEST,
        user_id: payload.user_id,
        data: payload.data,
        callback,
    };
};

export const ChangePasswordSuccess = () => {
    return {
        type: K.ACTIONS.CHANGE_PASSWORD_SUCCESS,
    };
};

export const ChangePasswordFailure = (error) => {
    return {
        type: K.ACTIONS.CHANGE_PASSWORD_FAILURE,
        error,
    };
};

// RESET PASSWORD FLOW
export const ResetPassword = (
    payload,
    callback
) => {
    return {
        type: K.ACTIONS.RESET_PASSWORD_REQUEST,
        email: payload.email,
        callback,
    };
};

export const ResetPasswordSuccess = () => {
    return {
        type: K.ACTIONS.RESET_PASSWORD_SUCCESS,
    };
};

export const ResetPasswordFailure = (error) => {
    return {
        type: K.ACTIONS.RESET_PASSWORD_FAILURE,
        error,
    };
};

// VERIFY EMAIL FLOW
export const VerifyEmail = (
    payload,
    callback
) => {
    return {
        type: K.ACTIONS.VERIFY_EMAIL_REQUEST,
        user_id: payload.user_id,
        email: payload.email,
        token: payload.token,
        callback,
    };
};

export const VerifyEmailSuccess = () => {
    return {
        type: K.ACTIONS.VERIFY_EMAIL_SUCCESS,
    };
};

export const VerifyEmailFailure = (error) => {
    return {
        type: K.ACTIONS.VERIFY_EMAIL_FAILURE,
        error,
    };
};

// VERIFY EMAIL FLOW
export const ResetPasswordWithCode = (payload, callback) => {
    return {
        type: K.ACTIONS.RESET_PASSWORD_WITH_CODE_REQUEST,
        payload: {
            email: payload.email,
            reset_code: payload.reset_code,
            password: payload.password,
            confirm_password: payload.confirm_password
        },
        callback
    };
};

export const ResetPasswordWithCodeSuccess =
  () => {
      return {
          type: K.ACTIONS.RESET_PASSWORD_WITH_CODE_SUCCESS,
      };
  };

export const ResetPasswordWithCodeFailure = (
    error
) => {
    return {
        type: K.ACTIONS.RESET_PASSWORD_WITH_CODE_FAILURE,
        error,
    };
};

// SIGNUP FLOW
export const SignUp = (
    payload,
    callback
) => {
    return {
        type: K.ACTIONS.SIGNUP_REQUEST,
        payload,
        callback: callback,
    };
};

export const SignUpSuccess = () => {
    return {
        type: K.ACTIONS.SIGNUP_SUCCESS,
    };
};

export const SignUpFailure = (error) => {
    return {
        type: K.ACTIONS.SIGNUP_FAILURE,
        error,
    };
};

// CHANGE USER POLICY
export const ChangeUserPolicy = (user) => {
    return {
        type: K.ACTIONS.CHANGE_USER_POLICY,
        user,
    };
};

export const UpdateAuthenticatedUser = (
    user_id,
    user,
    callback
) => {
    return {
        type: K.ACTIONS.UPDATE_USER_REQUEST,
        payload: { user_id, user },
        callback,
    };
};
