import { UserConstants as K } from './User.constants';

export const getAllUsersRequest = (query, callback) => {
    return {
        type: K.ACTIONS.FETCH_USERS_SYSTEM_REQUEST,
        payload: { query },
        callback,
    };
};

export const getAllUsersSuccess = (users) => {
    return {
        type: K.ACTIONS.FETCH_USERS_SYSTEM_SUCCESS,
        payload: {
            list: users,
        },
    };
};

export const getAllUsersFailure = (error) => {
    return {
        type: K.ACTIONS.FETCH_USERS_SYSTEM_FAILURE,
        payload: {
            error,
        },
    };
};

export const changeUserRoleRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.CHANGE_USER_ROLE_REQUEST,
        payload,
        callback,
    };
};

export const changeUserRoleFailure = (error) => {
    return {
        type: K.ACTIONS.CHANGE_USER_ROLE_FAILURE,
        payload: {
            error,
        },
    };
};

export const deleteUserRequest = (payload, callback) => {
    return {
        type: K.ACTIONS.DELETE_USER_REQUEST,
        payload,
        callback,
    };
};

export const deleteUserFailure = (error) => {
    return {
        type: K.ACTIONS.DELETE_USER_FAILURE,
        payload: {
            error,
        },
    };
};
