/* eslint-disable react/display-name */
/* eslint-disable react/prop-types */

import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { SortableComponent } from '../../../components/SortableComponent';

const getItemLabel = (item, index) => {
    const original_title = item.chart_title.replace('<PRIMARY> ', '');
    item.chart_title = index ? original_title : `<PRIMARY> ${original_title}`;
    return item;
};

const SortableCharts = forwardRef((props, ref) => {
    const [charts, setCharts] = useState(
        props.charts.map((c, i) => ({
            asset_id: c.asset_id,
            chart_order: c.chart_order,
            metadata_id: c.metadata_id,
            fusion_id: c.fusion_id,
            chart_title: i ? c.chart_title : `<PRIMARY> ${c.chart_title}`,
        }))
    );

    useImperativeHandle(ref, () => ({
        getData() {
            return charts.map((c) => {
                const clone = { ...c };
                delete clone.chart_title;
                return clone;
            });
        },
    }));

    return (
        <SortableComponent
            items={charts}
            valueAccessor={(item) => item.chart_title}
            onSortEnd={({ result }) => {
                setCharts(
                    result.map((c, index) => {
                        return getItemLabel({ ...c, chart_order: index + 1 }, index);
                    })
                );
            }}
        />
    );
});

export default SortableCharts;
