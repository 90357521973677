import moment from 'moment';
import { store } from '../../store';

export const parseGatewayArguments = (g) => [
    g.device_id,
    g.serial_number,
    g.entity_id,
    g.mac_address,
];

export class Gateway {
    constructor(
        device_id,
        serial_number,
        entity_id,
        mac_address
    ) {
        this.device_id = device_id;
        this.serial_number = serial_number;
        this.entity_id = entity_id;
        this.mac_address = mac_address;
    }

    get gateway_id() {
        return this.device_id;
    }

    get entity() {
        const {
            entity: { entities },
        } = store.getState();

        if (Object.keys(entities).length === 0) return null;

        return entities[this.entity_id];
    }

    get status() {
        const {
            gateway: { statuses },
        } = store.getState();

        return statuses[this.device_id];
    }

    get active() {
        if (!this.status) return false;

        let { last_seen, last_fetched, connected } = this.status;

        if (connected !== undefined) return connected;

        last_seen = moment(last_seen);
        return moment.duration(last_fetched.diff(last_seen)).as('seconds') <= 900;
    }
}
