/* eslint-disable react/prop-types */
import React, { Component, createRef } from 'react';
import Toggle from 'react-toggle';
import { connect } from 'react-redux';
import { Input, Modal } from 'antd';

// COMPONENT
import AukTooltip from '../../../components/AukTooltip';
import {
    updateAssetSkuStdTime,
    createAssetSkuStdTime,
} from '../../../../store/old/Assets/Assets.action';

import { updateAssetStdTime } from '../../../../store/old/Assets/Assets.action';

// MODEL
import { Asset, Sku } from '../../../models';

import { InfoCircleOutlined } from '@ant-design/icons';
import { stdTimeMaxLength } from '..';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import translate from '../../../utils/translate';

class ModalStandardTime extends Component {
    inputRef = createRef();

    state = {
        showModalStandardtime: false,
        currentStandardTime: {
            sku: new Sku(null, -1, ''),
            asset: new Asset(null, -1, ''),
            autoUpdate: false,
            std_time: 0,
        },
        asset: new Asset(null, -1, ''),
        type: '',
        action: '',
        input: 0,
    };

    changeStateModalStandardTime(
        type,
        standardTime,
        asset,
        action
    ) {
        if (standardTime) {
            return this.setState({
                type,
                showModalStandardtime: true,
                currentStandardTime: standardTime,
                action,
                input: standardTime.std_time,
            });
        }

        if (asset) {
            return this.setState({
                type,
                showModalStandardtime: true,
                asset,
                action,
                input: asset.stdTime,
            });
        }
    }

    handleChangeInput = (e) => {
        if (
            e.target.value === '' ||
      (isFinite(e.target.value) && +e.target.value >= 0)
        ) {
            this.setState({ input: e.target.value });
        }
    };

    handleSubmitStandardTime = () => {
        const { currentStandardTime, action, input } = this.state;
        const { entity_id } = this.props.entity;

        if (action === 'update-asset-sku') {
            const bodyRequest = {
                sku_id: currentStandardTime.sku.sku_id,
                asset_id: currentStandardTime.asset.asset_id,
                autoUpdate: currentStandardTime.autoUpdate,
                std_time: +input,
            };
            this.props.updateAssetSkuStandardTime(entity_id, bodyRequest, () => {
                this.setState({ showModalStandardtime: false, type: '' });
            });
        }

        if (action === 'create-asset-sku') {
            const bodyRequest = {
                sku_id: currentStandardTime.sku.sku_id,
                asset_id: currentStandardTime.asset.asset_id,
                autoUpdate: currentStandardTime.autoUpdate,
                std_time: +input,
            };
            this.props.createAssetSkuStandardTime(entity_id, bodyRequest, () => {
                this.setState({ showModalStandardtime: false, type: '' });
            });
        }

        if (action === 'update-asset') {
            const { asset } = this.state;
            const newAsset = new Asset(
                null,
                asset.asset_id,
                asset.asset_name,
                null,
                null,
                -1,
                null,
                [],
                asset.autoUpdate,
                asset.speed,
                +input
            );

            return this.props.updateAssetStandardTime(entity_id, newAsset, () => {
                this.setState({ showModalStandardtime: false, type: '' });
            });
        }
    };

    handleChangeStatusStandardTime = (status) => {
        const { currentStandardTime } = this.state;
        const newObject = Object.assign({}, currentStandardTime);
        newObject.autoUpdate = status;
        this.setState({ currentStandardTime: newObject });
    };

    handleChangeStatusAsset = (type, status) => {
        const { asset } = this.state;
        const newAsset = new Asset(
            null,
            asset.asset_id,
            asset.asset_name,
            null,
            null,
            -1,
            null,
            [],
            asset.autoUpdate,
            asset.speed,
            asset.stdTime
        );

        if (type === 'speed') {
            newAsset.speed = status;
        }

        if (type === 'cycle_time') {
            newAsset.autoUpdate = status;
        }

        this.setState({ asset: newAsset });
    };

    renderForAssetSku = () => {
        const { currentStandardTime, action, input } = this.state;

        return (
            <>
                <div className="row oee-table-lable-row">
                    <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                        <>
                            <p style={{ textTransform: 'capitalize' }}>
                                {translate('autocycletime')}
                            </p>
                    (s)
                        </>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                        <Toggle
                            icons={false}
                            checked={currentStandardTime.autoUpdate}
                            onChange={(event) =>
                                this.handleChangeStatusStandardTime(event.target.checked)
                            }
                        />
                    </div>
                </div>
                <div className="row oee-table-lable-row">
                    <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                        <p>{translate('standardtimes')}</p>
                        {action === 'update-asset-sku' && (
                            <AukTooltip.Help title="Set as 0 to remove">
                                <span className="d-flex align-items-center ml-2">
                                    <InfoCircleOutlined />
                                </span>
                            </AukTooltip.Help>
                        )}
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-8">
                        <Input
                            ref={this.inputRef}
                            maxLength={stdTimeMaxLength}
                            value={input}
                            onChange={(e) => this.handleChangeInput(e)}
                        />
                    </div>
                </div>
            </>
        );
    };

    renderForAsset = () => {
        const { asset, input } = this.state;

        return (
            <>
                <div className="row oee-table-lable-row">
                    <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                        <>
                            <span style={{ textTransform: 'capitalize' }}>
                                {translate('speedloss')}
                            </span>
                    (s)
                        </>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                        <Toggle
                            icons={false}
                            checked={asset.speed}
                            onChange={(event) =>
                                this.handleChangeStatusAsset('speed', event.target.checked)
                            }
                        />
                    </div>
                </div>
                <div className="row oee-table-lable-row">
                    <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                        <>
                            <span style={{ textTransform: 'capitalize' }}>
                                {translate('autocycletime')}
                            </span>
                    (s)
                        </>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-8 oee-table-content">
                        <Toggle
                            icons={false}
                            checked={asset.autoUpdate}
                            onChange={(event) =>
                                this.handleChangeStatusAsset('cycle_time', event.target.checked)
                            }
                        />
                    </div>
                </div>

                <div className="row oee-table-lable-row">
                    <div className="col col-xs-12 col-sm-12 col-md-4 oee-table-lable">
                        <span>{translate('standardtimes')}</span>
                    </div>
                    <div className="col col-xs-12 col-sm-12 col-md-8">
                        <Input
                            ref={this.inputRef}
                            maxLength={8}
                            value={input}
                            onChange={(e) => this.handleChangeInput(e)}
                        />
                    </div>
                </div>
            </>
        );
    };

    get generateTitle() {
        const { type, asset, currentStandardTime } = this.state;

        if (type === 'asset-sku') {
            return `SKU: ${currentStandardTime.sku.name} - Asset:${currentStandardTime.asset.asset_name}`;
        }

        return `Asset: ${asset.asset_name}`;
    }

    render() {
        const { type, showModalStandardtime } = this.state;
        return (
            <Modal
                className="auk-modal custom-modal-oee-table"
                title={this.generateTitle}
                visible={showModalStandardtime}
                onOk={() => this.handleSubmitStandardTime()}
                onCancel={() => this.setState({ showModalStandardtime: false })}
            >
                {type === 'asset-sku' ? this.renderForAssetSku() : null}
                {type === 'asset' ? this.renderForAsset() : null}
            </Modal>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        entity: currentEntitySelector(appState),
    }
}

const mapDispatchToProps = (dispatch) => {
    return {
        updateAssetSkuStandardTime: (entity_id, payload, callback) =>
            dispatch(updateAssetSkuStdTime(entity_id, payload, callback)),
        updateAssetStandardTime: (entity_id, payload, callback) =>
            dispatch(updateAssetStdTime(entity_id, payload, callback)),
        createAssetSkuStandardTime: (entity_id, payload, callback) =>
            dispatch(createAssetSkuStdTime(entity_id, payload, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps,
    null,
    { forwardRef: true }
)(ModalStandardTime);
