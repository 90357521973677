import { updateStoreRedux } from '../../../legacy/utils/redux';
import { AssetsConstants as K } from './Assets.constants';
import { pickBy } from 'lodash';

const initialReducer = () => ({
    assets: {},
    operator: {
        skus: [],
        issues: [],
    },
    standardTimes: {},
});

export const assetsReducer = (
    state = initialReducer(),
    action
) => {
    if (action.type === K.ACTIONS.SET_ASSETS_STATE) {
        return updateStoreRedux(state, {
            ...action.payload,
        });
    }

    if (action.type === K.ACTIONS.ADD_ASSETS_RESOURCE) {
        return updateStoreRedux(state, {
            assets: { ...state.assets, ...action.payload.assets },
        });
    }

    if (action.type === K.ACTIONS.REMOVE_ASSETS_RESOURCE) {
        const deleteSet = new Set(action.payload.asset_ids);
        const deletedState = pickBy(
            { ...state.assets },
            ({ asset_id }) => !deleteSet.has(asset_id)
        );

        return updateStoreRedux(state, {
            assets: deletedState,
        });
    }

    if (action.type === K.ACTIONS.ADD_ASSETS_SKUS_STANDARD_TIMES_RESOURCE) {
        return updateStoreRedux(state, {
            standardTimes: {
                ...state.standardTimes,
                ...action.payload.standardTimes,
            },
        });
    }

    if (action.type === K.ACTIONS.SET_ASSET_OPERATOR_PERMITTED_LABELS) {
        return updateStoreRedux(state, {
            operator: { ...action.payload },
        });
    }

    if (action.type === K.ACTIONS.RESET_ASSETS_STATE) {
        return updateStoreRedux(state, initialReducer());
    }

    return updateStoreRedux(state, {});
};
