export const AssetsConstants = {
    ACTIONS: {
        RESET_ASSETS_STATE: 'RESET_ASSETS_STATE',
        SET_ASSETS_STATE: 'SET_ASSETS_STATE',
        ADD_ASSETS_RESOURCE: 'ADD_ASSETS_RESOURCE',
        REMOVE_ASSETS_RESOURCE: 'REMOVE_ASSETS_RESOURCE',
        CASCADE_DELETE_ASSET: 'CASCADE_DELETE_ASSET',
        FETCH_ASSETS_PRODUCTION_REQUEST: 'FETCH_ASSETS_PRODUCTION_REQUEST',
        UPDATE_ASSET_STANDARD_TIME: 'UPDATE_ASSET_STANDARD_TIME',
        ADD_ASSETS_SKUS_STANDARD_TIMES_RESOURCE:
      'ADD_ASSETS_SKUS_STANDARD_TIMES_RESOURCE',
        UPDATE_ASSET: 'UPDATE_ASSET',
        FETCH_ASSET_OPERATOR_SETTINGS: 'FETCH_ASSET_OPERATOR_SETTINGS',
        SET_ASSET_OPERATOR_PERMITTED_LABELS: 'SET_ASSET_OPERATOR_PERMITTED_LABELS',
        SET_ASSET_OPERATOR_SETTINGS: 'SET_ASSET_OPERATOR_SETTINGS',
        FETCH_ASSETS_SKUS_STANDARD_TIMES: 'FETCH_ASSETS_SKUS_STANDARD_TIMES',
        CREATE_ASSET_SKU_STANDARD_TIME: 'CREATE_ASSET_SKU_STANDARD_TIME',
        UPDATE_ASSET_SKU_STANDARD_TIME: 'UPDATE_ASSET_SKU_STANDARD_TIME',
        BULK_CREATE_UPDATE_ASSET_SKU_STANDARD_TIMES:
      'BULK_CREATE_UPDATE_ASSET_SKU_STANDARD_TIMES',
        DELETE_ASSET: 'DELETE_ASSET',
        UPDATE_ASSET_CHART_ORDERS: 'UPDATE_ASSET_CHART_ORDERS',
        CREATE_ASSET: 'CREATE_ASSET',
        DELETE_ASSET_PURE: 'DELETE_ASSET_PURE',
    },
};
