export class Invitation {
    constructor(
        entity_id,
        invite_id,
        inviter_id,
        recipient,
        referral_code,
        requester,
        requester_id,
        status,
        type
    ) {
        this.entity_id = entity_id;
        this.invite_id = invite_id;
        this.inviter_id = inviter_id;
        this.recipient = recipient;
        this.referral_code = referral_code;
        this.requester = requester;
        this.requester_id = requester_id;
        this.status = status;
        this.type = type;
    }
}
