/* eslint-disable react/prop-types */
import React from 'react';

export const Panel = (props) => (
    <div className={`panel ${props.className || ''}`} style={props.style}>
        {props.children}
    </div>
);

export const PanelHeader = (props) => (
    <div className={`panel-header ${props.className || ''}`} style={props.style}>
        <div className="panel-title">
            {props.children}
        </div>
    </div>
);

export const PanelBody = (props) => (
    <div className={`panel-body ${props.className || ''}`} style={props.style}>
        {props.children}
    </div>
);

export const PanelFooter = (props) => (
    <div className={`panel-footer ${props.className || ''}`} style={props.style}>
        {props.children}
    </div>
);