/* eslint-disable react/prop-types */
// LIBRARIES
import * as d3 from 'd3';
import React, { useContext, useMemo, useState } from 'react';
import Toggle from 'react-toggle';
import { useDispatch } from 'react-redux';
import { Menu, Select } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

// COMPONENTS
import AukButton from '../../../../components/AukButton';
import ColorPicker from '../../../../components/ColorPicker';
import AukTooltip from '../../../../components/AukTooltip';
import LabelWrapper from '../../../../components/LabelWrapper';
import OnActiveInput from '../../../../components/OnActiveInput';
import { ColumnChart, LineChart } from '../../../../Charts/v1';
import { ColumnChartTooltip } from '../../../../Charts/v1/tooltips/templates';
import { flash } from '../../../../components/Flash';
import { Permission } from '../../../../components/Permission';

// HELPERS
import CONSTANTS from '../../../../Constants';
import { generateContinuousDomain } from '../../../../utils/charts';
import { getTargetsPathCoords } from '../../../../utils/targets';

// ACTIONS
import { UpdateMetadatum } from '../../../../../store/old/Metadata/Metadata.action';
import { UpdateFusion } from '../../../../../store/old/Fusions/Fusions.action';
import { updateAssetStdTime } from '../../../../../store/old/Assets/Assets.action';
import { eventEmitter } from '../../../../auxStore';

import { AssetDomainContext } from '..';
import { Fusion } from '../../../../models';
import { uniq } from 'lodash';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { currentEntitySelector } from '../../../../../store/old/Entity/Entity.selector';

const benchmarkXAccessor = (d) => d[0];
const benchmarkYAccessor = (d) => d[1];

const getMaxPushRateSeconds = (devices) => {
    return devices.reduce(
        (acc, curr) => (curr.sampling_rate > acc ? curr.sampling_rate : acc),
        0
    );
};

const getChartPushRate = (chart) => {
    if (chart instanceof Fusion) {
        const devices = uniq(
            chart.fusion_metadata.map((channel) => channel.ofDevice)
        );
        return getMaxPushRateSeconds(devices);
    }

    return chart.ofDevice.sampling_rate;
};

class ChartEditable extends React.Component {
    get chart() {
        const {
            meta: { mode, chart_type },
        } = this.props;

        const chartProps = this.getChartProps();

        if (mode === CONSTANTS.CHANNELS.MODES.STATE) {
            return <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />;
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.COLUMN) {
            return <ColumnChart {...chartProps} htmlTooltip={ColumnChartTooltip} />;
        }

        if (chart_type === CONSTANTS.CHARTS.TYPES.LINE) {
            return (
                <LineChart
                    {...chartProps}
                    focusEl="circle"
                    htmlTooltip={ColumnChartTooltip}
                />
            );
        }

        return null;
    }

    getChartProps() {
        const {
            meta,
            margin,
            window,
            onBrushCancel,
            onBrushEnd,
            asset,
            controls: { resolution },
        } = this.props;

        const { color, fusion_id, metadata_id } = meta;

        const resSeconds = moment
            .duration(resolution.res_x, resolution.res_period)
            .as('seconds');

        const useBenchmark = asset.targets.length && asset.primary === meta;
        const useMarkers =
      meta.mode === '3a' && getChartPushRate(meta) === resSeconds;

        const chartProps = {
            data: meta.data,
            margin,
            key: `${fusion_id || metadata_id}${color}`,
            xScale: d3.scaleTime(),
            yScale: d3.scaleLinear(),
            xDomain: window,
            yDomain: generateContinuousDomain(meta.data, (d) => d.val),
            xAccessor: (d) => d.time,
            yAccessor: (d) => d.val,
            colorAccessor: () => color || 'steelblue',
            useBrush: true,
            showYGrid: true,
            onBrushCancel: () => onBrushCancel(),
            yTickFormat: (d) => (`${d}`.length > 4 ? d.toExponential(1) : d),
            onBrushEnd: onBrushEnd,
            useMarkers,
            markers: meta.control_limits || [],
        };

        if (useBenchmark) {
            const benchmarkData =
        meta.mode === CONSTANTS.CHANNELS.MODES.DIGITAL_COUNT
            ? this._getBenchmarkPathData()
            : [];
            const benchmarkYDomain = generateContinuousDomain(
                benchmarkData,
                benchmarkYAccessor
            );
            chartProps.yDomain = [
                Math.min(chartProps.yDomain[0], benchmarkYDomain[0]),
                Math.max(chartProps.yDomain[1], benchmarkYDomain[1]),
            ];

            chartProps.useBenchmark = true;
            chartProps.benchmark = {
                xAccessor: benchmarkXAccessor,
                yAccessor: benchmarkYAccessor,
                data: benchmarkData,
            };
        }
        return chartProps;
    }

    _getBenchmarkPathData() {
        const {
            window,
            asset: { targets },
            controls: { resolution },
        } = this.props;

        if (!targets.length) return [];

        return getTargetsPathCoords(targets, window, resolution);
    }

    render() {
        const { meta, isPrimary } = this.props;

        const { units, mode, data } = meta;
        const aggregate = data.aggregate(mode, units);

        return (
            <div className="d-flex flex-column chart-editable">
                <div className="chart-editable__header">
                    <div className="chart-editable__header__config">
                        <Permission resource="standard_time" forResource canDo="edit">
                            {isPrimary && <StdTimeOptions />}
                        </Permission>

                        <MoreOptions meta={meta} />
                    </div>
                    <div className="aggregate">{aggregate}</div>
                </div>
                <div className="chart-editable__body">{this.chart}</div>
            </div>
        );
    }
}

export default ChartEditable;

const dropdownItemLabelStyle = { padding: '0 0.5em', fontSize: 12 };

const MoreOptions = ({ meta }) => {
    const { asset } = useContext(AssetDomainContext);
    const dispatch = useDispatch();
    const { asset_id } = asset;
    const { fusion_id, metadata_id, device_id, chart_title } = meta;

    const save = (data, cb = () => {}) => {
        if (fusion_id) {
            const params = { fusion_id, ...data };
            dispatch(UpdateFusion(asset_id, params, cb));
            return;
        }

        const params = { ...data, metadata_id, device_id };
        dispatch(UpdateMetadatum(asset_id, params, cb));
    };

    const handleSelectChange = (oee_input) => {
        save({ oee_input }, () => eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH));
    };

    const handleColorChange = (color) => save({ color });

    const options = useMemo(() => {
        const { mode } = meta;
        const oeeInputOptions = [
            { value: 'rj', label: 'Reject', display: mode === '1a' },
            { value: 'rw', label: 'Rework', display: mode === '1a' },
            // { value: 'bd', label: 'Breakdown', display: mode === '2a' },
            // { value: 'st', label: 'Setup', display: mode === '2a' },
            { value: null, label: 'None', display: true },
        ];

        return oeeInputOptions
            .filter((opt) => opt.display)
            .map(({ value, label }) => ({ value, label }));
    }, [meta]);

    return (
        <AukButton.Dropdown
            className="ml-2"
            style={{ marginRight: 20 }}
            icon={<MoreOutlined />}
            trigger={['click']}
            overlay={
                <Menu style={{ width: 240 }}>
                    {meta.mode !== '3a' && meta.chart_order !== 1 && (
                        <Menu.Item key={1}>
                            <LabelWrapper
                                col={6}
                                label="OEE Input"
                                className="options-menu__item mb-0"
                                labelStyle={dropdownItemLabelStyle}
                            >
                                <Select
                                    className="w-100"
                                    options={options}
                                    defaultValue={meta.oee_input || null}
                                    onChange={handleSelectChange}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                    }}
                                />
                            </LabelWrapper>
                        </Menu.Item>
                    )}
                    <Menu.Item key={2}>
                        <LabelWrapper
                            col={6}
                            label="Chart Color"
                            className="options-menu__item mb-0"
                            labelStyle={dropdownItemLabelStyle}
                        >
                            <ColorPicker
                                className="w-100"
                                value={meta.color}
                                onChange={handleColorChange}
                            />
                        </LabelWrapper>
                    </Menu.Item>
                </Menu>
            }
        />
    );
};

const StdTimeOptions = () => {
    const { asset } = useContext(AssetDomainContext);
    const { entity_id } = useSelector(currentEntitySelector)
    const dispatch = useDispatch();

    const { asset_id, speed, autoUpdate } = asset;

    const [stdTime, setStdTime] = useState(asset.stdTime);

    const placeholder = asset.stdTime || '-';

    const save = (data, cb) => {
        dispatch(
            updateAssetStdTime(entity_id, data, () => {
                cb && cb();
                eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
            })
        );
    };

    const handleSubmit = () => {
    // validate stdTime
        if (!+stdTime || (+stdTime && +stdTime > 999999)) {
            return flash({
                message: 'Please enter a valid number (<999999)',
                status: 'error',
            });
        }

        const data = { asset_id, stdTime: +stdTime };
        save(data);
    };

    const handleToggle = (field) => {
        const data = { asset_id, [field]: !asset[field] };
        save(data);
    };

    return (
        <>
            <AukTooltip.Help
                title={!speed ? 'Enable speed loss to edit' : ''}
                placement="left"
            >
                <div
                    className={`stdtime-input mx-2 ${!speed ? 'disabled' : ''}`}
                    style={{ width: 240 }}
                >
                    <label>Std Time (s)*</label>
                    <OnActiveInput
                        placeholder={placeholder}
                        handleBlur={() => setStdTime(placeholder)}
                        value={stdTime}
                        handleChange={(e) => setStdTime(e.target.value)}
                        handleSubmit={handleSubmit}
                    />
                    <AukButton.Dropdown
                        trigger={['click']}
                        icon={<i className="fas fa-sliders-h" />}
                        overlay={
                            <Menu style={{ width: 200 }}>
                                <Menu.Item key={1}>
                                    <LabelWrapper
                                        labelStyle={dropdownItemLabelStyle}
                                        col={6}
                                        label="Speed Loss"
                                        className="options-menu__item mb-0"
                                    >
                                        <div className="d-flex align-items-center h-100">
                                            <Toggle
                                                key={speed}
                                                icons={false}
                                                checked={speed}
                                                onChange={() => handleToggle('speed')}
                                            />
                                        </div>
                                    </LabelWrapper>
                                </Menu.Item>
                                <Menu.Item key={2}>
                                    <LabelWrapper
                                        labelStyle={dropdownItemLabelStyle}
                                        col={6}
                                        label="Auto Cycle"
                                        className={`options-menu__item mb-0`}
                                    >
                                        <div className="d-flex align-items-center h-100">
                                            <Toggle
                                                key={autoUpdate}
                                                icons={false}
                                                checked={autoUpdate}
                                                onChange={() => handleToggle('autoUpdate')}
                                            />
                                        </div>
                                    </LabelWrapper>
                                </Menu.Item>
                            </Menu>
                        }
                    />
                </div>
            </AukTooltip.Help>
        </>
    );
};
