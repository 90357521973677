import { put, takeLatest } from 'redux-saga/effects';

import { getMapFromArr } from '../../../legacy/utils/helpers';

// ACTION
import {
    getAllInvitationsSuccess,
    getAllInvitationsFailure,
    createInvitationFailure,
    updateInvitationFailure,
    revokeInvitationFailure,
} from './Invitation.action';

// MODEL
import { Invitation } from '../../../legacy/models';

// User SERVICES
import {
    api_getInvitations,
    api_createInvitation,
    api_revokeInvitation,
    api_updateInvitation,
} from './Invitation.service';

// HELPERS
import { store } from '../..';
import { currentEntitySelector } from '../Entity/Entity.selector';

// CONSTANT
import { InvitationConstants as K } from './Invitation.constant';
import { errorFlash } from '../../../legacy/components/Flash';

// GET ALL INVITATION
function* handleGetAllInvitations(data) {
    try {
        const { entity_id } = currentEntitySelector(store.getState());
        const response = yield api_getInvitations(entity_id);

        const responseMapValue = getMapFromArr(
            response.map((v) => {
                return new Invitation(
                    v.entity_id,
                    v.invite_id,
                    v.inviter_id,
                    v.recipient,
                    v.referral_code,
                    v.requester,
                    v.requester_id,
                    v.status,
                    v.type
                );
            }),
            'invite_id'
        );

        yield put(getAllInvitationsSuccess(responseMapValue));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(getAllInvitationsFailure(error));
    }
}

export function* getAllInvitations() {
    yield takeLatest(
        K.ACTIONS.FETCH_INVITATIONS_REQUEST,
        handleGetAllInvitations
    );
}

// REVOKE INVITATION
function* handleRevokeInvitation(data) {
    try {
        const currentState = store.getState().invitation.list;

        const cloneState = Object.assign({}, currentState);

        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield api_revokeInvitation(
            entity_id,
            data.payload.single
        );

        delete cloneState[data.payload.single.invite_id];

        yield put(getAllInvitationsSuccess(cloneState));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(revokeInvitationFailure(error));
    }
}

export function* revokeInvitation() {
    yield takeLatest(
        K.ACTIONS.REVOKE_INVITATION_REQUEST,
        handleRevokeInvitation
    );
}

// UPDATE INVITATION
function* handleUpdateInvitation(data) {
    try {
        const currentState = store.getState().group.list;

        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield api_updateInvitation(
            entity_id,
            data.payload.invite_id,
            data.payload.accept
        );

        const newState = {
            ...currentState,
            [response.invite_id]: new Invitation(
                response.entity_id,
                response.invite_id,
                response.inviter_id,
                response.recipient,
                response.referral_code,
                response.requester,
                response.requester_id,
                response.status,
                response.type
            ),
        };

        yield put(getAllInvitationsSuccess(newState));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(updateInvitationFailure(error));
    }
}

export function* updateInvitation() {
    yield takeLatest(
        K.ACTIONS.UPDATE_INVITATION_REQUEST,
        handleUpdateInvitation
    );
}

// CREATE INVITATION
function* handleCreateInvitation(data) {
    try {
        const currentState = store.getState().invitation.list;

        const { entity_id } = currentEntitySelector(store.getState());

        const response = yield api_createInvitation(
            entity_id,
            data.payload.emails
        );

        const invites = response.map((invite) => {
            return new Invitation(
                invite.entity_id,
                invite.invite_id,
                invite.inviter_id,
                invite.recipient,
                invite.referral_code,
                invite.requester,
                invite.requester_id,
                invite.status,
                invite.type
            );
        });

        const newState = {
            ...currentState,
            ...getMapFromArr(invites, 'invite_id'),
        };

        yield put(getAllInvitationsSuccess(newState));

        if (data.callback) {
            yield data.callback(response);
        }
    } catch (error) {
        errorFlash(error);
        yield put(createInvitationFailure(error));
    }
}

export function* createInvitation() {
    yield takeLatest(
        K.ACTIONS.CREATE_INVITATION_REQUEST,
        handleCreateInvitation
    );
}
