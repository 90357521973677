/* eslint-disable react/prop-types */
import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

// COMPONENT
import AukTooltip from '../../../components/AukTooltip';
import Toggle from 'react-toggle';
import {
    updateAssetSkuStdTime,
    updateAssetStdTime,
} from '../../../../store/old/Assets/Assets.action';

// MODEL
import { Asset, Sku } from '../../../models';
import { currentEntitySelector } from '../../../../store/old/Entity/Entity.selector';
import translate from '../../../utils/translate';

class OeeTableCell extends PureComponent {
    modalUpdateStandardTime;
    clickedToggle;
    state = {
        asset: new Asset(null, -1, ''),
        standardTime: {
            asset: new Asset(null, -1, ''),
            sku: new Sku(null, -1, ''),
            std_time: 0,
            autoUpdate: false,
        },
    };

    componentDidMount() {
        const { asset, standardTime } = this.props;

        this.setState({
            asset,
            standardTime,
        });
    }

    UNSAFE_componentWillReceiveProps({ asset, standardTime }) {
        this.setState({
            asset,
            standardTime,
        });
    }

    styleForCell() {
        const { standardTime } = this.state;
        const { isHeader } = this.props;
        const defaultStyle = {
            height: '100%',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            paddingLeft: 5,
            paddingRight: 5,
        };

        if (!isHeader && standardTime && standardTime.asset.asset_id === -1) {
            return {
                ...defaultStyle,
                fontWeight: 'bold',
            };
        }

        if (isHeader) {
            return {
                height: '100%',
                fontWeight: 'bold',
            };
        }

        return defaultStyle;
    }

    handleChangeStatusToggle = (field, status) => {
        const { isHeader, entity } = this.props;

        if (!isHeader) {
            const { standardTime } = this.state;
            const { asset, sku, std_time } = standardTime;
            return this.props.updateAssetSkuStandardTime(
                entity.entity_id, 
                {
                    asset_id: asset.asset_id,
                    sku_id: sku.sku_id,
                    autoUpdate: status,
                    std_time,
                },
                () => {
                    this.clickedToggle = false;
                }
            );
        }

        if (isHeader && field === 'cycle-time') {
            const { asset } = this.state;

            const newAsset = new Asset(
                null,
                asset.asset_id,
                asset.asset_name,
                null,
                null,
                -1,
                null,
                [],
                status,
                asset.speed,
                asset.stdTime
            );
            console.log('cycle time clicked')
            return this.props.updateAssetStandardTime(entity.entity_id, newAsset, () => {
                this.clickedToggle = false;
            });
        }

        if (isHeader && field === 'speed') {
            const { asset } = this.state;

            const newAsset = new Asset(
                null,
                asset.asset_id,
                asset.asset_name,
                null,
                null,
                -1,
                null,
                [],
                asset.autoUpdate,
                status,
                asset.stdTime
            );

            console.log('speed clicked')
            return this.props.updateAssetStandardTime(entity.entity_id, newAsset, () => {
                this.clickedToggle = false;
            });
        }
    };

    renderHeader() {
        const { asset } = this.state;
        const { handleOpenModalStandardTime, checkPolicy } = this.props;

        const { asset_name, autoUpdate, speed, stdTime } = asset;

        return (
            <div
                className="header-oee-asset-table enabled"
                onClick={(e) => {
                    setTimeout(() => {
                        if (
                            !this.clickedToggle &&
              handleOpenModalStandardTime &&
              checkPolicy.isEditPermission
                        ) {
                            handleOpenModalStandardTime('asset', null, asset);
                        }
                    });
                }}
            >
                <p style={{ margin: 0, width: '100%' }}>{asset_name}</p>
                <div className="header-oee-asset-table-group">
                    <div className="header-oee-asset-table-group-item">
                        <p style={{ margin: 0 }}>
                            {stdTime === 0 ? translate('notset')
                                : stdTime === Math.floor(stdTime) ? (
                                    stdTime
                                ) : (
                                    stdTime.toFixed(3)
                                )}
                        </p>
                    </div>
                    <div className="header-oee-asset-table-group-item">
                        {stdTime > 0 ? (
                            <div
                                style={
                                    checkPolicy.isEditPermission
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                }
                            >
                                <Toggle
                                    icons={false}
                                    checked={speed}
                                    onChange={(event) => {
                                        if (!checkPolicy.isEditPermission) {
                                            return;
                                        }

                                        this.handleChangeStatusToggle(
                                            'speed',
                                            event.target.checked
                                        );
                                        this.clickedToggle = true;
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                    <div className="header-oee-asset-table-group-item">
                        {stdTime > 0 ? (
                            <div
                                style={
                                    checkPolicy.isEditPermission
                                        ? { opacity: 1 }
                                        : { opacity: 0.5 }
                                }
                            >
                                <Toggle
                                    icons={false}
                                    checked={autoUpdate}
                                    onChange={(event) => {
                                        if (!checkPolicy.isEditPermission) {
                                            return;
                                        }

                                        this.handleChangeStatusToggle(
                                            'cycle-time',
                                            event.target.checked
                                        );
                                        this.clickedToggle = true;
                                    }}
                                />
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }

    renderOtherCells() {
        const { standardTime } = this.state;
        const { handleOpenModalStandardTime, isEnabled, checkPolicy } = this.props;
        const { asset, sku, std_time, autoUpdate } = standardTime;

        return (
            <AukTooltip.Help title={isEnabled ? '' : 'Asset speed must be turned on'}>
                <div
                    className={`standard-time-oee-table ${
                        isEnabled ? 'enabled' : 'disabled'
                    }`}
                    style={this.styleForCell()}
                    onClick={(event) => {
                        setTimeout(() => {
                            if (!isEnabled) {
                                return;
                            }

                            if (
                                !this.clickedToggle &&
                handleOpenModalStandardTime &&
                checkPolicy.isEditPermission
                            ) {
                                handleOpenModalStandardTime('asset-sku', {
                                    sku,
                                    asset,
                                    std_time: std_time,
                                    autoUpdate: autoUpdate,
                                });
                            }
                        });

                        event.preventDefault();
                    }}
                >
                    <span>
                        {std_time === 0 ? (
                            <span>
                                {translate('notset')}
                            </span>
                        ) : std_time === Math.floor(std_time) ? (
                            std_time
                        ) : (
                            std_time.toFixed(3)
                        )}
                    </span>
                    {std_time ? (
                        <div
                            style={
                                checkPolicy.isEditPermission ? { opacity: 1 } : { opacity: 0.5 }
                            }
                        >
                            <Toggle
                                icons={false}
                                checked={autoUpdate}
                                onChange={(event) => {
                                    if (!isEnabled || !checkPolicy.isEditPermission) {
                                        return;
                                    }
                                    this.handleChangeStatusToggle(
                                        'other-cell-cycle-time',
                                        event.target.checked
                                    );
                                    this.clickedToggle = true;
                                }}
                            />
                        </div>
                    ) : null}
                </div>
            </AukTooltip.Help>
        );
    }

    render() {
        const { isHeader } = this.props;
        return <>{isHeader ? this.renderHeader() : this.renderOtherCells()}</>;
    }
}

const checkPolicyStandardtime = (user) => {
    return {
        isEditPermission: user.check_resource_policy(
            'standard_time',
            false,
            true,
            false
        ),
    };
};

const mapStateToProps = (rootState) => {
    return {
        checkPolicy: checkPolicyStandardtime(rootState.auth.user),
        entity: currentEntitySelector(rootState)
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateAssetSkuStandardTime: (entity_id, payload, callback) =>
            dispatch(updateAssetSkuStdTime(entity_id, payload, callback)),
        updateAssetStandardTime: (entity_id, payload, callback) =>
            dispatch(updateAssetStdTime(entity_id, payload, callback)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OeeTableCell);
