import { makeRequest } from '../Shared/Shared.action';
import { currentEntitySelector } from '../Entity/Entity.selector';

// REDUX STORE
import { store } from '../..';

export const api_initializeDeviceAsRelay = (device_id, gateway_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${device_id}/relay`,
                params: JSON.stringify({ gateway_id }),
                method: 'POST',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_updateDeviceEntity = (entity_id, mac_address) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device`,
                params: JSON.stringify({ mac_address }),
                method: 'PUT',
                success: resolve,
                error: reject,
            });
        }
    );
};

// Preview Data
export const api_previewSerialData = (payload) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${payload.device_id}/serial_preview`,
                method: 'POST',
                params: JSON.stringify(payload.data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Update Device
export const api_updateDevice = (payload) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${payload.device_id}`,
                method: 'PUT',
                params: JSON.stringify(payload.data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Create Device
export const api_createDevice = (payload) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${payload.device_id}/initialization`,
                method: 'POST',
                params: JSON.stringify(payload.data),
                success: resolve,
                error: reject,
            });
        }
    );
};

// Node status
export const api_refreshMeshNode = (gateway_id, dmac) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/gateway/${gateway_id}/link/${dmac}`,
                method: 'GET',
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_resetDevice = (device_id) => {
    const { entity_id } = currentEntitySelector(store.getState());

    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${device_id}`,
                method: 'DELETE',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_linkDevice = (entity_id, asset_id, device_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/device/${device_id}/link`,
                method: 'PUT',
                params: JSON.stringify({ asset_id }),
                success: resolve,
                error: reject,
            });
        }
    );
};

export const api_unlinkDevice = (entity_id, asset_id, device_id) => {
    return new Promise(
        (resolve, reject) => {
            makeRequest({
                path: `v1/entity/${entity_id}/asset/${asset_id}/device/${device_id}/unlink`,
                method: 'PUT',
                params: JSON.stringify({}),
                success: resolve,
                error: reject,
            });
        }
    );
};
