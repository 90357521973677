/* eslint-disable react/prop-types */
// LIBRARIES
import React from 'react';
import { Button } from 'antd';
import { connect } from 'react-redux';

// COMPONENTS
import Collapsible from '../../components/Collapsible';
import AukTooltip from '../../components/AukTooltip';
import Modal from '../../components/Modal';
import SchedulerPanel from './components/SchedulerPanel';
import ScheduleTable from './components/ScheduleTable';
import { Permission } from '../../components/Permission';
import { withEntity } from '../../Wrappers/HOCs/withEntity';

// CONSTANTS
import { SchedulesConstants as K } from '../../../store/old/Schedules/Schedules.constants';

// STYLES
import './EntitySchedule.scss';

// ACTIONS
import {
    stopRecurringDowntime,
    stopRecurringShift,
    deleteShifts,
    fetchSchedules,
    setSchedulesFilter,
    deleteDowntimes,
} from '../../../store/old/Schedules/Schedules.action';

// SELECTORS
import { arrayAssetsWithoutUsingBlock } from '../../../store/old/Assets/Assets.selector';
import {
    arrayDowntimes,
    arrayShifts,
} from '../../../store/old/Schedules/Schedules.selector';

// HELPERS
import { regexMatch } from '../../utils/helpers';
import AukFilterSelect from '../../components/AukFilterSelect';
import { flash } from '../../components/Flash';
import { capitalize } from 'lodash';
import { SPAWrapper } from '../../components/SPAWrapper';
import translate from '../../utils/translate';

const sortSchedules = (arr) => {
    return arr.sort((a, b) => {
        if (!a.isCompleted && b.isCompleted) return -1;
        if (a.isCompleted && !b.isCompleted) return 1;
        return 0;
    });
};

const SCHEDULE_STATUS_OPTIONS = [
    { value: K.FILTER_BY.ALL, title: K.FILTER_BY.ALL },
    { value: K.FILTER_BY.ACTIVE, title: K.FILTER_BY.ACTIVE },
    { value: K.FILTER_BY.ARCHIVED, title: K.FILTER_BY.ARCHIVED },
];

const getFiltered = (filterBy, schedule) => {
    const completed = schedule.isCompleted;
    switch (filterBy) {
    case K.FILTER_BY.ACTIVE:
        return !completed;
    case K.FILTER_BY.ARCHIVED:
        return completed;
    default:
        return true;
    }
};

class EntitySchedule extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            selection: null,
            showFormModal: false,
            search: '',
            deleteModal: {
                show: false,
                title: '',
                body: '',
                onProceed: () => {},
            },
        };

        const { entity_id } = this.props.entity;
        this.props.getSchedules(entity_id);
    }

    handleClickCreate() {
        this.setState({ showFormModal: true, selection: null });
    }

    handleCancelFormModal() {
        this.setState({ showFormModal: false, selection: null });
    }

    handleClickDelete(selection) {
        const isShift = !!selection.shift_id;
        const type = isShift ? K.SHIFT : K.DOWNTIME;

        if (!selection.isFuture) {
            return flash({
                message: `Failed to delete. ${capitalize(type)} has already begun.`,
                status: 'error',
            });
        }

        const name = selection.shift_name || selection.downtime_name;
        const title = `Delete ${type}`;
        const body = (
            <div>
                <p>
          You are about to delete{' '}
                    {<strong className="text-uppercase">{name}</strong>}.
                </p>
                <p>Would you like to continue?</p>
            </div>
        );

        this.setState({
            deleteModal: {
                show: true,
                title,
                body,
                onProceed: () => {
                    const deleteHandler = isShift
                        ? this.props.deleteShifts
                        : this.props.deleteDowntimes;
                    deleteHandler([selection.shift_id || selection.downtime_id], () =>
                        this.hideModal()
                    );
                },
            },
        });
    }

    handleClickStop(selection) {
        const isShift = !!selection.shift_id;
        const type = isShift ? K.SHIFT : K.DOWNTIME;
        const name = selection.shift_name || selection.downtime_name;
        const title = `Stop recurring ${type}`;
        const body = (
            <div>
                <p>
          You are about to stop{' '}
                    {<strong className="text-uppercase">{name}</strong>}.
                </p>
                <p>
          This might affect subsequent OEE computations. Would you like to
          continue?
                </p>
            </div>
        );

        this.setState({
            deleteModal: {
                show: true,
                title,
                body,
                onProceed: () => {
                    const stopHandler = isShift
                        ? this.props.stopRecurringShift
                        : this.props.stopRecurringDowntime;
                    stopHandler(selection.shift_id || selection.downtime_id, () =>
                        this.hideModal()
                    );
                },
            },
        });
    }

    hideModal() {
        this.setState({
            deleteModal: {
                show: false,
                title: '',
                body: '',
                onProceed: () => {},
            },
        });
    }

    get shifts() {
        const { search } = this.state;

        const result = this.props.shifts
            .filter((s) => getFiltered(this.props.filterStatus, s))
            .filter((s) => regexMatch(s.shift_name, search, { escape: true }));

        return sortSchedules(result);
    }

    get downtimes() {
        const { search } = this.state;

        const result = this.props.downtimes
            .filter((s) => getFiltered(this.props.filterStatus, s))
            .filter((s) => regexMatch(s.downtime_name, search, { escape: true }));

        return sortSchedules(result);
    }

    render() {
        return (
            <SPAWrapper className="entity-schedule">
                {this.state.showFormModal ? (
                    <SchedulerPanel
                        data={this.state.selection}
                        assetOptions={this.props.arrayAssets}
                        cancel={() => this.handleCancelFormModal()}
                    />
                ) : null}
                <div className="d-flex mb-3 justify-content-between align-items-center">
                    <AukFilterSelect
                        style={{ width: 140 }}
                        options={SCHEDULE_STATUS_OPTIONS}
                        value={this.props.filterStatus}
                        onChange={(e) => this.props.setFilter(e)}
                    />
                    <div className="d-flex">
                        <Permission resource="schedules" forResource canDo="edit">
                            <div className="d-flex">
                                <AukTooltip.Help title={"Add Shift/Downtime"}>
                                    <Button
                                        onClick={(e) => this.handleClickCreate()}
                                        className="auk-button auk-button--round"
                                    >
                                        <i className="fas fa-plus" />
                                    </Button>
                                </AukTooltip.Help>
                            </div>
                        </Permission>
                    </div>
                </div>
                <div className="d-flex flex-column" style={{ flexGrow: 1 }}>
                    <Collapsible
                        isOpen={true}
                        label={`Shifts (${this.shifts.length})`}
                        alwaysShowLabel={true}
                    >
                        <ScheduleTable
                            rowKeyAccessor={(d) => d.shift_id}
                            data={this.shifts}
                            collection="shifts"
                            type={K.SHIFT}
                            assetOptions={this.props.arrayAssets}
                            handleClickDelete={(item) => this.handleClickDelete(item)}
                            handleClickStop={(item) => this.handleClickStop(item)}
                        />
                    </Collapsible>
                    <Collapsible
                        isOpen={true}
                        label={`Downtimes (${this.downtimes.length})`}
                        alwaysShowLabel={true}
                    >
                        <ScheduleTable
                            rowKeyAccessor={(d) => d.downtime_id}
                            data={this.downtimes}
                            collection="downtimes"
                            type={K.DOWNTIME}
                            assetOptions={this.props.arrayAssets}
                            handleClickDelete={(item) => this.handleClickDelete(item)}
                            handleClickStop={(item) => this.handleClickStop(item)}
                        />
                    </Collapsible>
                </div>
                <Modal
                    show={this.state.deleteModal.show}
                    toggle={() => this.hideModal()}
                    onProceed={() => this.state.deleteModal.onProceed()}
                    title={this.state.deleteModal.title}
                    body={this.state.deleteModal.body}
                />
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        shifts: arrayShifts(rootState),
        downtimes: arrayDowntimes(rootState),
        arrayAssets: arrayAssetsWithoutUsingBlock(rootState),
        filterStatus: rootState.schedules.filter,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        stopRecurringShift: (shift_id, callback) =>
            dispatch(stopRecurringShift(shift_id, callback)),
        deleteShifts: (shifts, callback) =>
            dispatch(deleteShifts(shifts, callback)),
        stopRecurringDowntime: (downtime_id, callback) =>
            dispatch(stopRecurringDowntime(downtime_id, callback)),
        deleteDowntimes: (downtimes, callback) =>
            dispatch(deleteDowntimes(downtimes, callback)),
        setFilter: (str) => dispatch(setSchedulesFilter(str)),
        getSchedules: (entity_id) => dispatch(fetchSchedules(entity_id)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withEntity(EntitySchedule));
