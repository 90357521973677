export const InvitationConstants = {
    ACTIONS: {
        FETCH_INVITATIONS_REQUEST: 'FETCH_INVITATIONS_REQUEST',
        FETCH_INVITATIONS_SUCCESS: 'FETCH_INVITATIONS_SUCCESS',
        FETCH_INVITATIONS_FAILURE: 'FETCH_INVITATIONS_FAILURE',
        CREATE_INVITATION_REQUEST: 'CREATE_INVITATION_REQUEST',
        CREATE_INVITATION_FAILURE: 'CREATE_INVITATION_FAILURE',
        REVOKE_INVITATION_REQUEST: 'REVOKE_INVITATION_REQUEST',
        REVOKE_INVITATION_FAILURE: 'REVOKE_INVITATION_FAILURE',
        UPDATE_INVITATION_REQUEST: 'UPDATE_INVITATION_REQUEST',
        UPDATE_INVITATION_FAILURE: 'UPDATE_INVITATION_FAILURE',
    },
};
