/* eslint-disable react/prop-types */
import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import AukButton from '../../components/AukButton';
import AssetForm from '../Assets/AssetForm';
import DeviceScan from './DeviceScan';
import DeviceConfiguration from './DeviceConfiguration';
import ModalPanel from '../../components/ModalPanel';
import { PanelBody, PanelFooter, PanelHeader } from '../../components/Panel';
import { CreateDevice, UpdateDevice } from '../../../store/old/Devices/Devices.action';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';

const DEVICE_SCAN = 1;
const SETUP_ASSET = 2;
const SETUP_DEVICE = 3;

const DeviceInitFlow = (props) => {
    const dispatch = useDispatch();
    const { cancel, gridSelection } = props;
    const [step, setStep] = useState(DEVICE_SCAN);
    const [device, setDevice] = useState(null);
    const [asset, setAsset] = useState({});
    const [hasInitialized, setInitialized] = useState(false);

    const onScan = (result) => {
        if (result.type === K.TYPES.NODE) {
            setStep(SETUP_ASSET);
            setDevice(result.device);
            return;
        }

        cancel();
    };

    const submitAsset = (data) => {
        setAsset(data);
        setStep(SETUP_DEVICE);
    };

    const submitFinal = ({ device_id, data }, callback) => {
        hasInitialized
            ? dispatch(
                UpdateDevice({ device_id, data }, () => {
                    // for serial comms
                    // eventEmitter.trigger(CONSTANTS.EVENTS.REFRESH);
                    callback && callback();
                })
            )
            : dispatch(
                CreateDevice(
                    {
                        device_id,
                        data: {
                            ...data,
                            asset,
                            tile: {
                                position_x: gridSelection.x,
                                position_y: gridSelection.y,
                            },
                        },
                    },
                    () => {
                        setInitialized(true);
                        callback && callback();
                    }
                )
            );
    };

    if (step === DEVICE_SCAN) {
        return (
            <ModalPanel>
                <PanelHeader>Initialize Device</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    <DeviceScan hideOPCUA hideGateway onScan={onScan} />
                </PanelBody>
                <PanelFooter className="p-3">
                    <AukButton.Cancel onClick={cancel} />
                </PanelFooter>
            </ModalPanel>
        );
    }

    if (step === SETUP_ASSET) {
        return (
            <ModalPanel>
                <PanelHeader>Add Asset</PanelHeader>
                <PanelBody className="d-flex justify-content-center">
                    <AssetForm submit={submitAsset} />
                </PanelBody>
                <PanelFooter className="p-3">
                    <AukButton.Cancel onClick={cancel} />
                </PanelFooter>
            </ModalPanel>
        );
    }

    return (
        <ModalPanel>
            <PanelHeader>Configure Device</PanelHeader>
            <PanelBody className="d-flex justify-content-center">
                {device && (
                    <DeviceConfiguration
                        device={device}
                        submit={submitFinal}
                        onSubmit={cancel}
                        isInit={true}
                    />
                )}
            </PanelBody>
            <PanelFooter className="p-3">
                <AukButton.Cancel onClick={cancel} />
            </PanelFooter>
        </ModalPanel>
    );
};

export default DeviceInitFlow;
