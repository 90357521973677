import _ from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Table } from 'antd';

const columns = [
    {
        title: 'Category',
        dataIndex: 'category',
    },
    {
        title: 'Code',
        dataIndex: 'code',
    },
    {
        title: 'Name',
        dataIndex: 'name',
    },
];

class SkusTab extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    get rowSelection() {
        return {
            checkStrictly: true,
            selectedRowKeys: this.props.checked,
            onChange: (selectedRowKeys) =>
                this.props.handleCheck(selectedRowKeys),
        };
    }

    get dataSource() {
        const { skus } = this.props;
        return _.flatten(
            _.values(_.groupBy(_.sortBy(skus, 'category'), 'category')).map((items) =>
                _.sortBy(items, 'name')
            )
        );
    }

    render() {
        return (
            <div className="configuration-skus-tab">
                <Table
                    dataSource={this.dataSource}
                    columns={columns}
                    rowKey={'sku_id'}
                    rowSelection={this.rowSelection}
                />
            </div>
        );
    }
}

const mapStateToProps = (rootState) => {
    return {
        skus: rootState.sku.skus,
    };
};

export default connect(mapStateToProps)(SkusTab);
