import { store } from '../../store';
import CONSTANTS from '../Constants';

const cellPadding = CONSTANTS.DASHBOARD.CELL_PADDING;
const cellWidth = CONSTANTS.DASHBOARD.CELL_WIDTH;
const cellHeight = CONSTANTS.DASHBOARD.CELL_HEIGHT;
const margin = CONSTANTS.DASHBOARD.MARGIN;

export class CommonTile {
    position_x;
    position_y;

    constructor(position_x = -1, position_y = -1) {
        this.position_x = position_x;
        this.position_y = position_y;
    }

    get arrowX() {
        return this.position_x * (cellWidth + cellPadding);
    }

    get arrowY() {
        return (
            this.position_y * (cellHeight + cellPadding) +
      cellPadding / 2 +
      margin.top
        );
    }
}

export const parseTileArguments = (t) => {
    return [t.position_x, t.position_y, t.tile_id, t.asset_id];
};

export class Tile extends CommonTile {
    constructor(
        position_x,
        position_y,
        tile_id,
        asset_id
    ) {
        super(position_x, position_y);

        this.tile_id = tile_id;
        this.asset_id = asset_id;
    }

    get id() {
    // for dashboard component
        return `${this.tile_id}`;
    }

    get asset() {
        const {
            assets: { assets: assetsState },
        } = store.getState();

        return assetsState[this.asset_id];
    }

    // widget adaptor
    get data() {
        return this.asset && this.asset.primary && this.asset.primary.data;
    }
}

export const parseSummaryTileArguments = (t) => {
    return [
        t.position_x,
        t.position_y,
        t.block,
        t.label,
        t.summary_tile_id,
        t.type || 'block',
    ];
};

export class SummaryTile extends CommonTile {
    block;
    label;
    summary_tile_id;
    type;

    constructor(
        position_x,
        position_y,
        block,
        label,
        summary_tile_id,
        type
    ) {
        super(position_x, position_y);
        this.block = block;
        this.label = label;
        this.summary_tile_id = summary_tile_id;
        this.type = type;
    }

    get id() {
    // for dashboard component
        return `st${this.summary_tile_id}`;
    }

    get data() {
        const {
            data: { summary_tiles },
        } = store.getState();

        const oeeData = summary_tiles[this.summary_tile_id];

        return {
            data: oeeData,
            label: (this.block && this.block.block_name) || this.label,
            block: this.block,
        };
    }
}
