import React from 'react';
import { Button } from 'reactstrap';

import CONSTANTS from '../../../../../Constants';
import OperatorChart from '../OperatorChart';
import { Panel, PanelWO } from '../OperatorLabelPanel';

const SHOW = {
    OEE: 'showOEE',
    SKU: 'showSKU',
    WO: 'showWO',
    CHART: 'showChart',
};

const oeeStyle = (color) => ({ borderLeft: `10px solid ${color}` });

const ISSUES_DUMMY = [
    {
        id: 1,
        label: CONSTANTS.CAT.ms.altLabel,
        style: oeeStyle(CONSTANTS.CAT.ms.color),
    },
    {
        id: 2,
        label: CONSTANTS.CAT.bd.altLabel,
        style: oeeStyle(CONSTANTS.CAT.bd.color),
    },
    {
        id: 3,
        label: CONSTANTS.CAT.st.altLabel,
        style: oeeStyle(CONSTANTS.CAT.st.color),
    },
];

const SKU_DUMMY = [
    { id: 1, label: 'SKU001' },
    { id: 2, label: 'SKU002' },
    { id: 3, label: 'SKU003' },
];

const WO_DUMMY = [{ id: 1, label: 'WO-001' }];

export default class PanelsTab extends React.Component<any> {
    constructor(props) {
        super(props);
    }

    render() {
        const {
            panels,
            asset,
            handleChangePanelDisplay,
            dateRange,
            controls,
        } = this.props;

        return (
            <div className="configuration-panel-tab">
                <div id="basic-view-panel-wrapper" className="basic-view-panel-wrapper">
                    <div className="panel-item-wrapper">
                        <Panel
                            title="OEE"
                            data={ISSUES_DUMMY}
                            className={!panels[SHOW.OEE] ? 'inactive' : ''}
                        />
                        <ButtonCheckbox
                            checked={panels[SHOW.OEE]}
                            handleClick={() => handleChangePanelDisplay(SHOW.OEE)}
                        />
                    </div>
                    <div className="panel-item-wrapper">
                        <Panel
                            title="SKU"
                            data={SKU_DUMMY}
                            className={!panels[SHOW.SKU] ? 'inactive' : ''}
                        />
                        <ButtonCheckbox
                            checked={panels[SHOW.SKU]}
                            handleClick={() => handleChangePanelDisplay(SHOW.SKU)}
                        />
                    </div>
                    <div className="panel-item-wrapper">
                        <PanelWO
                            title="W/O"
                            data={WO_DUMMY}
                            className={!panels[SHOW.WO] ? 'inactive' : ''}
                        />
                        <ButtonCheckbox
                            checked={panels[SHOW.WO]}
                            handleClick={() => handleChangePanelDisplay(SHOW.WO)}
                        />
                    </div>
                </div>
                <div className="configuration-panel--chart">
                    <div
                        className={!panels[SHOW.CHART] ? 'inactive' : ''}
                        style={{
                            height: '100%',
                            width: '100%',
                            pointerEvents: 'none',
                        }}
                    >
                        <OperatorChart
                            pointerEvents={false}
                            dateRange={dateRange}
                            meta={asset.primary}
                            asset={asset}
                            controls={controls}
                        />
                    </div>
                    <ButtonCheckbox
                        checked={panels[SHOW.CHART]}
                        handleClick={() => handleChangePanelDisplay(SHOW.CHART)}
                    />
                </div>
            </div>
        );
    }
}

const ButtonCheckbox = (props: any) => {
    return (
        <Button
            color={props.checked ? 'success' : 'secondary'}
            onClick={props.handleClick}
        >
            <span key={`${props.checked}`}>
                {props.checked ? (
                    <i className="fas fa-check" />
                ) : (
                    <i className="fas fa-ban" />
                )}
            </span>
        </Button>
    );
};
