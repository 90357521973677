import { isEmpty } from 'lodash';
import { store } from '../../store';

export const parseUserArguments = (u) => {
    return [
        u.user_id,
        u.email,
        u.name_first,
        u.name_last,
        u.mobile,
        u.country_code,
        u.role_name,
        u.appointment,
        u.entity_id,
        u.confirmed,
        u.user_img,
        u.user_img_id,
        u.policies,
    ];
};

export class User {
    user_id;
    role_name;
    email;
    name_first;
    name_last;
    mobile;
    country_code;
    appointment;
    user_img;
    user_img_id;
    entity_id;
    confirmed;
    policies;

    constructor(
        user_id,
        email,
        name_first,
        name_last,
        mobile,
        country_code,
        role_name,
        appointment,
        entity_id,
        confirmed,
        user_img,
        user_img_id,
        policies
    ) {
        this.user_id = user_id || -1;
        this.email = email || '';
        this.name_first = name_first || '';
        this.name_last = name_last || '';
        this.mobile = mobile || null;
        this.country_code = country_code || null;
        this.role_name = role_name || '';
        this.appointment = appointment || '';
        this.entity_id = entity_id || -1;
        this.confirmed = confirmed || '';
        this.user_img = user_img || '';
        this.user_img_id = user_img_id || -1;
        this.policies = policies;
    }

    get policy() {
        const {
            entity: { active },
        } = store.getState();

        return this.policies ? this.policies[active] : null;
    }

    get image() {
        return this.user_img && this.user_img.signedUrl;
    }

    get entity() {
        const {
            entity: { entities },
        } = store.getState();

        return entities[this.entity_id];
    }

    get preference() {
        return store.getState().preference.user;
    }

    get fallbackPreference() {
        const fallback = {};

        return fallback;
    }

    get nameFull() {
        const { name_first, name_last } = this;

        return `${name_first} ${name_last}`;
    }

    get user_block_ids() {
        const blocksKeyValue = {};

        if (!this.policy) return blocksKeyValue;

        const blocksLength = this.policy.blocks.length;

        for (let i = 0; i < blocksLength; i++) {
            blocksKeyValue[Number(this.policy.blocks[i].block_id)] =
        this.policy.blocks[i];
        }

        return blocksKeyValue;
    }

    // TO CHECK POLICY FOR BLOCK
    check_user_policy_for_block(block_id) {
        if (
            !this.policy ||
      !block_id ||
      Object.keys(this.policy).length == 0 ||
      this.role_name === 'owner'
        )
            return true;

        const block_key_value = this.user_block_ids;

        if (isEmpty(block_key_value) || isEmpty(block_key_value[block_id]))
            return false;

        return true;
    }

    // TO CHECK WHETHER URL IS VALID
    check_user_policy_url(resource) {
        if (
            !this.policy ||
      Object.keys(this.policy).length == 0 ||
      this.role_name === 'owner'
        )
            return true;

        const resourceFound = this.policy.resource_policies.find((v) => {
            return v.resource && v.resource.resource === resource;
        });

        if (!resourceFound || resourceFound.rights === 'nil') return false;

        return true;
    }

    // TO CHECK RESOURCE FOR COMPONENT
    check_resource_policy(resource, canView, canEdit, canFull) {
        if (
            !this.policy ||
      this.role_name === 'owner' ||
      Object.keys(this.policy).length == 0
        )
            return true;

        const resourceFound = this.policy.resource_policies.find((v) => {
            return v.resource && v.resource.resource === resource;
        });

        const rights = resourceFound && resourceFound.rights;

        if (rights === 'full') return true;

        if (rights === 'edit') return canView || canEdit;

        if (rights === 'view') return canView;

        return false;
    }

    get isWhitelistedDemoUser() {
        const demo_user_whitelist = [223];
        return this.entity_id === 52 && demo_user_whitelist.includes(this.user_id);
    }

    get isClientUser() {
        return this.entity_id !== 52;
    }
}

export const InitialUser = new User();

export const DELETED_USER = {
    name_first: 'Deleted',
    name_last: 'User',
    nameFull: 'Deleted User',
    user_id: -1,
};
