/* eslint-disable react/prop-types */
import React, { useCallback, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { Breadcrumb, Form } from 'antd';
import { isEmpty } from 'lodash';
import AukButton from '../../components/AukButton';
import DeviceForm from './DeviceForm';
import ChannelsForm from './ChannelsForm';
import { DeviceConstants as K } from '../../../store/old/Devices/Devices.constants';
import { flash } from '../../components/Flash';
import './DeviceConfiguration.scss';
import { DoubleRightOutlined } from '@ant-design/icons';
import { Permission } from '../../components/Permission';

const DEVICE_STEP = 1;
const CHANNELS_STEP = 2;

const DeviceConfiguration = (props) => {
    const isLoading = useSelector((appState) => appState.ui.loader.count);
    const { device, submit, isInit, onUnlink, onSubmit } = props;

    const [deviceFormState, setDeviceFormState] = useState({});
    const [channelsFormState, setChannelsFormState] = useState({});

    const [step, setStep] = useState(DEVICE_STEP);
    const interfaceType = useMemo(
        () =>
            isEmpty(deviceFormState)
                ? device.interface_type
                : deviceFormState.interface,
        [deviceFormState]
    );
    const isSerial = interfaceType !== K.IO;

    const isFinalStep = useMemo(() => step === CHANNELS_STEP, [step]);

    const handleNext = () => setStep(CHANNELS_STEP);

    const formData = useMemo(() => {
        const { serial, metadata } = channelsFormState;

        const params = {
            metadata,
            device: { ...deviceFormState },
        };

        serial && (params.device.serial = serial);

        return params;
    }, [channelsFormState, deviceFormState]);

    const handleSubmit = useCallback(
        (cb) => {
            submit({ device_id: device.device_id, data: formData }, cb);
        },
        [formData]
    );

    return (
        <Form.Provider
            onFormFinish={async () => {
                try {
                    if (!isFinalStep) return handleNext();
                    handleSubmit(() => onSubmit && onSubmit());
                } catch (e) {
                    flash({
                        message: 'Incomplete / incorrect fields',
                        status: 'warning',
                    });
                }
            }}
        >
            <div className="device-configuration">
                <Breadcrumb
                    className="p-3"
                    separator=">"
                    style={{ borderBottom: '1px solid #ddd' }}
                >
                    <Breadcrumb.Item>
                        <a
                            className={step === DEVICE_STEP ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault();
                                setStep(DEVICE_STEP);
                            }}
                        >
              Node
                        </a>
                    </Breadcrumb.Item>
                    <Breadcrumb.Item>
                        <a
                            className={step === CHANNELS_STEP ? 'active' : ''}
                            onClick={(e) => {
                                e.preventDefault();
                                setStep(CHANNELS_STEP);
                            }}
                        >
              Channels
                        </a>
                    </Breadcrumb.Item>
                </Breadcrumb>
                <div className="device-configuration__body ">
                    <DeviceForm
                        hidden={isFinalStep}
                        device={device}
                        onChange={setDeviceFormState}
                        onUnlink={onUnlink}
                        isInit={isInit}
                    />
                    <ChannelsForm
                        isSerial={isSerial}
                        interfaceType={interfaceType}
                        hidden={!isFinalStep}
                        device={device}
                        deviceForm={deviceFormState}
                        onChange={setChannelsFormState}
                        submit={handleSubmit}
                        isInit={isInit}
                    />
                </div>
                <div className="device-configuration__submit">
                    <Permission forResource resource="devices" canDo="edit">
                        <Form name="submitButton">
                            <AukButton.Blue
                                htmlType="submit"
                                disabled={step === CHANNELS_STEP && isLoading}
                            >
                                {isFinalStep ? (
                                    'Submit'
                                ) : (
                                    <span className="d-flex align-items-center">
                    Channels <DoubleRightOutlined className="ml-2" />
                                    </span>
                                )}
                            </AukButton.Blue>
                        </Form>
                    </Permission>
                </div>
            </div>
        </Form.Provider>
    );
};

export default DeviceConfiguration;
