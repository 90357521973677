/* eslint-disable react/prop-types */
// LIBRARIES
import { values } from 'lodash';
import React from 'react';
import { connect } from 'react-redux';
import { Button, Input, Select } from 'antd';

// COMPONENTS
import ConfirmationModal from '../../components/ConfirmationModal';
import CustomModal from '../../components/Modal';
import AukTooltip from '../../components/AukTooltip';
import Slider from './components/Slider';
import TrackedIssueEditor from '../../components/Modal/TrackedIssueEditor';
import { PanelFooter } from '../../components/Panel';
import { Permission } from '../../components/Permission';

// SELECTORS
import { arrayAssetsWithoutUsingBlock } from '../../../store/old/Assets/Assets.selector';
import { arrayIssues } from '../../../store/old/Issues/Issues.selector';
import { arrayUsers } from '../../../store/old/User/User.selector';

// ACTIONS
import {
    createTrackedIssueRequest,
    deleteTrackedIssueRequest,
    updateTrackedIssueRequest,
} from '../../../store/old/TrackedIssues/TrackedIssues.action';

import './IssueTracker.scss';
import { regexMatch } from '../../utils/helpers';
import { flash } from '../../components/Flash';
import AukDrawerRight from '../../components/AukDrawerRight';
import { NoData } from '../../components/None';
import { SPAWrapper } from '../../components/SPAWrapper';
import VList from '../../components/VList';
import TrackedIssueListItem from './components/TrackedIssueListItem';
import translate from '../../utils/translate';

const EDITOR_MODES = {
    CREATE: 'create',
    UPDATE: 'update',
};

const FILTER_BY = {
    ALL: 'All',
    ACTIVE: 'Active',
    ARCHIVED: 'Archived',
};

const FILTER_OPTIONS = [
    { value: FILTER_BY.ALL, label: FILTER_BY.ALL },
    { value: FILTER_BY.ACTIVE, label: FILTER_BY.ACTIVE },
    { value: FILTER_BY.ARCHIVED, label: FILTER_BY.ARCHIVED },
];

class IssueTrackerComponent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            showDrawer: false,
            showEditor: '',
            filter: { by: FILTER_BY.ACTIVE, search: '' },
            selection: null,
            confirmation: {},
            imageSelection: '',
        };

        this.handleChangeSearch = this.handleChangeSearch.bind(this);
        this.handleClickTableRow = this.handleClickTableRow.bind(this);
        this.handleClickEditRow = this.handleClickEditRow.bind(this);
        this.handleClickResolve = this.handleClickResolve.bind(this);
        this.handleClickDelete = this.handleClickDelete.bind(this);
        this.handleClickReopen = this.handleClickReopen.bind(this);
        this.toggleEditor = this.toggleEditor.bind(this);
        this.hideEditor = this.hideEditor.bind(this);
        this.handleCancelDelete = this.handleCancelDelete.bind(this);
        this.handleCloseDrawer = this.handleCloseDrawer.bind(this);
        this.handleClickImage = this.handleClickImage.bind(this);

        this.handleChangeFilter = this.handleChangeFilter.bind(this);
    }

    componentDidUpdate(prevProps, prevState) {
        const { selection } = this.state;
        if (prevProps.trackedIssues !== this.props.trackedIssues) {
            selection &&
        this.setState({
            selection: this.props.trackedIssues[selection.tracked_issue_id],
        });
        }

        if (prevState.selection !== selection) {
            if (!selection) return;
            if (
                (prevState.selection && prevState.selection.tracked_issue_id) ===
        (selection && selection.tracked_issue_id)
            )
                return;

            this.props.fetchSelectionDetails(selection);
        }
    }

    handleChangeFilter(v) {
        this.setState({ filter: { ...this.state.filter, ...v } });
    }

    handleChangeSearch(e) {
        this.setState({ search: e.target.value });
    }

    toggleEditor(mode) {
        this.setState({ showEditor: mode });
    }

    hideEditor() {
        this.setState({ showEditor: '' });
    }

    handleClickTableRow(selection) {
        this.setState({ selection, showDrawer: true });
    }

    handleCloseDrawer() {
        this.setState({ selection: null, showDrawer: false });
    }

    handleClickEditRow(selection) {
        this.setState({
            selection: selection,
            showEditor: EDITOR_MODES.UPDATE,
        });
    }

    handleClickResolve(selection) {
        const { tracked_issue_id } = selection;
        const data = {
            tracked_issue_id,
            close_date: new Date(),
        };

        this.props.updateTrackedIssue(data, () =>
            flash({
                message: 'Resolved tracked issue',
                status: 'success',
            })
        );
    }

    handleClickReopen(selection) {
        const { tracked_issue_id } = selection;
        const data = {
            tracked_issue_id,
            close_date: null,
        };

        this.props.updateTrackedIssue(data, () =>
            flash({
                message: 'Reopened tracked issue',
                status: 'success',
            })
        );
    }

    handleClickDelete(selection) {
        this.setState({
            selection,
            confirmation: {
                show: true,
            },
        });
    }

    handleClickImage(img = '') {
        this.setState({ imageSelection: img });
    }

    handleCancelDelete() {
        this.setState({
            confirmation: {},
            selection: null,
        });
    }

    get deleteWarning() {
        if (!this.state.confirmation.show || !this.state.selection) return null;

        const { title, description } = this.state.selection;

        return (
            <>
        You are about to remove <span style={{ fontWeight: 500 }}>{title}</span>
        :
                <p style={{ width: '80%', margin: '10px auto' }}>
                    <i>{description}</i>
                </p>
        This action is not reversible, would you like to continue?
            </>
        );
    }

    get editorCreate() {
        if (this.state.showEditor !== EDITOR_MODES.CREATE) return null;
        return (
            <TrackedIssueEditor
                show={true}
                toggle={this.hideEditor}
                save={(data) =>
                    this.props.createTrackedIssue(data, () => {
                        flash({
                            message: 'Created new tracked issue',
                            status: 'success',
                        });
                        this.hideEditor();
                    })
                }
            />
        );
    }

    get editorUpdate() {
        if (this.state.showEditor !== EDITOR_MODES.UPDATE) return null;
        return (
            <TrackedIssueEditor
                show={true}
                toggle={this.hideEditor}
                data={this.state.selection}
                save={(data) =>
                    this.props.updateTrackedIssue(data, () => {
                        flash({
                            message: 'Updated tracked issue',
                            status: 'success',
                        });
                        this.hideEditor();
                    })
                }
            />
        );
    }

    get imageModal() {
        const body = this.state.imageSelection ? (
            <div style={{ width: '80%', margin: '0 auto' }}>
                <img
                    style={{ maxWidth: '100%' }}
                    alt=""
                    src={this.state.imageSelection}
                />
            </div>
        ) : null;

        const footer = (
            <PanelFooter>
                <button
                    onClick={() => this.handleClickImage()}
                    className="btn b-cancel"
                >
          CANCEL
                </button>
            </PanelFooter>
        );

        return (
            <CustomModal
                title="Viewer"
                show={!!this.state.imageSelection}
                toggle={() => this.handleClickImage()}
                body={body}
                footer={footer}
            />
        );
    }

    get trackedIssues() {
        const {
            state: {
                filter: { by, search },
            },
        } = this;

        return values(this.props.trackedIssues)
            .filter((item) => {
                if (by === FILTER_BY.ACTIVE) return !item.close_date;
                if (by === FILTER_BY.ARCHIVED) return item.close_date;
                return true;
            })
            .filter((item) => {
                const {
                    reporter = { nameFull: '' },
                    assignee = { nameFull: '' },
                    issue = { name: '' },
                    title,
                    assets,
                } = item;

                return (
                    regexMatch(reporter.nameFull, search, { escape: true }) ||
          regexMatch(assignee.nameFull, search, { escape: true }) ||
          regexMatch(issue.name, search, { escape: true }) ||
          regexMatch(title, search, { escape: true }) ||
          regexMatch(assets.map((a) => a.asset_name).join(' '), search, {
              escape: true,
          })
                );
            });
    }

    get filterComponent() {
        return (
            <div className="col col-4 p-0">
                <Input.Group compact className="d-flex flex-row">
                    <Select
                        style={{ width: 140 }}
                        options={FILTER_OPTIONS}
                        value={this.state.filter.by}
                        onChange={(e) => this.handleChangeFilter({ by: e })}
                    />
                    <Input.Search
                        style={{ flexGrow: 1 }}
                        onChange={(e) =>
                            this.handleChangeFilter({ search: e.target.value })
                        }
                        value={this.state.filter.search}
                        allowClear
                    />
                </Input.Group>
            </div>
        );
    }

    render() {
        return (
            <SPAWrapper className="issue-tracker-container">
                {this.editorCreate}
                {this.editorUpdate}
                <div className="controls d-flex mb-3 justify-content-between">
                    {this.filterComponent}
                    <Permission forResource resource="tracked_issues" canDo="edit">
                        <div className="d-flex">
                            <AukTooltip.Help
                                title={translate(['track', 'new', 'issue'])}
                            >
                                <Button
                                    onClick={() => this.toggleEditor(EDITOR_MODES.CREATE)}
                                    className="auk-button auk-button--round"
                                >
                                    <i className="fas fa-plus" />
                                </Button>
                            </AukTooltip.Help>
                        </div>
                    </Permission>
                </div>
                <div className="tracked-issues-vlist-container w-100">
                    {this.trackedIssues.length ? (
                        <VList
                            rowHeight={220}
                            rowCount={this.trackedIssues.length}
                            data={this.trackedIssues}
                            rowRenderer={({ key, index, style }) => {
                                const data = this.trackedIssues[index];

                                return (
                                    <div style={style} key={key}>
                                        <TrackedIssueListItem
                                            selected={this.state.selection === data}
                                            data={data}
                                            onClick={() => this.handleClickTableRow(data)}
                                        />
                                    </div>
                                );
                            }}
                        />
                    ) : (
                        <div className="d-flex align-items-center justify-content-center h-100">
                            <NoData />
                        </div>
                    )}
                </div>
                <AukDrawerRight
                    autoFocus
                    maskStyle={{ display: 'none', pointerEvents: 'none' }}
                    style={{ position: 'absolute', pointerEvents: 'none' }}
                    onClose={this.handleCloseDrawer}
                    visible={this.state.showDrawer}
                >
                    {this.state.selection ? (
                        <Slider
                            data={{
                                selection: this.state.selection,
                                occurrences: this.props.occurrences,
                                comments: this.props.comments,
                            }}
                            handleClickEdit={this.handleClickEditRow}
                            handleClickResolve={this.handleClickResolve}
                            handleClickReopen={this.handleClickReopen}
                            update={this.props.updateTrackedIssue}
                            remove={(d) =>
                                this.props.deleteTrackedIssue(d, () => {
                                    this.setState({ selection: null, showDrawer: false });
                                })
                            }
                            addComment={this.props.addComment}
                            handleClickImage={this.handleClickImage}
                        />
                    ) : (
                        <NoData className="h-100" description="No issue selected" />
                    )}
                </AukDrawerRight>
                <ConfirmationModal
                    showConfirmation={this.state.confirmation.show}
                    onConfirmation={() =>
                        this.props.deleteTrackedIssue(this.state.selection, () =>
                            this.handleCancelDelete()
                        )
                    }
                    toggleConfirmation={this.handleCancelDelete}
                    action="Delete tracked issue"
                    message={this.deleteWarning}
                />
                {this.imageModal}
            </SPAWrapper>
        );
    }
}

const mapStateToProps = (appState) => {
    return {
        trackedIssues: appState.trackedIssues.trackedIssues,
        users: appState.user.list,
        arrayAssets: arrayAssetsWithoutUsingBlock(appState),
        arrayIssues: arrayIssues(appState),
        arrayUsers: arrayUsers(appState),
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createTrackedIssue: (data, cb) =>
            dispatch(createTrackedIssueRequest(data, cb)),
        updateTrackedIssue: (data, cb) =>
            dispatch(updateTrackedIssueRequest(data, cb)),
        deleteTrackedIssue: (data, cb) =>
            dispatch(deleteTrackedIssueRequest(data, cb)),
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IssueTrackerComponent);
